<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="50rem" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white shadow">
      <div class="p-6 md:px-10 md:py-6">
        <table class="mt-8 w-full select-none">
          <thead>
            <tr>
              <th class="w-1/2 whitespace-nowrap py-3 text-left text-sm font-semibold uppercase text-gray-400">
                {{ $t('Name') }}
              </th>
              <th class="w-1/2 whitespace-nowrap py-3 text-right text-sm font-semibold uppercase text-gray-400">
                {{ $t('Quantity') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <subscription-item
              v-for="activeSubscriptionItem in subscription.items"
              v-if="show"
              :key="activeSubscriptionItem.id"
              :subscription-item="activeSubscriptionItem"
              @update-quantity="onUpdateQuantity"
            />
          </tbody>
        </table>
      </div>
      <hr />
      <div v-if="preview" class="flex p-6 md:px-10 md:py-6">
        <div class="w-1/2">
          <div class="font-semibold">
            {{ $t('Monthly Cost') }}
          </div>
          <div class="text-sm font-semibold text-gray-500">
            {{ preview.description }}
          </div>
        </div>
        <div class="w-1/2 text-right text-xl font-semibold">
          ${{ $t(':amount / mo', { amount: getMonthlyAmount }) }}
        </div>
      </div>
      <hr />
      <div class="bg-gray-200/60 px-6 py-4 md:px-10 md:py-8">
        <div class="text-right">
          <front-solid-button class="transform px-12 py-4" :loading="form.processing" @click="editSubscription()">
            {{ $t('Update quantity') }}
          </front-solid-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import SubscriptionItem from './SubscriptionItem.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import Modal from '@/Shared/Modal.vue'
import { useForm } from '@inertiajs/vue3'
import moment from 'moment'

export default {
  components: { SubscriptionItem, FrontSolidButton, Modal },
  props: {
    show: Boolean,
    subscription: Object,
  },
  data() {
    return {
      billing_dt: moment(this.subscription.currentPeriodEnd).format('YYYY-MM-DD'),
      form: useForm({
        subscriptionPlans: null,
      }),
      preview: this.subscription.preview ?? null,
      quantity: this.subscription.items[0].quantity,
    }
  },
  computed: {
    getFeatures() {
      return this.subscription.items[0].subscriptionPlan.features?.split('|')
    },
    getMonthlyAmount() {
      let amt = 0
      if (this.preview.amount) {
        amt = this.preview.amount.replace(',', '')
        if (this.preview.interval === 'year') {
          amt = amt / 12
        }
      }
      return parseFloat(amt).toFixed(2)
    },
  },
  methods: {
    close() {
      this.quantity = this.subscription.items[0].quantity
      this.$emit('close')
    },
    editSubscription() {
      this.subscription.items[0].subscriptionPlan.quantity = this.quantity
      this.form
        .transform(() => ({
          subscriptionPlans: [this.subscription.items[0].subscriptionPlan],
        }))
        .post(this.route('front.admin.subscription.plans.store'), {
          onSuccess: () => {
            this.close()
          },
        })
    },
    onUpdateQuantity(quantity) {
      this.quantity = quantity
      axios
        .get(
          this.route('front.admin.subscription.plans.pricing-preview', [
            this.subscription.items[0].subscriptionPlan.id,
          ]),
          {
            params: {
              quantity: quantity,
            },
          }
        )
        .then((response) => {
          this.preview = response.data
        })
        .catch((error) => console.log(error))
    },
  },
}
</script>
