<template>
  <front-layout :title="manual.name">
    <div class="w-full max-w-5xl">
      <div class="flex flex-col xl:flex-row xl:justify-between">
        <div class="sm:no-flex flex w-full items-center justify-between pb-8">
          <breadcrumb :name="manual.name" />
          <front-loading-button type="button" @click="showExportManualModal = true">
            {{ $t('Export') }}
          </front-loading-button>
        </div>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="w-full md:w-1/4">
          <div class="max-h-72 overflow-y-auto rounded-lg bg-white shadow-lg md:max-h-96">
            <div v-for="(section, index) in allSections" :key="section.id" class="flex flex-col">
              <InertiaLink
                class="w-full px-6 py-4 hover:bg-gray-200"
                :class="{
                  'bg-gray-200': isCurrentSection(section),
                  'rounded-t-lg': index === 0,
                  'rounded-b-lg': index === allSections.length - 1,
                }"
                :href="route('front.manuals.show', { manual: manual.id, page: index + 1 })"
                :preserve-scroll="true"
                replace
              >
                {{ section.name }}
              </InertiaLink>
            </div>
          </div>
        </div>
        <div class="mt-8 w-full md:mt-0 md:w-3/4 md:pl-8">
          <div
            v-for="section in sections.data"
            :key="section.id"
            class="w-full overflow-x-auto rounded-lg bg-white p-6 shadow-lg"
          >
            <div class="leading-6" v-html="section.content" />
            <div v-if="section.attachments.length > 0">
              <hr class="my-4" />
              <label class="form-label block text-ts-front-label" for="attachments">Attachments</label>
              <div class="flex flex-col">
                <div
                  v-for="attachment in section.attachments"
                  :key="attachment.id"
                  class="mt-1 flex items-center justify-between rounded-lg p-4"
                  style="background: #f1f0ef"
                >
                  <div class="flex flex-col">
                    <span class="text-sm">{{ attachment.file_name }}</span>
                    <span class="mt-1 text-xs">Uploaded on {{ attachment.created_at }}</span>
                  </div>
                  <a :href="attachment.url" target="_blank">
                    <icon class="inline h-6 w-6 rounded-full fill-darkGray-1000" name="download-modern" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <pagination :links="sections.links" />
        </div>
      </div>
    </div>
    <export-manual-modal
      :manual="manual"
      :sections="allSections"
      :show="showExportManualModal"
      @close:export-manual-modal="closeExportManualModal"
    />
  </front-layout>
</template>

<script>
import ExportManualModal from './ExportManualModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import Icon from '@/Shared/Icon.vue'
import Pagination from '@/Shared/Pagination.vue'

export default {
  components: {
    ExportManualModal,
    Breadcrumb,
    FrontLayout,
    FrontLoadingButton,
    Icon,
    Pagination,
  },
  props: {
    manual: Object,
    sections: Object,
    allSections: Array,
  },
  data() {
    return {
      showExportManualModal: false,
    }
  },
  computed: {
    currentPaginatedPage() {
      let urlParams = new URLSearchParams(window.location.search)
      return parseInt(urlParams.get('page') ?? 1)
    },
  },
  methods: {
    closeExportManualModal() {
      this.showExportManualModal = false
    },
    isCurrentSection(section) {
      // Check if this section is currently being viewed
      if (this.sections.data.length === 0) return false

      // If there's a current section displayed, check if it matches
      const currentSection = this.sections.data[0]
      return section.id === currentSection.id
    },
  },
}
</script>
