<template>
  <layout title="Current Subscription">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb-admin :path="breadCrumbs" />
      </div>
    </div>

    <div class="flex w-full flex-col justify-between gap-x-6 md:flex-row">
      <main class="w-full space-y-8 md:w-2/3">
        <section>
          <h2 class="font-heading text-2xl font-semibold">{{ $t('Current Subscription Plans') }}</h2>
          <InertiaLink :href="route('organizations.subscription-plans.index', organization)">
            {{ $t('Add Plans') }}
          </InertiaLink>
          <div v-if="subscriptions.length > 0">
            <div v-for="subscription in subscriptions" :key="subscription.id" class="mt-6 rounded bg-white shadow">
              <div class="flex items-center justify-between overflow-x-auto px-6 py-2">
                <div class="whitespace-nowrap py-4">
                  <div class="mb-2 font-bold">{{ $t('Started On') }}</div>
                  <show-date :timestamp="subscription.startDate" />
                </div>

                <div class="whitespace-nowrap py-4">
                  <div v-if="subscription.stripe_status == 'active'">
                    <div class="mb-2 font-bold">{{ $t('Billing Period') }}</div>
                    <div class="flex">
                      <show-date :timestamp="subscription.currentPeriodStart" />
                      <span class="px-2">-</span>
                      <show-date :timestamp="subscription.currentPeriodEnd" />
                    </div>
                  </div>
                  <div v-else-if="subscription.stripe_status == 'trialing'">
                    <div class="mb-2 font-bold">{{ $t('Trial Ends At') }}</div>
                    <div class="flex">
                      <show-date
                        class="text-blue-600 underline cursor-pointer"
                        :timestamp="subscription.currentPeriodEnd"
                        @click="showSubscriptionTrialModal(subscription)"
                      />
                      <UpdateSubscriptionTrialModal
                        :organization="organization"
                        :show="$page.props.errors.free_trial_days ? true : subscription.showSubscriptionTrialModal"
                        :subscription="subscription"
                        @close="closeSubscriptionTrialModal(subscription)"
                      />
                    </div>
                  </div>
                  <div v-else-if="subscription.stripe_status == 'canceled'">
                    <div class="mb-2 font-bold">{{ $t('Cancels At') }}</div>
                    <div class="flex">
                      <show-date :timestamp="subscription.currentPeriodEnd" />
                    </div>
                  </div>
                </div>

                <div class="whitespace-nowrap py-4">
                  <div class="mb-2 font-bold">{{ $t('Status') }}</div>
                  <div class="capitalize">{{ subscription.stripe_status }}</div>
                </div>
                <div class="whitespace-nowrap py-4">
                  <button
                    v-if="subscription.can.cancel"
                    class="btn-blue inline-block bg-red-500 hover:bg-red-600 focus:bg-red-600"
                    @click="stopSubscription(subscription)"
                  >
                    {{ $t('Cancel') }}
                  </button>
                  <button
                    v-else-if="subscription.can.resume"
                    class="btn-blue"
                    @click="resumeSubscription(subscription)"
                  >
                    {{ $t('Resume') }}
                  </button>
                </div>
              </div>
              <div v-if="subscription" class="mt-2 px-6 py-2">
                <table class="w-full">
                  <thead>
                    <tr class="bg-gray-100">
                      <th class="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold">{{ $t('Name') }}</th>
                      <th class="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold">{{ $t('Quantity') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="subscription.items.length === 0">
                      <td class="border-t px-6 py-4" colspan="2">{{ $t('No plans found.') }}</td>
                    </tr>
                    <subscription-item
                      v-for="activeSubscriptionItem in subscription.items"
                      :key="activeSubscriptionItem.id"
                      :organization="organization"
                      :subscription-item="activeSubscriptionItem"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else class="mt-6 flex items-center justify-between overflow-x-auto rounded bg-white px-6 py-4 shadow">
            {{ $t('No subscription yet.') }}
          </div>
        </section>

        <section v-if="upcomingInvoice">
          <h2 class="font-heading text-2xl font-semibold">{{ $t('Upcoming Invoice') }}</h2>
          <span class="text-xs">
            {{
              $t(
                'This is a preview of the upcoming invoice that will be billed. It may change if the subscription is updated.'
              )
            }}
          </span>
          <upcoming-invoice :upcoming-invoice="upcomingInvoice" />
        </section>

        <section>
          <h2 class="font-heading text-2xl font-semibold">{{ $t('Invoices') }}</h2>
          <invoices :invoice-data="invoices" />
        </section>
      </main>

      <!-- Side bar Code Starts-->
      <div class="w-full space-y-4 md:w-1/3">
        <section class="overflow-hidden rounded bg-white shadow">
          <h2 class="p-4 font-heading text-2xl font-semibold">{{ $t('Payment Method') }}</h2>
          <payment-methods
            :default-payment-method="defaultPaymentMethod"
            :organization="organization"
            :payment-methods="paymentMethods"
          />
        </section>
        <section class="flex flex-col overflow-hidden rounded bg-white p-4 shadow">
          <h2 class="font-heading text-2xl font-semibold">{{ $t('Total Revenue') }}</h2>
          <small class="text-xs font-normal text-gray-500">{{ $t('(Including Pending Invoice)') }}</small>
          <show-price class="pt-2 text-4xl text-green-400" :value="totalRevenue" />
        </section>
      </div>
      <!-- Side bar Code Ends-->
    </div>
    <CancelSubscriptionModal
      :organization="organization"
      :show="showCancelSubscriptionModal"
      @close="showCancelSubscriptionModal = false"
    />
  </layout>
</template>

<script>
import CancelSubscriptionModal from './CancelSubscriptionModal.vue'
import Invoices from './Invoices.vue'
import PaymentMethods from './PaymentMethods.vue'
import SubscriptionItem from './SubscriptionItem.vue'
import UpcomingInvoice from './UpcomingInvoice.vue'
import UpdateSubscriptionTrialModal from './UpdateSubscriptionTrialModal.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Layout from '@/Shared/Layout.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import ShowPrice from '@/Shared/ShowPrice.vue'

export default {
  components: {
    UpdateSubscriptionTrialModal,
    CancelSubscriptionModal,
    PaymentMethods,
    Invoices,
    SubscriptionItem,
    UpcomingInvoice,
    BreadcrumbAdmin,
    Layout,
    ShowDate,
    ShowPrice,
  },
  props: {
    organization: Object,
    defaultPaymentMethod: Object,
    paymentMethods: Array,
    subscriptions: Object,
    invoices: Array,
    upcomingInvoice: Object,
    totalRevenue: Number,
  },
  data() {
    return {
      showCancelSubscriptionModal: false,
    }
  },
  computed: {
    breadCrumbs() {
      return [
        {
          name: this.$t('Organizations'),
          url: this.route('organizations.index'),
        },
        {
          name: this.organization.name,
          url: this.route('organizations.edit', this.organization.id),
        },
        {
          name: this.$t('Subscriptions'),
        },
      ]
    },
  },
  methods: {
    stopSubscription(subscription) {
      this.$inertia.post(
        this.route('organizations.subscription-plans.stop', [
          this.organization.id,
          subscription.items[0].subscriptionPlan.id,
        ])
      )
    },
    resumeSubscription(subscription) {
      this.$inertia.post(
        this.route('organizations.subscription-plans.resume', [
          this.organization.id,
          subscription.items[0].subscriptionPlan.id,
        ])
      )
    },
    showSubscriptionTrialModal(subscription) {
      subscription.showSubscriptionTrialModal = true
    },
    closeSubscriptionTrialModal(subscription) {
      this.$page.props.errors.free_trial_days = null
      subscription.showSubscriptionTrialModal = false
    },
  },
}
</script>
