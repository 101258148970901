<template>
  <div
    class="relative w-1/3 rounded-lg border-2 p-8 leading-normal shadow-xl"
    :class="{
      'border-qualify-red-600': isSelected,
      'bg-white': plan.theme === 'light',
      'bg-gradient-to-b from-[#3E484F] to-[#000000] text-white': plan.theme === 'dark',
    }"
  >
    <div
      v-if="plan.free_trial_days > 0"
      class="absolute -top-5 left-0 right-0 m-auto h-10 w-1/2 rounded-lg bg-gradient-to-r from-ts-orange-900 to-qualify-red-900 py-2 text-center text-sm font-bold leading-normal"
    >
      {{ $t(':free_trial_days Day Free Trial', { free_trial_days: plan.free_trial_days }) }}
    </div>
    <div class="text-center">
      <h4 class="mb-8 mt-6 h-12 text-center text-2xl font-semibold">{{ plan.title }}</h4>
    </div>
    <div v-if="plan.price.billing_scheme == 'per_unit'">
      <div class="grid h-20 grid-cols-2 items-center">
        <div class="text-right">
          <h1 class="pr-1 text-3xl font-bold tracking-wide">
            ${{
              plan.price.recurring.interval === 'year'
                ? (plan.price.amount / 12).toFixed(2)
                : plan.price.amount.toFixed(2)
            }}
          </h1>
        </div>
        <div class="w-20 text-left">
          <h4 class="pl-1 text-sm font-semibold">{{ $t('Per User Per Month') }}</h4>
        </div>
      </div>
      <div v-if="plan.price.recurring.interval === 'month'" class="w-full text-center text-sm font-semibold italic">
        {{ $t('Billed Monthly') }}
      </div>
      <div v-if="plan.price.recurring.interval === 'year'" class="w-full text-center text-sm font-semibold italic">
        {{ $t('Billed Annually') }}
      </div>

      <div class="mb-16 mt-8 h-16 w-full text-center">
        <div
          class="m-auto w-5/6 rounded-lg border-2 border-gray-200 p-3 text-center font-semibold uppercase text-gray-400"
        >
          {{ $t('unlimited') }}
        </div>
      </div>
    </div>

    <div v-if="plan.price.billing_scheme === 'tiered'">
      <div class="grid h-20 grid-cols-2 items-center">
        <div class="relative text-right">
          <span
            class="absolute -top-5 right-0 pl-1 text-xs"
            :class="{ 'text-gray-600': plan.theme === 'light', 'text-white': plan.theme === 'dark' }"
          >
            {{ $t('Starting at') }}
          </span>
          <h1 class="pr-1 text-3xl font-bold tracking-wide">${{ getSliderPrice(plan) }}</h1>
        </div>
        <div class="w-1/2 text-left">
          <h4 class="pl-1 text-sm font-semibold">
            {{ $t('Per User Per Month') }}
          </h4>
        </div>
      </div>
      <div v-if="plan.price.recurring.interval === 'month'" class="w-full text-center text-sm font-semibold italic">
        {{ $t('Billed Monthly') }}
      </div>
      <div v-if="plan.price.recurring.interval === 'year'" class="w-full text-center text-sm font-semibold italic">
        {{ $t('Billed Annually') }}
      </div>

      <div class="mb-16 mt-8 h-16 w-full text-center">
        <div class="m-auto w-5/6 text-center">
          <datalist id="steplist" class="text-md font-semibold">
            <option class="text-left" value="1">1</option>
            <option class="text-right" value="getSubscriptionPlansTierUpto(plan)">
              {{ getSubscriptionPlansTierUpto(plan) }}
            </option>
          </datalist>
          <input
            :id="plan.id"
            class="w-full"
            list="steplist"
            :max="plan.price.tiers?.length - 1"
            min="0"
            step="1"
            type="range"
            :value="sliderValue"
            @change="updateSliderValue"
          />
          <p class="mt-2">
            {{
              $t(':no_of_users users / mo', {
                no_of_users: getSubscriptionPlansNoOfUsers(plan),
              })
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="mb-4 text-center">
      <button
        v-if="isSelected"
        class="h-16 w-5/6 rounded-full border-2 border-qualify-red-600 px-8 py-3 font-heading font-bold leading-6"
        :class="{ 'text-qualify-red-600': plan.theme === 'light', 'text-white': plan.theme === 'dark' }"
        @click="addPlan(plan)"
      >
        {{ $t('Plan Selected') }}
      </button>
      <front-solid-button v-else-if="!plan.isSubscribed" class="h-16 w-5/6" @click="addPlan(plan)">
        {{ plan.free_trial_days > 0 ? $t('Try for Free') : $t('Add Plan') }}
      </front-solid-button>
      <button
        v-else
        class="h-16 w-5/6 cursor-default rounded-full border-2 border-gray-400 px-8 py-3 font-heading font-bold leading-6"
        :class="{ 'text-gray-400': plan.theme === 'light', 'text-white': plan.theme === 'dark' }"
      >
        {{ $t('Subscribed') }}
      </button>
    </div>
    <div class="h-32 w-full text-center">
      <p class="mb-8 mt-8 text-left">{{ plan.description }}</p>
    </div>
    <div v-for="(feature, index) in plan.features?.split('|')" :key="index" class="my-6 text-left">
      <span class="inline-flex items-center">
        <CheckIcon aria-hidden="true" class="mr-2 h-6 w-6 stroke-qualify-red-900" />
        {{ feature }}
      </span>
    </div>
  </div>
</template>
<script>
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import { googleTagManagerEvent } from '@/Utils/GoogleTagManager'
import { CheckIcon } from '@heroicons/vue/24/outline'

export default {
  components: { CheckIcon, FrontSolidButton },
  props: {
    plan: Object,
    isSelected: Object,
  },
  data() {
    return {
      sliderValue: 0,
      sliderNoOfUsers: 1,
    }
  },
  methods: {
    getSliderPrice(plan) {
      if (plan.price.recurring.interval === 'year') {
        return (plan.price.tiers[this.sliderValue].amount / 12).toFixed(2) ?? 0
      }
      return plan.price.tiers[this.sliderValue].amount.toFixed(2) ?? 0
    },
    getSubscriptionPlansTierUpto(plan) {
      var count = plan.price.tiers?.length
      return plan.price.tiers[count - 1].up_to ?? plan.price.tiers[count - 2].up_to + 1 + '+'
    },
    getSubscriptionPlansNoOfUsers(plan) {
      if (this.sliderValue === 0) {
        return 1
      } else {
        return plan.price.tiers[this.sliderValue - 1].up_to + 1 + '+'
      }
    },
    updateSliderValue(event) {
      this.sliderValue = Number(event.target.value)
    },
    addPlan(plan) {
      googleTagManagerEvent('add_plan', {
        plan_id: plan.id,
        plan_name: plan.title,
        plan_no_of_users: this.getSubscriptionPlansNoOfUsers(plan),
      })
      this.$emit('addPlan', plan)
    },
  },
}
</script>
