<template>
  <front-layout :title="$t('Quiz Verification')">
    <div class="mb-8">
      <breadcrumb
        :name="quiz.title"
        :previous-name="$t('Quiz')"
        :previous-url="route('front.quizzes', [enrolmentContent.id, quiz.id])"
      />
    </div>

    <div class="max-w-4xl">
      <!-- Progress Steps -->
      <div class="mb-8 flex items-center justify-between px-2 sm:justify-start sm:space-x-4 sm:px-0">
        <div v-for="(step, index) in steps" :key="index" class="flex items-center">
          <button
            class="flex h-8 w-8 items-center justify-center rounded-full font-medium transition-colors"
            :class="[
              currentStep >= index ? 'bg-ts-red-500 text-white' : 'bg-gray-200 text-gray-600',
              index <= currentStep + 1 ? 'cursor-pointer hover:opacity-90' : 'cursor-not-allowed opacity-50',
            ]"
            :disabled="index > currentStep + 1"
            type="button"
            @click="goToStep(index)"
          >
            {{ index + 1 }}
          </button>
          <div
            v-if="index < steps.length - 1"
            class="ml-2 h-1 w-8 sm:ml-4 sm:w-16 md:w-24 transition-colors"
            :class="[currentStep > index ? 'bg-ts-red-500' : 'bg-gray-200']"
          />
        </div>
      </div>

      <div class="rounded-lg bg-white p-6 shadow sm:p-8">
        <!-- Name Confirmation Section -->
        <div v-show="currentStep === 0">
          <h2 class="mb-4 text-xl font-semibold">{{ $t('Confirm Your Name') }}</h2>
          <p class="mb-6 text-gray-600">
            {{ $t('Please confirm your name matches the photo ID you will upload in the next step.') }}
          </p>
          <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <TextInput disabled :label="$t('First name')" :model-value="user.first_name" />
            <TextInput disabled :label="$t('Last name')" :model-value="user.last_name" />
          </div>
        </div>

        <!-- ID Verification Section -->
        <div v-show="currentStep === 1">
          <h2 class="mb-4 text-xl font-semibold">{{ $t('Upload Your ID') }}</h2>
          <IdVerification :error="form.errors.photoIdentityFile" @update:photo="handleIdPhotoUpdate" />
        </div>

        <!-- Selfie Verification Section -->
        <div v-show="currentStep === 2">
          <h2 class="mb-4 text-xl font-semibold">{{ $t('Take a Selfie') }}</h2>
          <div class="mb-8">
            <p v-if="!cameraEnabled" class="mb-4 text-red-500">
              {{ $t('Camera is not accessible. Please enable your camera and check permissions.') }}
            </p>
            <div v-else class="max-w-sm">
              <PhotoCapture
                v-if="cameraEnabled"
                :alt-text="$t('Webcam Photo')"
                :mirrored="true"
                type="selfie"
                @error="handleCameraError"
                @photo-captured="handleWebcamPhotoCapture"
              />
              <p class="mt-2 text-sm text-gray-600">
                {{
                  form.webCameraImage
                    ? $t('Your captured photo is shown above.')
                    : $t('Please ensure your face is clearly visible in the camera preview.')
                }}
              </p>
              <div v-if="form.errors.webCameraImage" class="mt-2 rounded bg-red-50 p-2 text-sm text-red-600">
                {{ form.errors.webCameraImage }}
              </div>
            </div>
          </div>
        </div>

        <!-- Quiz Rules Section -->
        <div v-show="currentStep === 3">
          <h2 class="mb-4 text-xl font-semibold">{{ $t('Quiz Rules & Privacy') }}</h2>

          <div v-if="quiz.proctoring_rules" class="mb-8">
            <h3 class="mb-3 font-semibold">{{ $t('Rules') }}</h3>
            <div class="prose prose-sm max-w-none" v-html="quiz.proctoring_rules" />
          </div>

          <div v-if="quiz.proctoring_privacy_policy" class="mb-8">
            <h3 class="mb-3 font-semibold">{{ $t('Privacy Policy') }}</h3>
            <div class="prose prose-sm max-w-none" v-html="quiz.proctoring_privacy_policy" />
          </div>

          <div v-if="quiz.time_limit_minutes" class="mb-8 rounded-lg bg-yellow-50 p-4">
            <p class="font-medium">
              {{ $t('This quiz has a time limit of :minutes minutes.', { minutes: quiz.time_limit_minutes }) }}
            </p>
            <p class="mt-2 text-sm text-gray-600">
              {{
                $t(
                  "Once you start it, you won't be able to pause the attempt. Make sure you have enough time to finish it before you confirm."
                )
              }}
            </p>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div class="mt-8 flex items-center justify-between border-t border-gray-200 pt-6">
          <button v-if="currentStep > 0" class="text-gray-600 hover:text-gray-800" @click="previousStep">
            {{ $t('Back') }}
          </button>
          <div class="flex-1" />
          <FrontLoadingButton :disabled="!canProceed" :loading="form.processing" @click="handleNextStep">
            <span v-if="currentStep < steps.length - 1">{{ $t('Continue') }}</span>
            <span v-else>{{ $t('Start Quiz') }}</span>
          </FrontLoadingButton>
        </div>
      </div>
    </div>
  </front-layout>
</template>

<script>
import IdVerification from './Components/IdVerification.vue'
import PhotoCapture from './Components/PhotoCapture.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  name: 'QuizVerification',
  components: {
    IdVerification,
    PhotoCapture,
    FrontLoadingButton,
    TextInput,
    Breadcrumb,
    FrontLayout,
  },
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    enrolmentContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 0,
      steps: [
        { id: 'name', title: 'Name Confirmation' },
        { id: 'id', title: 'ID Verification' },
        { id: 'selfie', title: 'Selfie Verification' },
        { id: 'rules', title: 'Quiz Rules' },
      ],
      form: useForm({
        photoIdentityFile: null,
        webCameraImage: null,
      }),
      cameraEnabled: false,
    }
  },
  computed: {
    canProceed() {
      if (this.currentStep === 0) return true
      if (this.currentStep === 1) return !!this.form.photoIdentityFile
      if (this.currentStep === 2) return !!this.form.webCameraImage && this.cameraEnabled
      return true
    },
  },
  mounted() {
    if (this.quiz.proctoring_enabled) {
      this.checkCameraAccess()
    }
    if (this.form.errors.photoIdentityFile) {
      this.currentStep = 1
    } else if (this.form.errors.webCameraImage) {
      this.currentStep = 2
    }
  },
  methods: {
    async checkCameraAccess() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'user',
            width: { ideal: 1000 },
            height: { ideal: 1000 },
          },
        })
        this.cameraEnabled = true
        stream.getTracks().forEach((track) => track.stop())
      } catch (error) {
        console.error('Camera access error:', error)
        this.cameraEnabled = false
        try {
          const permission = await navigator.permissions.query({ name: 'camera' })
          if (permission.state === 'granted') {
            this.cameraEnabled = true
          }
        } catch (permError) {
          console.error('Permission query error:', permError)
        }
      }
    },
    handleIdPhotoUpdate(file) {
      this.form.photoIdentityFile = file
      this.form.errors.photoIdentityFile = null
    },
    handleWebcamPhotoCapture(file) {
      this.form.webCameraImage = file
      this.form.errors.webCameraImage = null
    },
    handleCameraError(error) {
      console.error('Camera error:', error)
      this.cameraEnabled = false
    },
    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    async handleNextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++
      } else {
        await this.startQuiz()
      }
    },
    async startQuiz() {
      if (this.quiz.proctoring_enabled) {
        if (!this.form.photoIdentityFile) {
          this.currentStep = 1
          alert(this.$t('Please provide a photo ID before starting the test.'))
          return
        }
        if (!this.form.webCameraImage) {
          this.currentStep = 2
          alert(this.$t('Please take a selfie photo before starting the test.'))
          return
        }
      }

      this.form.post(this.route('front.quizzes.attempts.store', [this.enrolmentContent, this.quiz]), {
        preserveScroll: false,
        onError: (errors) => {
          if (errors.photoIdentityFile) {
            this.currentStep = 1
          } else if (errors.webCameraImage) {
            this.currentStep = 2
            if (errors.webCameraImage.includes('Invalid ID')) {
              this.form.webCameraImage = null
              alert(this.$t('Your ID verification failed. Please retake your photo.'))
            }
          }
        },
      })
    },
    goToStep(stepIndex) {
      // Only allow going to completed steps or next step if requirements are met
      if (stepIndex <= this.currentStep) {
        this.currentStep = stepIndex
      } else if (stepIndex === this.currentStep + 1 && this.canProceed) {
        this.currentStep = stepIndex
      }
    },
  },
}
</script>
