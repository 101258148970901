<template>
  <div class="flex min-h-screen justify-center bg-white">
    <DynamicHead :title="$t('Log In | :app_name', { app_name: $page.props.app.name })" />
    <div class="px-4 pt-20 lg:w-1/2 lg:pl-20 lg:pr-8">
      <div class="ml-auto max-w-md">
        <img
          v-if="accountLogo && !isTenantWorksite"
          id="custom-tenant-logo"
          :alt="$t('Account Logo')"
          class="h-12 max-w-md"
          :src="accountLogo"
        />
        <worksite-dark v-else class="mx-auto sm:mx-0" />
        <h2 class="mb-8 mt-20 text-2xl leading-9 text-gray-900">
          {{ $t('Check your :contactMethodName for a code', { contactMethodName: getContactMethodName }) }}
        </h2>
        <form @submit.prevent="submit">
          <div v-if="$page.props.errors['token']" class="mb-4 flex items-center justify-between rounded bg-red-400">
            <div class="flex items-center">
              <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
              <div class="py-4 text-sm font-medium text-white">
                <span>{{ $page.props.errors['token'] }}</span>
              </div>
            </div>
          </div>
          <div class="-mx-3 mb-10 flex max-w-sm flex-wrap">
            <div class="w-full p-3">
              <label class="mb-6 block text-lg text-darkGray-700" for="otp">
                {{
                  $t(
                    "We've sent a 6-character code to :contactMethodValue. The code expires shortly, so please enter it soon.",
                    {
                      contactMethodValue: getContactMethodValue,
                    }
                  )
                }}
              </label>
              <FrontOtp id="otp" v-model="form.code" :error="form.errors.code" :length="6" />
            </div>
          </div>
        </form>

        <InertiaLink
          class="hover-animate text-darkGray mb-4 mt-10 block text-lg hover:no-underline"
          :href="route('front.login.password', { email: form.email })"
        >
          {{ $t('Sign in with a password instead') }}
          <icon class="currentColor inline h-5 w-5 transform transition duration-500" name="chevron-right" />
        </InertiaLink>

        <div class="py-10">
          <span class="text-darkGray-700 opacity-65">{{ $t('Powered By') }}</span>
          <qualify-dark class="ml-2 inline opacity-25" />
        </div>
      </div>
    </div>
    <div
      class="hidden items-center justify-center bg-cover lg:flex lg:w-1/2"
      style="background-image: url('/images/Qualify_LOGIN_BG.svg')"
    >
      <img alt="$t('Login')" class="w-3/4" src="/images/login.svg" />
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import FrontOtp from '@/Shared/FrontOtp.vue'
import Icon from '@/Shared/Icon.vue'
import QualifyDark from '@/Shared/Logo/QualifyDark.vue'
import WorksiteDark from '@/Shared/Logo/WorksiteDark.vue'
import { isTenantWorksite } from '@/Utils/Helpers'
import zendesk from '@/mixins/zendesk.js'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { DynamicHead, Icon, QualifyDark, WorksiteDark, FrontOtp },
  mixins: [zendesk],
  props: {
    user: Object,
  },
  data() {
    return {
      accountLogo: this.$page.props.app.tenant.logo,
      form: useForm({
        email: this.user.email ?? this.user.username,
        code: '',
      }),
    }
  },
  watch: {
    'form.code'() {
      if (this.form.code?.length === 6) {
        this.submit()
      }
    },
  },
  computed: {
    isTenantWorksite,
    getContactMethodValue() {
      return this.user.email && this.user.phone
        ? this.$t(':email and :phone', {
            email: this.maskEmail(this.user.email),
            phone: this.maskPhone(this.user.phone),
          })
        : this.user.email
          ? this.maskEmail(this.user.email)
          : this.maskPhone(this.user.phone)
    },
    getContactMethodName() {
      return this.user.email && this.user.phone
        ? this.$t('email or phone')
        : this.user.email
          ? this.$t('email')
          : this.$t('phone')
    },
  },
  mounted() {
    // Auth Check Zendesk Chat Widget
    this.zE_auth()
  },
  methods: {
    submit() {
      this.form.post(this.route('front.login.otp.attempt'))
    },
    maskEmail(email) {
      let [name, domain] = email.split('@')

      if (name.length <= 2) {
        name = name[0] + '*'.repeat(name.length - 1)
      } else {
        name = name[0] + '*'.repeat(name.length - 2) + name[name.length - 1]
      }

      let [domainName, domainExt] = domain.split('.')
      let maskedDomain = '*'.repeat(domainName.length)

      return `${name}@${maskedDomain}.${domainExt}`
    },
    maskPhone(phone) {
      let number = phone.toString()

      let first = number.slice(0, 2)
      let last = number.slice(-2)
      let masked = '*'.repeat(number.length - 4)

      return first + masked + last
    },
  },
}
</script>

<style scoped>
.hover-animate:hover > svg {
  --transform-translate-x: 0.5rem;
}
</style>
