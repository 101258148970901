<template>
  <FrontLayout :title="$t('Trial Expired')">
    <div class="rounded-2xl px-8" style="background-color: rgb(248, 239, 242)">
      <div class="w-full py-12 px-3 md:px-6 lg:w-full">
        <div class="text-4xl font-semibold">{{ $t('Your free trial period has expired.') }}</div>
        <div v-if="getOrganizationRole === 'admin'" class="mt-6">
          <div class="mb-8 leading-6">
            {{ 'To continue accessing our services, please add a payment method.' }}
          </div>
          <InertiaLink class="btn-red-gradient" :href="route('front.admin.subscriptions')">
            {{ $t('Manage Subscriptions') }}
          </InertiaLink>
        </div>
        <div v-else class="mt-6 leading-6">
          {{ "To continue accessing our services, please contact your organization's admin." }}
        </div>
      </div>
    </div>
  </FrontLayout>
</template>

<script>
import FrontLayout from '@/Shared/FrontLayout.vue'

export default {
  components: {
    FrontLayout,
  },
  computed: {
    getOrganizationRole() {
      return this.$page.props.auth.user.organization_role
    },
  },
}
</script>
