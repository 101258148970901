<template>
  <div class="flex justify-start py-2">
    <InertiaLink class="link flex space-x-2" :href="route(routeName, { id: props.value.id })">
      <ShowDate :format="`MMM DD, YYYY hh:mm`" :timestamp="props.value.valid_from" />
      <span v-if="!props.value.valid_to" class="text-xs">(Latest version)</span>
    </InertiaLink>
  </div>
</template>
<script setup>
import ShowDate from '../ShowDate.vue'

const props = defineProps({
  value: Object,
  routeName: String,
})
</script>
