<template>
  <layout :title="$t('Proctoring Recording')">
    <div class="mb-6 flex items-center justify-between">
      <h1 class="text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('proctoring-recordings')">
          {{ $t('Proctoring Recordings') }}
        </InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ attempt.user.first_name }} {{ attempt.user.last_name }}
      </h1>
      <div class="flex items-center space-x-4">
        <div class="relative">
          <Multiselect
            v-model="selectedLabels"
            class="w-64"
            :clear-on-select="false"
            :close-on-select="false"
            :internal-search="true"
            label="name"
            :multiple="true"
            :options="available_labels"
            :placeholder="$t('Filter Labels')"
            :preserve-search="true"
            :show-labels="false"
            track-by="name"
          >
            <template #selection="props">
              <span v-if="props.values.length && !props.isOpen" class="multiselect__single">
                {{ props.values.length }} {{ $t('labels selected') }}
              </span>
            </template>
          </Multiselect>
        </div>
        <dropdown class="btn-blue" placement="bottom-end">
          <div class="flex cursor-pointer select-none items-center">
            <div>
              <span>{{ $t('Actions') }}</span>
            </div>
            <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
          </div>
          <template #dropdown>
            <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
              <a
                class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                :href="route('enrolments.edit', attempt.enrolment_id)"
              >
                {{ $t('View Enrolment') }}
              </a>
              <button
                v-if="!attempt.proctoring_reviewed_at"
                class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                tabindex="-1"
                type="button"
                @click="markQuizAttemptPassed()"
              >
                {{ $t('Mark Attempt Passed') }}
              </button>
              <button
                v-if="!attempt.proctoring_reviewed_at"
                class="block w-full px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-700"
                tabindex="-1"
                type="button"
                @click="markQuizAttemptFailed()"
              >
                {{ $t('Mark Attempt Failed') }}
              </button>
            </div>
          </template>
        </dropdown>
      </div>
    </div>
    <div class="flex">
      <div class="flex w-full items-start">
        <div class="w-1/2 bg-white rounded mr-3">
          <div class="w-full bg-gray-300 px-4 py-4 text-sm font-bold text-gray-700 rounded-t">
            {{ $t('Failed Frames') }}
          </div>
          <div class="overflow-x-auto">
            <ul>
              <li v-for="(frame, index) in failed_frames" :key="index" class="mt-2">
                <table class="mb-6">
                  <tbody>
                    <tr>
                      <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold" colspan="2">
                        <img class="cursor-pointer" :src="frame.file" @click="openLightbox(frame.file)" />
                      </td>
                    </tr>
                    <tr>
                      <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                        {{ $t('Faces Found') }}
                      </td>
                      <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                        <span :class="frame.faces_count === 1 ? 'text-green-500' : 'text-red-500'">
                          {{ frame.faces_count }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                        {{ $t('Face Matched') }}
                      </td>
                      <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                        <span :class="frame.face_matched === 1 ? 'text-green-500' : 'text-red-500'">
                          {{ frame.face_matched }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                        {{ $t('Labels Found') }}
                      </td>
                      <td class="whitespace-nowrap text-wrap px-6 pb-4 pt-6 text-left font-semibold">
                        {{ frame.labels_found }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </li>
              <WhenVisible
                always
                :params="{
                  data: {
                    page: failed_frames_pagination.current_page + 1,
                  },
                  only: ['failed_frames', 'failed_frames_pagination'],
                }"
              >
                <div
                  v-if="failed_frames_pagination.current_page >= failed_frames_pagination.last_page"
                  class="text-center my-5"
                >
                  No more frames found.
                </div>
                <div v-else class="text-center my-5">Loading...</div>
              </WhenVisible>
            </ul>
          </div>
        </div>
        <div class="w-1/2 bg-white rounded">
          <div class="w-full bg-gray-300 px-4 py-4 text-sm font-bold text-gray-700 rounded-t">
            {{ $t('Identification') }}
          </div>
          <div class="overflow-x-auto mb-4">
            <table>
              <tbody>
                <tr v-if="!attempt.is_passed && !attempt.proctoring_reviewed_at">
                  <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                    {{ $t('Identification') }}
                  </td>
                  <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                    <button
                      class="inline-flex items-center px-4 py-2 btn-blue font-semibold shadow-sm transition-colors"
                      @click="openIdentificationLightbox(attempt.identification.id)"
                    >
                      {{ $t('View Identification') }}
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                    {{ $t('Name Match') }}
                  </td>
                  <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                    <span :class="attempt.identification.name_matched ? 'text-green-500' : 'text-red-500'">
                      {{ attempt.identification.name_matched }}
                    </span>
                    ({{ attempt.user.first_name }} {{ attempt.user.last_name }})
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                    {{ $t('Passed Frames') }}
                  </td>
                  <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                    <span :class="attempt.passed_frames_count > 0 ? 'text-green-500' : 'text-red-500'">
                      {{ attempt.passed_frames_count }} frame(s)
                    </span>
                  </td>
                </tr>
                <tr v-if="attempt.user_agent">
                  <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold">
                    {{ $t('Device Details') }}
                  </td>
                  <td class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-semibold text-wrap">
                    {{ attempt.user_agent }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </layout>
  <!-- Lightbox -->
  <div
    v-if="showLightbox && currentImage"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
  >
    <div class="relative max-h-[90vh] max-w-[90vw]">
      <button
        class="absolute -right-4 -top-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-xl font-bold text-gray-800 shadow-lg hover:bg-gray-100"
        @click="showLightbox = false"
      >
        ×
      </button>
      <img class="max-h-[90vh] max-w-[90vw] object-contain" :src="currentImage" />
    </div>
  </div>

  <!-- Failure Reason Modal -->
  <transparent-modal
    v-if="attempt.user.email"
    :show="showFailureReasonModal"
    style="z-index: 100000"
    width="40rem"
    @close="showFailureReasonModal = false"
  >
    <div class="p-6 bg-white w-full relative rounded-lg shadow-xl">
      <button
        class="absolute -right-2 -top-2 flex h-8 w-8 items-center justify-center rounded-full bg-white text-xl font-bold text-gray-800 shadow-lg hover:bg-gray-100"
        @click="showFailureReasonModal = false"
      >
        ×
      </button>
      <h2 class="text-lg font-medium mb-5">{{ $t('Mark Attempt as Failed') }}</h2>
      <div class="space-y-4">
        <div>
          <label class="block font-medium text-gray-700 mb-1">{{ $t('User Email') }}</label>
          <input class="form-input w-full bg-gray-100" disabled type="text" :value="attempt.user.email" />
        </div>
        <div>
          <label class="block font-medium text-gray-700 mb-1">{{ $t('Failure Reasons') }}</label>
          <Multiselect
            v-model="selectedFailureReasons"
            :allow-empty="true"
            class="w-full mb-1"
            :clear-on-select="false"
            :close-on-select="false"
            :internal-search="true"
            label="label"
            :multiple="true"
            :options="failure_reasons || []"
            placeholder="Select failure reasons"
            :preserve-search="true"
            :searchable="true"
            track-by="value"
          />
          <small class="text-sm text-gray-700">
            {{ $t('If left blank no notification will be sent.') }}
          </small>
        </div>
        <div class="flex justify-end space-x-2 mt-5">
          <button class="btn-white" type="button" @click="showFailureReasonModal = false">
            {{ $t('Cancel') }}
          </button>
          <button class="btn-blue ml-3" type="button" @click="submitFailureReasons">
            {{ $t('Mark as Failed') }}
          </button>
        </div>
      </div>
    </div>
  </transparent-modal>
</template>

<script>
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import TransparentModal from '@/Shared/TransparentModal.vue'
import { WhenVisible } from '@inertiajs/vue3'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  name: 'ProctorRecordingsShow',
  components: {
    WhenVisible,
    Dropdown,
    Icon,
    Layout,
    TransparentModal,
    Multiselect,
  },
  props: {
    attempt: {
      type: Object,
      required: true,
    },
    failed_frames: {
      type: Array,
      required: true,
    },
    failed_frames_pagination: {
      type: Object,
      required: true,
    },
    available_labels: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    failure_reasons: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      selectedLabels: this.filters?.labels
        ? this.available_labels.filter((label) => this.filters.labels.includes(label.name))
        : [],
      showIdentificationModal: false,
      showLightbox: false,
      currentImage: null,
      showFailureReasonModal: false,
      selectedFailureReasons: [],
    }
  },
  watch: {
    selectedLabels: {
      handler(newLabels) {
        this.$inertia.get(this.route('proctoring-recordings.show', this.attempt.id), {
          labels: newLabels.map((label) => label.name),
          page: 1,
          preserveState: true,
          preserveScroll: true,
        })
      },
      deep: true,
    },
  },
  methods: {
    markQuizAttemptPassed() {
      if (confirm('Are you sure you want to override the proctoring system and pass this enrolment content?')) {
        this.$inertia.post(this.route('enrolment-contents.attempts.proctoring-passed', this.attempt.id))
      }
    },
    markQuizAttemptFailed() {
      if (!this.attempt.user.email) {
        if (confirm('Are you sure you want to override the proctoring system and fail this enrolment content?')) {
          this.$inertia.post(this.route('enrolment-contents.attempts.proctoring-failed', this.attempt.id))
        }
        return
      }
      this.showFailureReasonModal = true
    },
    submitFailureReasons() {
      if (confirm('Are you sure you want to override the proctoring system and fail this enrolment content?')) {
        this.$inertia.post(this.route('enrolment-contents.attempts.proctoring-failed', this.attempt.id), {
          failure_reasons: this.selectedFailureReasons.map((reason) => reason.value),
        })
      }
      this.showFailureReasonModal = false
    },
    openLightbox(image) {
      this.currentImage = image
      this.showLightbox = true
    },
    async openIdentificationLightbox(identificationId) {
      try {
        const response = await axios.post(this.route('proctoring-recordings.identification.show', identificationId))
        if (response.data && response.data.url) {
          this.currentImage = response.data.url
          this.showLightbox = true
        }
      } catch (error) {
        console.error('Failed to load identification image:', error)
      }
    },
  },
}
</script>

<style scoped>
.multiselect {
  min-height: 40px;
}

.multiselect__tags {
  min-height: 40px;
  padding: 8px 40px 0 8px;
  border-radius: 0.375rem;
  border: 1px solid #e2e8f0;
  background: #fff;
}

.multiselect__select {
  height: 38px;
}

.multiselect__input,
.multiselect__single {
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin-bottom: 8px;
}

.multiselect__tag {
  background: #3b82f6;
  color: white;
  margin-bottom: 0;
}

.multiselect__tag-icon:after {
  color: white;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #2563eb;
}

.multiselect__option--highlight {
  background: #3b82f6 !important;
}

.multiselect__option--highlight:hover {
  background: #2563eb !important;
}

.multiselect__option--highlight:after {
  background: #2563eb !important;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: #f3f4f6;
  color: #9ca3af;
}

.identification-image {
  max-width: 100%;
  height: auto;
}
</style>
