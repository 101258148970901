<template>
  <layout :title="$t('Create Enrolment')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('enrolments')">
        {{ $t('Enrolments') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <search-input
              v-model="course"
              :data="courses"
              :error="form.errors.course_id"
              :label="$t('Course')"
              :search-by="['name']"
              track-by="id"
            >
              <div v-if="course" class="flex items-center justify-between">
                <div class="truncate">{{ course.name }}</div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    {{ option.name }}
                  </div>
                </div>
              </template>
            </search-input>
          </div>
          <div v-if="!course || course.has_instructor_led_training" class="w-full pb-8 pr-6">
            <text-input v-if="targetClass.id" disabled :label="$t('Class')" :modelValue="targetClass.formatted_name" />
            <template v-else>
              <div class="item-center flex justify-between pb-2">
                <label>{{ $t('Class:') }}</label>
                <div class="">
                  <input
                    id="private-classes-only"
                    v-model="access"
                    false-value="public"
                    true-value="private"
                    type="checkbox"
                  />
                  <label for="private-classes-only">{{ $t('Private Classes') }}</label>
                </div>
              </div>
              <dynamic-search-input
                v-model="class_"
                :disabled="!course"
                :error="form.errors.class_id"
                label=""
                :search-by="['location.name', 'location.client_site', 'room', 'dates', 'available_seats']"
                track-by="id"
                :url="
                  route('classes.search', {
                    course: course ? course.id : null,
                    access,
                  })
                "
              >
                <div v-if="class_" class="flex items-center justify-between">
                  <div class="truncate">{{ class_.formatted_name }}</div>
                </div>
                <template #option="{ option, selected }">
                  <div class="flex items-center justify-between">
                    <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                      {{ option.formatted_name }}
                    </div>
                  </div>
                </template>
              </dynamic-search-input>
            </template>
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <text-input
              v-if="targetOwner"
              disabled
              :label="$t('Owner')"
              :model-value="targetOwner.first_name + ' ' + targetOwner.last_name"
            />
            <user-search-input v-else v-model="owner" :error="form.errors.owner_id" :label="$t('Owner')" />
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <user-search-input v-model="user" :error="form.errors.user_id" :label="$t('User')" />
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <text-input
              v-model="form.quantity"
              :error="form.errors.quantity"
              :label="$t('Quantity')"
              min="1"
              step="1"
              type="number"
            />
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <text-input disabled :label="$t('Payment Status')" model-value="Paid" />
          </div>
          <div class="w-1/2 pb-8 pr-6">
            <label class="form-label">{{ $t('Paid Via:') }}</label>
            <select v-model="form.payment_gateway" class="form-select w-full rounded">
              <option :value="null">{{ $t('None') }}</option>
              <option v-for="(gateway, key) in paymentGateways" :key="key" :value="key">
                {{ gateway }}
              </option>
            </select>
          </div>
          <div v-if="form.payment_gateway === 'WooCommerce'" class="w-1/2 pb-8 pr-6">
            <dynamic-search-input
              v-model="order"
              :error="form.errors.order_id"
              :label="$t('Order')"
              :search-by="['order_number']"
              track-by="id"
              :url="route('woocommerce-orders.search')"
            >
              <div v-if="order" class="flex items-center justify-between">
                <div class="truncate">{{ order.order_number }}</div>
                <div class="flex flex-col items-end whitespace-nowrap text-xs text-gray-600">
                  <span>{{ order.user.display_name }}</span>
                  <span>{{ $t('Total: $:total', { total: order.total }) }}</span>
                </div>
              </div>
              <template #option="{ option, selected }">
                <div class="flex items-center justify-between">
                  <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                    #{{ option.order_number }}
                  </div>
                  <div
                    class="flex flex-col items-end text-xs"
                    :class="{ 'text-gray-600': !selected, 'text-white': selected }"
                  >
                    <span>{{ option.user.display_name }}</span>
                    <span>{{ $t('Total: $:total', { total: option.total }) }}</span>
                  </div>
                </div>
              </template>
            </dynamic-search-input>
          </div>
          <div v-if="form.payment_gateway === 'Invoice'" class="w-1/2 pb-8 pr-6">
            <text-input
              v-model="form.invoice_number"
              :error="form.errors.invoice_number"
              :label="$t('Invoice Number')"
            />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Enrollment') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import SearchInput from '@/Shared/SearchInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import UserSearchInput from '@/Shared/UserSearchInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicSearchInput,
    Layout,
    LoadingButton,
    SearchInput,
    TextInput,
    UserSearchInput,
  },
  props: {
    courses: Array,
    targetOwner: Object,
    targetClass: Object,
    paymentGateways: Array,
  },
  data() {
    return {
      order: null,
      enrollers: null,
      course: null,
      class_: null,
      owner: null,
      user: null,
      access: 'public',
      form: useForm({
        course_id: null,
        class_id: null,
        owner_id: null,
        user_id: null,
        order_id: null,
        quantity: 1,
        payment_gateway: null,
        invoice_number: null,
      }),
    }
  },
  watch: {
    owner(newOwner) {
      this.form.owner_id = newOwner?.id
    },
    user(newUser) {
      this.form.user_id = newUser?.id
    },
    enroller(newEnroller) {
      this.form.enroller_id = newEnroller?.id
    },
    course(newCourse) {
      this.form.course_id = newCourse?.id
      if (!this.targetClass?.id) {
        this.class_ = null
        this.form.class_id = null
      }
    },
    order(newOrder) {
      this.form.order_id = newOrder ? newOrder.order_id : null
    },
    class_(newClass) {
      this.form.class_id = newClass ? newClass.id : null
    },
    access() {
      this.class_ = null
    },
    'form.payment_gateway'(newValue) {
      if (newValue && newValue === 'None') this.form.order_id = null
    },
  },
  mounted: function () {
    if (this.targetOwner) {
      this.owner = this.targetOwner
    }

    if (this.courses.length == 1) {
      this.course = this.courses[0]
    }

    if (this.targetClass.id) {
      this.class_ = this.targetClass
      this.form.class_id = this.targetClass.id
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('enrolments.store'))
    },
  },
}
</script>
