<template>
  <layout :title="subscriptionPlanAccess.subscriptionPlan.title">
    <div class="mb-8">
      <breadcrumb-admin :path="breadcrumbs" />
    </div>
    <div class="max-w-sm">
      <archived-message v-if="subscriptionPlanAccess.archived_at" class="mb-6">
        {{ $t('This has been archived.') }}
      </archived-message>
      <cancelled-message v-if="subscriptionPlanAccess.cancelled_at" class="mb-6">
        {{ $t('This has been cancelled.') }}
      </cancelled-message>
      <deleted-message v-if="subscriptionPlanAccess.deleted_at" class="mb-6">
        {{ $t('This has been deleted.') }}
      </deleted-message>
    </div>
    <div class="flex w-full flex-col gap-x-6 md:flex-row">
      <div class="w-1/2 overflow-hidden rounded bg-white shadow">
        <form>
          <div class="-mb-8 -mr-6 flex flex-wrap p-8">
            <div class="w-full pb-8 pr-6">
              <label class="form-label">{{ $t('Plan') }}:</label>
              <InertiaLink
                v-if="subscriptionPlanAccess.subscriptionPlan"
                class="mb-2 text-blue-600 underline"
                :href="route('subscription-plans.edit', subscriptionPlanAccess.subscriptionPlan.id)"
              >
                {{ subscriptionPlanAccess.subscriptionPlan.title }}
              </InertiaLink>
            </div>
            <div class="w-full pb-8 pr-6">
              <label class="form-label">User:</label>
              <InertiaLink
                v-if="subscriptionPlanAccess.user"
                class="mb-2 text-blue-600 underline"
                :href="route('users.edit', subscriptionPlanAccess.user.id)"
              >
                {{ subscriptionPlanAccess.user.name }}
              </InertiaLink>
            </div>
            <div class="flex w-full flex-col pb-8 pr-6 lg:w-1/2">
              <label class="form-label">{{ $t('Assigned By') }}:</label>
              <InertiaLink
                v-if="subscriptionPlanAccess.assignedBy"
                class="mb-2 text-blue-600 underline"
                :href="route('users.edit', subscriptionPlanAccess.assignedBy)"
              >
                {{ subscriptionPlanAccess.assignedBy.name }}
              </InertiaLink>
            </div>
            <div class="flex w-full flex-col pb-8 pr-6 lg:w-1/2">
              <label class="form-label">{{ $t('Assigned On') }}:</label>
              <show-date class="mb-2" :timestamp="subscriptionPlanAccess.assigned_at" />
            </div>
          </div>
          <div class="flex items-center border-t border-gray-100 bg-gray-100 px-8 py-4">
            <button
              v-if="subscriptionPlanAccess.can.delete"
              class="text-red-500 hover:underline"
              tabindex="-1"
              type="button"
              @click="destroy"
            >
              {{ $t('Delete') }}
            </button>
          </div>
        </form>
      </div>

      <div class="md:w-1/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <div class="flex flex-wrap">
            <div class="w-full bg-gray-400 px-4 py-4 text-sm font-bold text-gray-700">{{ $t('User') }}</div>
            <div class="w-full px-6 pb-5 pt-5">
              <user-search-input
                v-if="!subscriptionPlanAccess.user"
                v-model="user"
                :error="form.errors.user_id"
                :organization-id="organization.id"
              />
              <text-input v-else disabled="disabled" :modelValue="subscriptionPlanAccess.user.name" />
            </div>
            <div class="flex w-full items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <button v-if="subscriptionPlanAccess.user" class="text-red-500 hover:underline" @click="unassign">
                {{ $t('UnAssign') }}
              </button>
              <loading-button
                class="btn-blue ml-auto"
                :disabled="subscriptionPlanAccess.user"
                :loading="form.processing"
                type="button"
                @click="assign"
              >
                {{ $t('Assign') }}
              </loading-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModelTimestamps :model="subscriptionPlanAccess" />
    <div>
      <div class="mb-6 mt-12 flex items-center justify-between">
        <h2 class="text-lg">{{ $t('Enrolments') }}</h2>
      </div>
      <div class="overflow-x-auto rounded bg-white shadow">
        <table class="w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('User') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Status') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Created At') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2" />
            </tr>
          </thead>
          <tbody>
            <tr v-if="subscriptionPlanAccess.enrolments.data <= 0">
              <td class="border-t px-6 py-4" colspan="5">{{ $t('No enrolments found.') }}</td>
            </tr>
            <tr
              v-for="enrolment in subscriptionPlanAccess.enrolments.data"
              v-else
              :key="enrolment.id"
              class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
              :class="{ 'opacity-50': enrolment.is_cancelled || enrolment.is_deleted || enrolment.is_archived }"
              @click="$inertia.visit(route('enrolments.edit', enrolment.id))"
            >
              <td class="whitespace-nowrap border-t px-6 py-4">
                {{ enrolment.course.name }}
                <icon
                  v-if="enrolment.is_cancelled"
                  class="ml-2 inline-block h-3 w-3 stroke-gray-500"
                  name="forbidden"
                />
                <archived-badge v-if="enrolment.archived_at" />
                <deleted-badge v-if="enrolment.deleted_at" />
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                <span v-if="enrolment.user">{{ enrolment.user.name }}</span>
                <span v-else>-</span>
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                <front-course-status :status="enrolment.status" />
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                <show-date :timestamp="enrolment.created_at" />
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4" />
            </tr>
          </tbody>
        </table>
      </div>
      <pagination :links="subscriptionPlanAccess.enrolments.links" />
    </div>
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import CancelledMessage from '@/Shared/CancelledMessage.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import FrontCourseStatus from '@/Shared/FrontCourseStatus.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import ModelTimestamps from '@/Shared/ModelTimestamps.vue'
import Pagination from '@/Shared/Pagination.vue'
import ShowDate from '@/Shared/ShowDate.vue'
import TextInput from '@/Shared/TextInput.vue'
import UserSearchInput from '@/Shared/UserSearchInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    ShowDate,
    ArchivedBadge,
    ArchivedMessage,
    BreadcrumbAdmin,
    CancelledMessage,
    DeletedBadge,
    DeletedMessage,
    FrontCourseStatus,
    Icon,
    Layout,
    LoadingButton,
    ModelTimestamps,
    Pagination,
    TextInput,
    UserSearchInput,
  },
  props: {
    subscriptionPlanAccess: Object,
    organization: Object,
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$t('Organizations'),
          url: this.route('organizations.index'),
        },
        {
          name: this.organization.name,
          url: this.route('organizations.edit', this.organization.id),
        },
        {
          name: this.$t('Subscription Plan Access ID') + ` : ${this.subscriptionPlanAccess.id}`,
        },
      ],
      user: this.subscriptionPlanAccess.user,
      form: useForm(
        {
          user_id: this.subscriptionPlanAccess.user_id,
        },
        'SubscriptionPlanAccessEdit'
      ),
    }
  },
  watch: {
    user(newUser) {
      this.form.user_id = newUser?.id
    },
  },
  methods: {
    assign() {
      this.form.post(this.route('subscription-plan-access.assign', this.subscriptionPlanAccess.id))
    },
    unassign() {
      this.form.delete(this.route('subscription-plan-access.assign.delete', this.subscriptionPlanAccess.id))
    },
    destroy() {
      if (
        confirm(
          this.$t(
            'Are you sure you want to delete this subscription access?\nAll enrolments in progress will be deleted and the subscription quantity will be reduced.'
          )
        )
      ) {
        this.$inertia.delete(this.route('subscription-plan-access.delete', this.subscriptionPlanAccess))
      }
    },
  },
}
</script>
