<template>
  <div class="w-full max-w-lg overflow-hidden rounded-lg border bg-white shadow">
    <div class="flex flex-col p-4">
      <h2 class="mb-5 mt-0 text-xl">{{ subscriptionPlan.title }}</h2>
      <p class="mb-1 text-sm leading-tight text-darkGray-800">
        {{ subscriptionPlan.description }}
      </p>
    </div>
    <div class="flex items-center justify-between bg-gray-100 p-4">
      <template v-if="!isSubscribed">
        <div class="flex justify-end w-full">
          <button class="btn-blue" @click="showCreateSubscriptionModal = true">
            {{ $t('Subscribe') }}
          </button>
        </div>
      </template>
      <div v-else class="float-right px-2 py-2 text-sm text-gray-600">{{ $t('Currently Subscribed') }}</div>
    </div>
  </div>
  <CreateSubscriptionWithTrialModal
    :organization="organization"
    :show="showCreateSubscriptionModal"
    :subscriptionPlan="subscriptionPlan"
    @close="closeSubscriptionTrialModal"
  />
</template>
<script>
import CreateSubscriptionWithTrialModal from './CreateSubscriptionWithTrialModal.vue'

export default {
  components: {
    CreateSubscriptionWithTrialModal,
  },
  props: {
    organization: Object,
    subscriptionPlan: Object,
    isSubscribed: Boolean,
  },
  data() {
    return {
      showCreateSubscriptionModal: false,
    }
  },
  methods: {
    closeSubscriptionTrialModal() {
      this.showCreateSubscriptionModal = false
    },
  },
}
</script>
