<template>
  <layout :title="$t('Courses')">
    <div class="mb-8">
      <breadcrumb :name="$t('Courses')" />
    </div>
    <div class="mb-6 flex items-center justify-between">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-800">{{ $t('Status:') }}</label>
        <select v-model="form.status" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="selling">{{ $t('Selling') }}</option>
          <option value="selling_exclusive">{{ $t('Selling (Exclusive)') }}</option>
          <option value="reselling">{{ $t('Reselling') }}</option>
        </select>
        <label v-if="courseCategories.length > 0" class="mt-4 block text-gray-800">{{ $t('Course Category:') }}</label>
        <select v-if="courseCategories.length > 0" v-model="form.category" class="form-select mt-1 w-full">
          <option :value="null" />
          <option v-for="category in courseCategories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Archived:') }}</label>
        <select v-model="form.archived" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Archived') }}</option>
          <option value="only">{{ $t('Only Archived') }}</option>
        </select>
        <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
        <select v-model="form.deleted" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">{{ $t('With Deleted') }}</option>
          <option value="only">{{ $t('Only Deleted') }}</option>
        </select>
      </search-filter>
      <dropdown class="btn-blue" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700" :href="route('courses.create')">
              {{ $t('Create new course') }}
            </InertiaLink>
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('course-categories')"
            >
              {{ $t('Manage categories') }}
            </InertiaLink>
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('reselling_courses.create')"
            >
              {{ $t('Resell a course') }}
            </InertiaLink>
          </div>
        </template>
      </dropdown>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Name (FR)') }}</TableHead>
          <TableHead>{{ $t('Category') }}</TableHead>
          <TableHead>{{ $t('Required Contents') }}</TableHead>
          <TableHead>{{ $t('Price') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="courses.data.length === 0">
          <TableData colspan="5">{{ $t('No courses found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="course in courses.data"
          :key="course.id"
          :clickable="true"
          @click="$inertia.visit(route(course.reselling ? 'courses.show' : 'courses.edit', course.id))"
        >
          <TableData>
            <div class="flex items-center">
              <span class="whitespace-nowrap">{{ course.name }}</span>
              <span v-if="course.reselling" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                {{ $t('Reselling') }}
              </span>
              <ArchivedBadge v-if="course.archived_at" />
              <DeletedBadge v-if="course.deleted_at" />
            </div>
          </TableData>
          <TableData>
            <div class="flex items-center">
              <span class="whitespace-nowrap">{{ course.name_fr || '-' }}</span>
            </div>
          </TableData>
          <TableData>
            <div class="flex items-center">
              <span class="whitespace-nowrap">{{ course.category?.name }}</span>
            </div>
          </TableData>
          <TableData class="text-left">
            <span>{{ course.required_contents_count }}</span>
          </TableData>
          <TableData class="text-right">
            <span v-if="course.price">${{ course.price }}</span>
            <span v-else class="text-sm text-gray-400">-</span>
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="courses.links" />
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    SimpleTable,
    TableHead,
    TableData,
    TableRow,
    Breadcrumb,
    DeletedBadge,
    Dropdown,
    Icon,
    Layout,
    Pagination,
    SearchFilter,
    ArchivedBadge,
  },
  props: {
    courses: Object,
    filters: Object,
    courseCategories: Array,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        status: this.filters.status,
        parent: this.filters.parent,
        archived: this.filters.archived,
        deleted: this.filters.deleted,
        category: this.filters.category,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(this.route('courses'), Object.keys(query).length ? query : { remember: 'forget' }, {
          preserveState: true,
          replace: true,
        })
      }, 300),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
