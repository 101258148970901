<template>
  <layout :title="$t('Create Location')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('locations')">
        {{ $t('Locations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ $t('Create') }}
    </h1>
    <div class="max-w-lg overflow-hidden rounded bg-white shadow">
      <form @submit.prevent="submit">
        <div class="-mb-8 -mr-6 flex flex-wrap p-8">
          <div class="w-full pb-8 pr-6">
            <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <text-input v-model="form.capacity" :error="form.errors.capacity" :label="$t('Capacity')" />
          </div>
          <div class="w-full pb-8 pr-6 lg:w-1/2">
            <label class="form-label">{{ $t('Type:') }}</label>
            <select v-model="form.type" class="form-select mt-1 w-full">
              <option v-for="(type, index) in types" :key="index" :value="index">
                {{ type }}
              </option>
            </select>
          </div>
          <div class="flex w-full flex-wrap">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <label class="form-label">{{ $t('Enter a location:') }}</label>
              <google-maps-autocomplete v-model="fullAddress" :placeholder="$t('Look for an Address')" />
              <span v-if="form.type === 'webinar'" class="mt-2 block text-sm text-gray-600">
                {{ $t('This will autocomplete the timezone field.') }}
              </span>
              <span v-if="form.type === 'class'" class="mt-2 block text-sm text-gray-600">
                {{ $t('This will autocomplete most of the fields below.') }}
              </span>
            </div>
            <div v-if="form.type !== 'webinar'" class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input v-model="form.timezone" disabled :error="form.errors.timezone" :label="$t('Timezone')" />
            </div>
            <div v-else class="w-full pb-8 pr-6 lg:w-1/2">
              <select-input v-model="form.timezone" :error="form.errors.timezone" :label="$t('Timezone')">
                <option :value="null" />
                <option v-for="(timezone, index) in timezones" :key="index" :value="timezone">
                  {{ timezone }}
                </option>
              </select-input>
            </div>
          </div>
          <div v-if="form.type === 'class'" class="flex flex-wrap">
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.address"
                :disabled="!hasUsedAutocompleteField"
                :error="form.errors.address"
                :label="$t('Address')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.city"
                :disabled="!hasUsedAutocompleteField"
                :error="form.errors.city"
                :label="$t('City')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.region"
                :disabled="!hasUsedAutocompleteField"
                :error="form.errors.region"
                :label="$t('Province/State')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <text-input
                v-model="form.postal_code"
                :disabled="!hasUsedAutocompleteField"
                :error="form.errors.postal_code"
                :label="$t('Postal code')"
              />
            </div>
            <div class="w-full pb-8 pr-6 lg:w-1/2">
              <radio-input
                v-model="clientSite"
                :error="form.errors.client_site"
                :label="$t('Client site')"
                type="radio"
              />
            </div>
            <div v-if="clientSite" class="w-full pb-8 pr-6 lg:w-1/2">
              <dynamic-search-input
                v-model="selectedOrganization"
                :error="form.errors.organization_id"
                :label="$t('Organization')"
                :search-by="['name']"
                track-by="id"
                :url="route('organizations.search', { access_codes_enabled: true })"
              >
                <div v-if="selectedOrganization" class="truncate">{{ selectedOrganization.name }}</div>
                <template #option="{ option }">
                  {{ option.name }}
                </template>
              </dynamic-search-input>
              <span class="mt-2 block text-sm text-gray-600">
                {{ $t('Location availability can be limited to an organization.') }}
              </span>
            </div>
            <div v-if="form.type === 'class' && !clientSite" class="w-full pb-8 pr-6 lg:w-1/2">
              <radio-input
                v-model="isOwned"
                :error="form.errors.is_owned"
                :label="$t('Property Owned/Leased (Not a hotel)')"
                type="radio"
              />
            </div>
            <div class="flex w-full items-end pb-8 pr-6 lg:w-1/2">
              <a
                v-if="can.getLocationsFromMyBusiness && !myBusinessLocations.length"
                class="text-gray-700 hover:underline"
                :href="route('login.google')"
              >
                {{ $t('Show My Business locations list') }}
              </a>
              <select-input
                v-if="can.getLocationsFromMyBusiness && myBusinessLocations.length"
                v-model="form.my_business_location_url"
                :error="form.errors.my_business_location_url"
                :label="$t('My Business Location')"
              >
                <option :value="null" />
                <option
                  v-for="(myBusinessLocation, key) in myBusinessLocations"
                  :key="key"
                  :value="myBusinessLocation.url"
                >
                  {{ myBusinessLocation.address }} -
                  {{ myBusinessLocation.name }}
                </option>
              </select-input>
            </div>
            <div class="w-full pb-8 pr-6">
              <textarea-input
                v-model="form.parking_info"
                :error="form.errors.parking_info"
                :label="$t('Parking info')"
              />
            </div>
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input
              v-model="form.additional_directions"
              :error="form.errors.additional_directions"
              :help="$t('This field will be shown in confirmation emails sent to clients.')"
              :label="$t('Additional Directions')"
            />
            <span class="mt-1 block text-sm text-gray-500">
              <small>{{ $t('This information will be displayed in booking confirmation emails.') }}</small>
            </span>
          </div>
          <div class="w-full pb-8 pr-6">
            <textarea-input v-model="form.description" :error="form.errors.description" :label="$t('Description')" />
          </div>
        </div>
        <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
          <loading-button class="btn-blue" :loading="form.processing" type="submit">
            {{ $t('Create Location') }}
          </loading-button>
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import GoogleMapsAutocomplete from '@/Shared/GoogleMapsAutocomplete.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicSearchInput,
    GoogleMapsAutocomplete,
    Layout,
    LoadingButton,
    RadioInput,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    can: Object,
    timezones: Array,
    myBusinessLocations: Array,
    types: Object,
  },
  data() {
    return {
      fullAddress: {},
      hasUsedAutocompleteField: false,
      clientSite: false,
      isOwned: false,
      selectedOrganization: null,
      form: useForm(
        {
          name: null,
          address: null,
          city: null,
          region: null,
          country: null,
          postal_code: null,
          timezone: null,
          my_business_location_url: null,
          latitude: null,
          longitude: null,
          description: null,
          additional_directions: null,
          parking_info: null,
          capacity: null,
          client_site: false,
          is_owned: false,
          type: 'class',
          organization_id: null,
        },
        'LocationsCreate'
      ),
    }
  },
  watch: {
    fullAddress: {
      handler: function (val) {
        this.form.address = (val.street_number || '') + ' ' + (val.route || '')
        this.form.city = val.locality
        this.form.region = val.administrative_area_level_1
        this.form.country = val.country
        this.form.postal_code = val.postal_code
        this.form.timezone = val.timeZone
        this.form.latitude = val.latitude
        this.form.longitude = val.longitude

        this.hasUsedAutocompleteField = true
      },
      deep: true,
    },
    isOwned: {
      handler: function (val) {
        this.form.is_owned = val
        if (val) this.clientSite = false
      },
    },
    clientSite: {
      handler: function (val) {
        this.form.client_site = val
        if (val) this.isOwned = false
      },
    },
    selectedOrganization(organization) {
      this.form.is_owned = !organization
      this.form.organization_id = organization ? organization.id : null
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('organization[id]')) {
      this.form.client_site = true
      this.form.organization_id = urlParams.get('organization[id]')
      this.selectedOrganization = {
        id: urlParams.get('organization[id]'),
        name: urlParams.get('organization[name]'),
      }
    }
  },
  methods: {
    submit() {
      this.resetFields()
      this.form.post(this.route('locations.store'))
    },
    resetFields() {
      if (this.form.type !== 'class') {
        this.form.address = null
        this.form.city = null
        this.form.region = null
        this.form.country = null
        this.form.postal_code = null
        this.form.my_business_location_url = null
        this.form.latitude = null
        this.form.longitude = null
        this.form.parking_info = null
        this.form.client_site = false
        this.form.organization_id = null
        this.selectedOrganization = null
      }
    },
  },
}
</script>
