<template>
  <Layout :title="`Marketing Settings`">
    <BreadcrumbAdmin :path="[{ name: 'Marketing Settings' }]" />
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="onSubmit">
            <div
              v-for="(injectionScript, index) in form.injectionScripts"
              :key="injectionScript.id"
              class="flex flex-wrap p-8 space-y-4"
              :class="{ 'border-t-2 border-gray-200': index !== 0 }"
            >
              <div class="w-full pr-6">
                <TextShow v-model="form.injectionScripts[index].name" :label="$t('Name')" />
              </div>
              <div class="w-full pr-6">
                <TextareaInput
                  v-model="form.injectionScripts[index].script"
                  :error="form.errors.injectionScripts?.[index]?.script"
                  :label="$t('Script')"
                />
              </div>
            </div>

            <div class="flex items-center justify-end border-t border-gray-100 bg-gray-100 px-8 py-4">
              <LoadingButton class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update') }}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script setup>
import BreadcrumbAdmin from '@/Shared/BreadcrumbAdmin.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import TextShow from '@/Shared/TextShow.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps({
  injectionScripts: {
    type: Object,
    required: true,
  },
})

const form = useForm({
  injectionScripts: Object.values(props.injectionScripts).map((injectionScript) => ({
    id: injectionScript.id,
    name: injectionScript.name,
    script: injectionScript.script,
  })),
})

const onSubmit = () => {
  form
    .transform((data) => ({
      injectionScripts: data.injectionScripts.map((injectionScript) => ({
        id: injectionScript.id,
        script: injectionScript.script,
      })),
    }))
    .put(route('marketing.settings.update'), {
      preserveScroll: true,
    })
}
</script>
