<template>
  <modal class="rounded text-base" :show="show" style="z-index: 100000" width="50rem" @close="close">
    <div class="max-h-screen max-w-lg overflow-auto rounded bg-white shadow">
      <div class="mt-6 flex items-center justify-evenly border-b border-ts-gray-quiz-progress-bar pb-10 pt-2">
        <div class="flex flex-col items-center justify-center px-2">
          <div class="mb-3 h-5 w-5 rounded-full border-4 border-qualify-red-600 bg-white" />
          <span class="cursor-default text-center text-sm font-semibold text-gray-600">
            {{ $t('Review and Confirm') }}
          </span>
        </div>
        <div class="flex flex-col items-center justify-center px-2">
          <div
            class="mb-3 h-5 w-5 rounded-full border-4 bg-white"
            :class="step >= 2 ? 'border-qualify-red-600' : 'border-ts-gray-300'"
          />
          <span
            class="cursor-default text-center text-sm font-semibold"
            :class="step >= 2 ? 'text-gray-600' : 'text-gray-400'"
          >
            {{ $t('Payment Method') }}
          </span>
        </div>
      </div>

      <div v-show="step === 1">
        <div class="p-6 md:px-10 md:py-6">
          <table class="w-full select-none">
            <thead>
              <tr>
                <th class="whitespace-nowrap py-3 text-left text-sm font-semibold uppercase text-gray-400">
                  {{ $t('Name') }}
                </th>
                <th class="whitespace-nowrap py-3 text-left text-sm font-semibold uppercase text-gray-400">
                  {{ $t('No. of users') }}
                </th>
                <th class="whitespace-nowrap py-3 text-right text-sm font-semibold uppercase text-gray-400">
                  {{ $t('Subtotal') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="plan in plans" :key="plan.id">
                <td class="whitespace-nowrap py-4 font-semibold">
                  {{ plan.title }}
                </td>
                <td class="w-auto whitespace-nowrap py-4">
                  <div class="flex items-center">
                    <MinusCircleIcon
                      aria-hidden="true"
                      class="mr-2 h-8 w-8 cursor-pointer text-gray-300 transition duration-200 ease-in hover:text-gray-600"
                      @click="decreaseQuantity(plan)"
                    />
                    <input
                      v-model="plan.quantity"
                      class="h-8 w-20 rounded-lg border border-2 bg-ts-front-field-light p-2 text-center text-ts-front-label focus:border-gray-400 focus:text-gray-600 focus:outline-none"
                      type="text"
                      @change="updateQuantity(plan)"
                    />
                    <PlusCircleIcon
                      aria-hidden="true"
                      class="ml-2 h-8 w-8 cursor-pointer text-gray-300 transition duration-200 ease-in hover:text-gray-600"
                      @click="increaseQuantity(plan)"
                    />
                  </div>
                </td>
                <td v-if="plan.amount" class="whitespace-nowrap py-4 text-right font-semibold">
                  ${{ $t(':amount / :interval', { amount: plan.amount, interval: plan.price.recurring.interval }) }}
                </td>
                <td
                  v-else-if="plan.price.billing_scheme === 'tiered'"
                  class="whitespace-nowrap py-4 text-right font-semibold"
                >
                  ${{
                    $t(':amount / :interval', {
                      amount: plan.price.tiers?.[0].amount.toFixed(2),
                      interval: plan.price.recurring.interval,
                    })
                  }}
                </td>
                <td
                  v-else-if="plan.price.billing_scheme === 'per_unit'"
                  class="whitespace-nowrap py-4 text-right font-semibold"
                >
                  ${{
                    $t(':amount / :interval', {
                      amount: plan.price.amount.toFixed(2),
                      interval: plan.price.recurring.interval,
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div class="flex p-6 md:px-10 md:py-6">
          <div class="w-1/2">
            <div class="font-semibold">
              {{ $t('Monthly Cost') }}
            </div>
          </div>
          <div class="w-1/2 text-right text-xl font-semibold">
            ${{ $t(':amount / month', { amount: getMonthlyAmount }) }}
          </div>
        </div>
      </div>

      <div v-show="step === 2" class="p-6 md:px-10 md:py-6">
        <div class="mb-4">
          <div v-if="defaultPaymentMethod">
            <div v-show="defaultPaymentMethod && !addPayment" class="p-3">
              <div class="border-1 flex w-full items-center justify-between rounded-lg border bg-white p-3">
                <div class="flex items-center">
                  <div class="pr-4">
                    <img
                      alt="`${defaultPaymentMethod.card.brand} card icon`"
                      class="w-12"
                      :src="`/images/${defaultPaymentMethod.card.brand}-icon.svg`"
                    />
                  </div>
                  <div>
                    <div class="font-semibold">
                      {{ $t('Card ending in :last4', { last4: defaultPaymentMethod.card.last4 }) }}
                    </div>
                    <div class="mt-1 text-sm text-ts-gray-text">
                      {{
                        $t('Expires: :exp_month/:exp_year', {
                          exp_month: String(defaultPaymentMethod.card.exp_month).padStart(2, '0'),
                          exp_year: defaultPaymentMethod.card.exp_year,
                        })
                      }}
                    </div>
                  </div>
                </div>
                <div>
                  <span
                    class="rounded-md bg-[#467082]/10 px-3 py-1 text-center text-sm font-semibold uppercase text-[#467082]"
                  >
                    {{ $t('DEFAULT') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="addPayment || !defaultPaymentMethod">
            <div class="p-3">
              <label class="mb-2 block text-lg text-darkGray-700" for="name">{{ $t('Name on card') }}</label>
              <front-text-input v-model="paymentForm.name" if="name" placeholder="Name on Card" />
            </div>
            <div class="p-3">
              <label class="mb-2 block text-lg text-darkGray-700">{{ $t('Card information') }}</label>
              <div id="card-element" class="border-1 rounded-lg border bg-white p-3" />
              <div v-if="stripe_error" class="form-error">{{ stripe_error }}</div>
            </div>
          </div>
          <div class="px-3 pb-3">
            <button
              v-if="defaultPaymentMethod"
              class="mt-1 text-sm font-semibold text-[#467082] underline"
              @click="addPaymentMethod()"
            >
              {{ addPayment ? $t('Use existing payment method') : $t('+ Add another payment method') }}
            </button>
          </div>
        </div>
        <div class="mb-4 p-3">
          <label class="mb-2 block text-lg text-darkGray-700">{{ $t('Billing information') }}</label>
          <div class="border-1 flex flex-wrap rounded-lg border bg-white py-2">
            <div class="w-full p-5 py-3 sm:w-1/2">
              <front-text-input v-model="form.email" :error="form.errors.email" :label="$t('Email')" />
            </div>
            <div class="w-full px-5 py-3 sm:w-1/2">
              <front-text-input
                v-model="form.address.line1"
                :error="form.errors['billingDetails.address.line1']"
                :label="$t('Address')"
              />
            </div>
            <div class="w-full px-5 py-3 sm:w-1/2">
              <front-text-input
                v-model="form.address.city"
                :error="form.errors['billingDetails.address.city']"
                :label="$t('City')"
              />
            </div>
            <div class="w-full px-5 py-3 sm:w-1/2">
              <front-select-input
                v-model="form.address.state"
                :error="form.errors['billingDetails.address.state']"
                :label="$t('Province/State')"
                :options="getRegions"
              />
            </div>
            <div class="w-full px-5 py-3 sm:w-1/2">
              <front-text-input
                v-model="form.address.postal_code"
                :error="form.errors['billingDetails.address.postal_code']"
                :label="$t('Postal Code')"
              />
            </div>
            <div class="w-full px-5 py-3 sm:w-1/2">
              <front-select-input
                v-model="form.address.country"
                :error="form.errors['billingDetails.address.country']"
                :label="$t('Country')"
                :options="[null, ...getCountries]"
                @change="handleBillingCountryChange($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-gray-200/60 px-6 py-4 md:px-10 md:py-8">
        <div class="flex items-center justify-between">
          <front-secondary-button
            class="mx-2 transform px-12 py-4 transition duration-300 ease-in-out"
            :disabled="form.processing"
            type="button"
            @click="step === 1 ? close() : back()"
          >
            {{ cancel_button_label }}
          </front-secondary-button>
          <front-loading-button
            class="mx-2 transform px-12 py-4 transition duration-300 ease-in-out"
            :loading="form.processing"
            @click="next"
          >
            {{ next_button_label }}
          </front-loading-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontSecondaryButton from '@/Shared/FrontSecondaryButton.vue'
import FrontSelectInput from '@/Shared/FrontSelectInput.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Modal from '@/Shared/Modal.vue'
import { googleTagManagerEvent } from '@/Utils/GoogleTagManager'
import Http from '@/Utils/Http'
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/vue/20/solid'
import { useForm } from '@inertiajs/vue3'
import moment from 'moment'

export default {
  components: {
    PlusCircleIcon,
    MinusCircleIcon,
    FrontLoadingButton,
    FrontSecondaryButton,
    FrontSelectInput,
    FrontTextInput,
    Modal,
  },
  props: {
    show: Boolean,
    organization: Object,
    selectedPlans: Array,
    paymentMethods: Array,
    defaultPaymentMethod: Object,
    regions: Array,
  },
  data() {
    return {
      plans: this.selectedPlans,
      intent: null,
      stripe_error: null,
      step: 1,
      addPayment: false,
      paymentForm: useForm({
        name: this.$page.props.auth.user.display_name,
      }),
      form: useForm({
        subscriptionPlans: null,
        paymentMethodId: null,
        email: this.$page.props.auth.user.email,
        address: {
          line1: this.$page.props.auth.user.address,
          city: this.$page.props.auth.user.city,
          state: this.$page.props.auth.user.region ?? 'Ontario',
          postal_code: this.$page.props.auth.user.postal_code,
          country: this.$page.props.auth.user.country ?? 'CA',
        },
      }),
    }
  },
  computed: {
    getFeatures() {
      return this.subscription.items[0].subscriptionPlan.features?.split('|')
    },
    getNextBillingDate() {
      return moment().add(1, 'months').format('YYYY-MM-DD')
    },
    getMonthlyAmount() {
      let amt = 0
      return this.plans.reduce((acc, plan) => {
        if (plan.amount) {
          amt = plan.amount
          if (plan.price.recurring.interval === 'year') {
            amt = amt / 12
          }
        } else if (plan.price.billing_scheme === 'tiered') {
          amt = plan.price.tiers?.[0].amount
          if (plan.price.recurring.interval === 'year') {
            amt = amt / 12
          }
        } else if (plan.price.billing_scheme === 'per_unit') {
          amt = plan.price.amount
          if (plan.price.recurring.interval === 'year') {
            amt = amt / 12
          }
        }
        return (parseFloat(acc) + parseFloat(amt)).toFixed(2)
      }, 0)
    },
    getTotalAmount() {
      let amt = 0
      return this.plans.reduce((acc, plan) => {
        if (plan.amount) {
          amt = plan.amount
        } else if (plan.price.billing_scheme === 'tiered') {
          amt = plan.price.tiers?.[0].amount
        } else if (plan.price.billing_scheme === 'per_unit') {
          amt = plan.price.amount
        }
        return (parseFloat(acc) + parseFloat(amt)).toFixed(2)
      }, 0)
    },
    next_button_label() {
      if (this.step === 2) {
        const price = this.getTotalAmount
        return this.$t(`Pay (${price} + tax) and Subscribe`)
      }
      return this.$t('Next')
    },
    cancel_button_label() {
      return this.step === 1 ? this.$t('Cancel') : this.$t('Back')
    },
    getRegions() {
      return this.regions.filter((region) => region.country === this.form.address.country).map((i) => i['name'])
    },
    getCountries() {
      return ['CA', 'US']
    },
  },
  watch: {
    show: function (newVal) {
      if (newVal && !this.intent) {
        this.setIntent()
      }
    },
  },
  async mounted() {
    this.stripe = Stripe(import.meta.env.VITE_STRIPE_KEY)
    const elements = this.stripe.elements()
    this.cardElement = elements.create('card', {
      classes: {
        base: 'block w-full px-3 py-3 border border-gray-200 bg-ts-front-field-light text-ts-front-label rounded-lg transition duration-150 ease-in-out',
      },
      style: {
        base: {
          fontWeight: '300',
          fontSize: '17px',
          color: '#2d3748',
        },
      },
    })

    this.cardElement.mount('#card-element')
  },
  methods: {
    setIntent() {
      Http.get(this.route('front.organization.stripe.intent', this.organization.id)).then(
        (response) => (this.intent = response.data)
      )
    },
    async submit() {
      if (this.defaultPaymentMethod && !this.addPayment) {
        this.submitLastStep()
        return
      }

      this.stripe_error = null

      if (!this.intent) {
        this.stripe_error = 'Something went wrong, order cannot be processed.'
      }

      const { setupIntent, error } = await this.stripe.confirmCardSetup(this.intent.client_secret, {
        payment_method: {
          card: this.cardElement,
          billing_details: { name: this.paymentForm.name, email: this.form.email, address: this.form.address },
        },
      })

      if (error) {
        this.stripe_error = error.message
        this.intent = null
        this.setIntent()
      } else {
        this.submitLastStep(setupIntent)
      }
    },
    increaseQuantity(plan) {
      plan.quantity++
      this.updateQuantity(plan)
    },
    decreaseQuantity(plan) {
      if (plan.quantity > 1) {
        plan.quantity--
        this.updateQuantity(plan)
      }
    },
    close() {
      this.$emit('close')
      this.form.reset()
      this.form.clearErrors()
      this.cardElement.clear()
      this.intent = null
    },
    updateQuantity(plan) {
      axios
        .get(this.route('front.admin.subscription.plans.pricing-preview', [plan.id]), {
          params: {
            quantity: plan.quantity,
          },
        })
        .then((response) => {
          plan.amount = response.data.amount.replace(',', '')
        })
        .catch(() => console.log('error'))
    },
    submitLastStep(setupIntent = null) {
      this.form
        .transform(() => ({
          subscriptionPlans: this.plans,
          paymentMethodId: setupIntent?.payment_method,
          billingDetails: {
            email: this.form.email,
            address: this.form.address,
          },
        }))
        .post(this.route('front.admin.subscription.plans.store'), {
          onSuccess: () => {
            googleTagManagerEvent('plan_checkout')
            this.close()
          },
        })
    },
    back() {
      this.step--
      this.cardElement.clear()
      this.setIntent()
      this.addPayment = false
    },
    next() {
      if (this.step === 2) {
        return this.submit()
      }

      if (this.step === 1) {
        googleTagManagerEvent('plan_begin_checkout')
      }
      this.step++
    },
    addPaymentMethod() {
      this.addPayment = !this.addPayment
    },
    handleBillingCountryChange(event) {
      this.form.address.country = event.target.value
    },
  },
}
</script>
