<template>
  <div class="flex min-h-screen justify-center bg-white">
    <DynamicHead :title="$t('Log In | :app_name', { app_name: $page.props.app.name })" />
    <div class="px-4 pt-20 lg:w-1/2 lg:pl-20 lg:pr-8">
      <div class="ml-auto max-w-md">
        <img
          v-if="accountLogo && !isTenantWorksite"
          id="custom-tenant-logo"
          :alt="$t('Account Logo')"
          class="h-12 max-w-md"
          :src="accountLogo"
        />
        <worksite-dark v-else class="mx-auto sm:mx-0" />
        <h2 class="mb-8 mt-20 text-2xl leading-9 text-gray-900">{{ $t('Sign In') }}</h2>
        <form @submit.prevent="submit">
          <div v-if="$page.props.errors['token']" class="mb-4 flex items-center justify-between rounded bg-red-400">
            <div class="flex items-center">
              <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
              <div class="py-4 text-sm font-medium text-white">
                <span>{{ $page.props.errors['token'] }}</span>
              </div>
            </div>
          </div>
          <div class="-mx-3 flex max-w-xs flex-wrap">
            <div class="w-full p-3">
              <div v-if="$page.props.flash.success" class="-mt-4 mb-6 flex max-w-lg items-center rounded bg-green-500">
                <icon class="mx-4 h-4 w-4 flex-shrink-0 fill-white" name="checkmark" />
                <div class="py-4 text-sm font-medium text-white">{{ $page.props.flash.success }}</div>
              </div>
              <label class="mb-2 block text-lg text-darkGray-700" for="email">{{ $t('Email / Username') }}</label>
              <input
                id="email"
                v-model="form.email"
                autocapitalize="off"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                required
                type="text"
              />
              <div v-if="form.errors.email" class="form-error">{{ form.errors.email }}</div>
              <div v-if="form.errors.username" class="form-error">{{ form.errors.username }}</div>
            </div>

            <div class="w-full p-3">
              <label class="mb-2 block text-lg text-darkGray-700" for="password">{{ $t('Password') }}</label>
              <div class="relative">
                <input
                  id="password"
                  v-model="form.password"
                  class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                  required
                  :type="showPassword ? 'text' : 'password'"
                />
                <div class="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
                  <icon
                    v-if="!showPassword"
                    class="h-5 w-5 cursor-pointer"
                    name="password-eye"
                    @click="showPassword = !showPassword"
                  />
                  <icon
                    v-else
                    class="h-5 w-5 cursor-pointer"
                    name="password-eye-slash"
                    @click="showPassword = !showPassword"
                  />
                </div>
              </div>
              <div v-if="form.errors.password" class="form-error">{{ form.errors.password }}</div>
            </div>
          </div>
          <label class="mt-6 flex select-none items-center" for="remember">
            <input id="remember" v-model="form.remember" class="form-checkbox mr-1" type="checkbox" />
            <span class="text-sm">{{ $t('Remember Me') }}</span>
          </label>
          <FrontLoadingButton class="mt-6" :loading="form.processing" type="submit">
            {{ $t('Login') }}
          </FrontLoadingButton>
        </form>

        <InertiaLink
          class="hover-animate text-darkGray mb-4 mt-10 block text-lg hover:no-underline"
          :href="route('front.login.otp', { email: form.email })"
        >
          {{ $t('Sign in with a one time password') }}
          <icon class="currentColor inline h-5 w-5 transform transition duration-500" name="chevron-right" />
        </InertiaLink>

        <div class="py-10">
          <span class="text-darkGray-700 opacity-65">{{ $t('Powered By') }}</span>
          <qualify-dark class="ml-2 inline opacity-25" />
        </div>
      </div>
    </div>
    <div
      class="hidden items-center justify-center bg-cover lg:flex lg:w-1/2"
      style="background-image: url('/images/Qualify_LOGIN_BG.svg')"
    >
      <img alt="$t('Login')" class="w-3/4" src="/images/login.svg" />
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import Icon from '@/Shared/Icon.vue'
import QualifyDark from '@/Shared/Logo/QualifyDark.vue'
import WorksiteDark from '@/Shared/Logo/WorksiteDark.vue'
import { isTenantWorksite } from '@/Utils/Helpers'
import zendesk from '@/mixins/zendesk.js'
import { useForm } from '@inertiajs/vue3'

export default {
  components: { FrontLoadingButton, DynamicHead, Icon, QualifyDark, WorksiteDark },
  mixins: [zendesk],
  props: {
    user: Object,
  },
  data() {
    return {
      accountLogo: this.$page.props.app.tenant.logo,
      form: useForm({
        email: this.user.email ?? this.user.username,
        password: '',
        remember: null,
      }),
      showPassword: false,
    }
  },
  computed: {
    isTenantWorksite,
  },
  mounted() {
    // Auth Check Zendesk Chat Widget
    this.zE_auth()
  },
  methods: {
    submit() {
      this.form.post(this.route('front.login.attempt'))
    },
  },
}
</script>

<style scoped>
.hover-animate:hover > svg {
  --transform-translate-x: 0.5rem;
}
</style>
