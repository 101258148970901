<template>
  <layout :title="$t('Proctoring Analysis')">
    <div class="mb-8 flex items-center justify-between">
      <h1 class="flex text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('reports')">
          {{ $t('Reports') }}
        </InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ $t('Proctoring Analysis') }}
      </h1>
      <div class="cursor-default rounded-lg bg-gray-400 p-2 font-semibold text-white">
        {{ totalStudentsInTraining.toLocaleString() }} in training
      </div>
    </div>

    <!-- Filters -->
    <div class="mb-6 rounded bg-white p-4 shadow">
      <form class="flex flex-col space-y-4 sm:flex-row sm:items-end sm:space-y-0 sm:space-x-4" @submit.prevent="submit">
        <div class="w-full sm:w-auto">
          <label class="form-label" for="course_id">{{ $t('Course') }}</label>
          <select id="course_id" v-model="form.course_id" class="form-select mt-1 w-full">
            <option value="">{{ $t('All Courses') }}</option>
            <option v-for="course in courses" :key="course.id" :value="course.id">
              {{ course.name }}
            </option>
          </select>
        </div>
        <div class="w-full sm:w-auto">
          <label class="form-label" for="start_date">{{ $t('Start Date') }}</label>
          <input id="start_date" v-model="form.start_date" class="form-input mt-1 w-full" type="date" />
        </div>
        <div class="w-full sm:w-auto">
          <label class="form-label" for="end_date">{{ $t('End Date') }}</label>
          <input id="end_date" v-model="form.end_date" class="form-input mt-1 w-full" type="date" />
        </div>
        <div class="flex gap-2 sm:ml-auto">
          <button class="btn-blue w-full sm:w-auto" type="submit">{{ $t('Filter') }}</button>
          <button class="btn-blue w-full sm:w-auto" type="button" @click="reset">{{ $t('Reset') }}</button>
        </div>
      </form>
    </div>

    <!-- Stats -->
    <div class="mb-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
      <div class="rounded bg-white p-4 shadow">
        <div class="text-sm text-gray-600">{{ $t('Total Attempts') }}</div>
        <div class="mt-1 text-2xl font-bold">{{ stats.total_attempts }}</div>
      </div>
      <div class="rounded bg-white p-4 shadow">
        <div class="text-sm text-gray-600">{{ $t('Auto-Passed') }}</div>
        <div class="mt-1 text-2xl font-bold text-green-600">{{ stats.auto_passed }}</div>
        <div class="text-sm text-gray-500">{{ stats.auto_pass_percentage }}%</div>
      </div>
      <div class="rounded bg-white p-4 shadow">
        <div class="text-sm text-gray-600">{{ $t('Manually Passed') }}</div>
        <div class="mt-1 text-2xl font-bold text-green-600">{{ stats.manually_passed }}</div>
      </div>
      <div class="rounded bg-white p-4 shadow">
        <div class="text-sm text-gray-600">{{ $t('Auto-Failed') }}</div>
        <div class="mt-1 text-2xl font-bold text-red-600">{{ stats.auto_failed }}</div>
      </div>
      <div class="rounded bg-white p-4 shadow">
        <div class="text-sm text-gray-600">{{ $t('Manually Failed') }}</div>
        <div class="mt-1 text-2xl font-bold text-red-600">{{ stats.manually_failed }}</div>
      </div>
    </div>

    <!-- Attempts Table -->
    <div class="rounded bg-white shadow">
      <div class="overflow-x-auto">
        <table class="w-full min-w-[1000px]">
          <thead>
            <tr>
              <th class="px-6 pt-6 pb-4 text-left">{{ $t('User') }}</th>
              <th class="px-6 pt-6 pb-4 text-left">{{ $t('Course') }}</th>
              <th class="px-6 pt-6 pb-4 text-left">{{ $t('Quiz') }}</th>
              <th class="px-6 pt-6 pb-4 text-left">{{ $t('Date') }}</th>
              <th class="px-6 pt-6 pb-4 text-left">{{ $t('Status') }}</th>
              <th class="px-6 pt-6 pb-4 text-left">{{ $t('Review Date') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="attempt in attempts.data"
              :key="attempt.id"
              class="hover:bg-gray-50 cursor-pointer"
              @click="goToEnrolment(attempt)"
            >
              <td class="border-t px-6 py-4">
                <template v-if="attempt.user">
                  {{ attempt.user.name }}
                  <br />
                  <span class="text-sm text-gray-500">{{ attempt.user.email }}</span>
                </template>
                <template v-else>
                  <span class="text-gray-500">{{ $t('User not found') }}</span>
                </template>
              </td>
              <td class="border-t px-6 py-4">
                {{ attempt.course?.name || '-' }}
              </td>
              <td class="border-t px-6 py-4">{{ attempt.quiz.name }}</td>
              <td class="border-t px-6 py-4">{{ formatDate(attempt.created_at) }}</td>
              <td class="border-t px-6 py-4">
                <span
                  :class="{
                    'text-green-600': attempt.passed_proctoring,
                    'text-red-600': !attempt.passed_proctoring,
                  }"
                >
                  {{ getProctoringStatus(attempt) }}
                </span>
              </td>
              <td class="border-t px-6 py-4">
                {{ attempt.proctoring_reviewed_at ? formatDate(attempt.proctoring_reviewed_at) : '-' }}
              </td>
            </tr>
            <tr v-if="attempts.data.length === 0">
              <td class="border-t px-6 py-4 text-center text-gray-500" colspan="6">
                {{ $t('No attempts found') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="border-t bg-gray-50">
        <div class="flex items-center justify-between px-4 py-3 sm:px-6">
          <!-- Desktop pagination -->
          <div class="hidden sm:block">
            <pagination :links="attempts.links" />
          </div>

          <!-- Mobile pagination -->
          <div class="flex w-full items-center justify-between sm:hidden">
            <div class="text-sm text-gray-600">{{ $t('Page') }} {{ attempts.current_page }}</div>
            <div class="flex gap-2">
              <button
                class="btn-blue px-3 py-1 text-sm disabled:opacity-50"
                :disabled="!hasPrevPage"
                @click="$inertia.visit(prevPageUrl)"
              >
                {{ $t('Previous') }}
              </button>
              <button
                class="btn-blue px-3 py-1 text-sm disabled:opacity-50"
                :disabled="!hasNextPage"
                @click="$inertia.visit(nextPageUrl)"
              >
                {{ $t('Next') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import { Link as InertiaLink } from '@inertiajs/vue3'

export default {
  components: {
    Layout,
    Pagination,
    InertiaLink,
  },

  props: {
    filters: Object,
    stats: Object,
    attempts: Object,
    courses: Array,
    totalStudentsInTraining: Number,
  },

  data() {
    return {
      form: {
        course_id: this.filters.course_id || '',
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
      },
    }
  },

  computed: {
    hasPrevPage() {
      return this.attempts.current_page > 1
    },
    hasNextPage() {
      return this.attempts.current_page < this.attempts.last_page
    },
    prevPageUrl() {
      const currentPage = this.attempts.current_page
      if (currentPage <= 1) return null
      return this.route('reports.proctoring', {
        ...this.form,
        page: currentPage - 1,
      })
    },
    nextPageUrl() {
      const currentPage = this.attempts.current_page
      if (currentPage >= this.attempts.last_page) return null
      return this.route('reports.proctoring', {
        ...this.form,
        page: currentPage + 1,
      })
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return ''
      return new Date(date).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },

    goToEnrolment(attempt) {
      if (attempt.user) {
        this.$inertia.visit(`/admin/enrolments/${attempt.enrolmentContent.enrolment.id}/edit`)
      }
    },

    submit() {
      this.$inertia.get(this.route('reports.proctoring'), this.form)
    },

    reset() {
      this.form = {
        course_id: '',
        start_date: null,
        end_date: null,
      }
      this.submit()
    },

    getProctoringStatus(attempt) {
      if (attempt.auto_passed) return this.$t('Auto-Passed')
      if (attempt.passed_proctoring) return this.$t('Manually Passed')
      return this.$t('Failed')
    },
  },
}
</script>
