<template>
  <layout :title="$t('Users')">
    <div class="flex flex-row justify-between mb-4">
      <div class="sm:no-flex flex w-full items-center justify-between">
        <h1 class="text-xl font-bold">{{ $t('Users') }}</h1>
      </div>
      <dropdown class="btn-blue" placement="bottom-end">
        <div class="flex cursor-pointer select-none items-center">
          <div>
            <span>{{ $t('Actions') }}</span>
          </div>
          <icon class="-my-1 h-5 w-5 fill-white" name="chevron-down" />
        </div>
        <template #dropdown>
          <div class="mt-2 rounded border bg-white py-2 text-sm shadow-lg">
            <InertiaLink class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700" :href="route('users.create')">
              {{ $t('Create new user') }}
            </InertiaLink>
            <InertiaLink
              class="block px-4 py-2 hover:bg-gray-200 hover:text-gray-700"
              :href="route('worksite-users.index')"
            >
              {{ $t('Manage backoffice users') }}
            </InertiaLink>
          </div>
        </template>
      </dropdown>
    </div>

    <!-- Custom search implementation -->
    <div class="mb-6">
      <form @submit.prevent="submitSearch">
        <!-- Search bar -->
        <div class="w-full mb-4">
          <div class="flex flex-col xl:flex-row">
            <div class="flex w-full rounded bg-white shadow mb-2 xl:mb-0">
              <!-- Filter toggle button for mobile -->
              <button
                class="flex items-center rounded-l border-r px-4 hover:bg-gray-100 focus:z-10 focus:border-white focus:ring h-[50px]"
                type="button"
                @click="showFilters = !showFilters"
              >
                <span class="text-gray-800">{{ $t('Filter') }}</span>
                <svg class="h-2 w-2 fill-gray-700 ml-2" viewBox="0 0 961.243 599.998">
                  <path
                    d="M239.998 239.999L0 0h961.243L721.246 240c-131.999 132-240.28 240-240.624 239.999-.345-.001-108.625-108.001-240.624-240z"
                  />
                </svg>
              </button>

              <input
                v-model="form.search"
                autocomplete="off"
                class="relative w-full px-6 py-3 leading-normal focus:z-10 focus:ring border-r-0 rounded-r h-[50px] xl:min-w-[300px]"
                name="search"
                :placeholder="$t('Search…')"
                type="text"
              />
            </div>

            <div class="flex flex-col xl:flex-row">
              <!-- Search field dropdown moved to separate row -->
              <select
                v-model="form.searchBy"
                class="form-select w-full rounded border py-3 my-2 focus:ring h-[50px] xl:w-auto xl:my-0 xl:mx-2 xl:max-w-[200px]"
              >
                <option value="name">{{ $t('by Name') }}</option>
                <option value="email">{{ $t('by Email') }}</option>
                <option value="phone">{{ $t('by Phone') }}</option>
                <option value="username">{{ $t('by Username') }}</option>
                <option value="certificate_number">{{ $t('by Certificate Number') }}</option>
              </select>

              <!-- Search type dropdown for all viewports -->
              <select
                v-model="form.searchType"
                class="form-select w-full rounded border py-3 my-2 focus:ring h-[50px] xl:w-auto xl:my-0 xl:max-w-[150px]"
              >
                <option value="startsWith">{{ $t('Starts With') }}</option>
                <option value="contains">{{ $t('Contains') }}</option>
              </select>

              <!-- Submit and reset buttons -->
              <div class="flex items-center mt-2 xl:mt-0 xl:ml-2 w-full xl:w-auto xl:justify-end">
                <button
                  class="btn-blue w-full max-w-[150px] ml-auto px-4 flex items-center justify-center h-[50px]"
                  type="submit"
                >
                  <span class="mr-1">{{ $t('Search') }}</span>
                  <icon class="h-5 w-5 text-white" name="search" />
                </button>
                <button
                  class="ml-3 text-sm text-gray-600 hover:text-gray-700 h-[50px] px-3"
                  type="button"
                  @click="reset"
                >
                  {{ $t('Reset') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Filter panel (toggleable) -->
        <div v-if="showFilters" class="bg-white p-4 shadow rounded mb-4 transition-all duration-200">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <label class="block text-gray-800">{{ $t('Role:') }}</label>
              <select v-model="form.role" class="form-select mt-1 w-full">
                <option :value="null" />
                <option value="none">{{ $t('None') }}</option>
                <option value="student">{{ $t('Student') }}</option>
                <option value="instructor">{{ $t('Instructor') }}</option>
                <option value="master_manager">{{ $t('Master Manager') }}</option>
                <option value="account_owner">{{ $t('Account Owner') }}</option>
                <option value="support">{{ $t('Support') }}</option>
                <option value="admin">{{ $t('Admin') }}</option>
                <option value="super">{{ $t('Super') }}</option>
              </select>
            </div>

            <div>
              <label class="block text-gray-800">{{ $t('Owner:') }}</label>
              <select v-model="form.owner" class="form-select mt-1 w-full">
                <option :value="null" />
                <option value="yes">{{ $t('Yes') }}</option>
                <option value="no">{{ $t('No') }}</option>
              </select>
            </div>

            <div>
              <label class="block text-gray-800">{{ $t('Archived:') }}</label>
              <select v-model="form.archived" class="form-select mt-1 w-full">
                <option :value="null" />
                <option value="with">{{ $t('With Archived') }}</option>
                <option value="only">{{ $t('Only Archived') }}</option>
              </select>
            </div>

            <div>
              <label class="block text-gray-800">{{ $t('Deleted:') }}</label>
              <select v-model="form.deleted" class="form-select mt-1 w-full">
                <option :value="null" />
                <option value="with">{{ $t('With Deleted') }}</option>
                <option value="only">{{ $t('Only Deleted') }}</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="overflow-x-auto rounded bg-white shadow">
      <SimpleTable>
        <template #head>
          <TableHead>{{ $t('Name') }}</TableHead>
          <TableHead>{{ $t('Email') }}</TableHead>
          <TableHead>{{ $t('Username') }}</TableHead>
          <TableHead>{{ $t('Organization') }}</TableHead>
          <TableHead>{{ $t('Role') }}</TableHead>
          <TableHead />
        </template>
        <TableRow v-if="users.data.length === 0">
          <TableData colspan="5">{{ $t('No users found.') }}</TableData>
        </TableRow>
        <TableRow
          v-for="user in users.data"
          :key="user.id"
          :clickable="true"
          @click="$inertia.visit(route('users.edit', user.id))"
        >
          <TableData>
            <InertiaLink class="flex items-center" :href="route('users.edit', user.id)">
              {{ user.first_name }} {{ user.last_name }}
              <span
                v-if="user.organization_role !== 'basic'"
                class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white"
              >
                {{ user.organization_role_label }}
              </span>
              <span v-if="user.is_owner" class="ml-2 rounded bg-gray-400 px-2 py-1 text-sm text-white">
                {{ $t('Owner') }}
              </span>
              <archived-badge v-if="user.archived_at" />
              <deleted-badge v-if="user.deleted_at" />
            </InertiaLink>
          </TableData>
          <TableData>{{ trimText(user.email) }}</TableData>
          <TableData>{{ trimText(user.username, 25) }}</TableData>
          <TableData>{{ user.organization }}</TableData>
          <TableData>
            <div v-if="user.role">
              {{ user.role }}
            </div>
            <div v-else class="text-gray-600">{{ $t('None') }}</div>
          </TableData>
          <TableData class="w-min align-middle">
            <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
          </TableData>
        </TableRow>
      </SimpleTable>
    </div>
    <pagination :links="users.links" />
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import Dropdown from '@/Shared/Dropdown.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import SimpleTable from '@/Shared/SimpleTable.vue'
import TableData from '@/Shared/TableData.vue'
import TableHead from '@/Shared/TableHead.vue'
import TableRow from '@/Shared/TableRow.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'

export default {
  components: {
    SimpleTable,
    TableData,
    TableRow,
    ArchivedBadge,
    DeletedBadge,
    Dropdown,
    Icon,
    Layout,
    Pagination,
    TableHead,
  },
  props: {
    users: Object,
    filters: Object,
  },
  data() {
    return {
      showFilters: false,
      form: {
        search: this.filters.search,
        searchBy: this.filters.searchBy || 'name',
        searchType: this.filters.searchType || 'startsWith',
        role: this.filters.role,
        owner: this.filters.owner,
        archived: this.filters.archived,
        deleted: this.filters.deleted,
      },
    }
  },
  methods: {
    submitSearch() {
      let query = _pickBy(this.form)
      this.$inertia.get(this.route('users'), Object.keys(query).length ? query : { remember: 'forget' }, {
        preserveState: true,
        replace: true,
        only: ['users'],
      })
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
      this.submitSearch()
    },
    trimText(text, length = 35) {
      if (!text) return text
      if (text.length < length) return text
      return text.substr(0, length) + '...'
    },
  },
}
</script>
