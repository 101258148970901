<template>
  <div v-if="timestamp" class="flex justify-start">
    <div class="w-20">{{ name }}</div>
    <ShowDate
      v-if="timestamp"
      :format="`MMM DD, YYYY hh:mm A ([${Intl.DateTimeFormat().resolvedOptions().timeZone}])`"
      :timestamp="timestamp"
    />
  </div>
</template>

<script>
import ShowDate from '../ShowDate.vue'

export default {
  components: {
    ShowDate,
  },
  props: {
    name: String,
    timestamp: String,
    text: String,
  },
}
</script>
