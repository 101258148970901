<template>
  <front-layout :title="$t('Edit Manual')">
    <div class="item-center mb-8 flex max-w-2xl justify-between">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="form.name ?? $t('Edit')"
        :previous-name="$t('Manuals')"
        :previous-url="route('front.admin.manuals')"
      />
      <front-loading-button :loading="form.processing" @click="submit">
        {{ $t('Save') }}
      </front-loading-button>
    </div>
    <form @submit.prevent="submit">
      <div class="max-w-2xl overflow-hidden rounded bg-white shadow">
        <div class="flex flex-col p-8">
          <div class="w-full pb-8">
            <front-text-input v-model="form.name" :error="form.errors.name" :label="$t('Manual Name')" />
          </div>
          <front-dynamic-access-control
            v-model="form.permissions"
            :organization="organization"
            @change="onPermissionsChange"
            @permissions-changed="onPermissionsDirectChange"
            @update:model-value="updatePermissions"
          />
        </div>
      </div>
      <!-- Sections -->
      <div class="pb-6 pt-8 font-heading text-xl">Sections</div>
      <Draggable v-model="form.sections">
        <div
          v-for="(section, index) in form.sections"
          :key="index"
          class="flex max-w-2xl flex-col overflow-hidden bg-white p-6 shadow"
        >
          <div class="flex items-center">
            <div class="">
              <icon class="h-8 w-8 text-gray-400" name="menu" />
            </div>
            <div class="w-full pl-4 text-lg">
              {{ section.name }}
            </div>
            <div v-if="section.id" class="flex items-center justify-end">
              <front-outline-button-link
                class="mr-6 flex"
                :href="route('front.admin.manual-sections.edit', { manual: manual, manualSection: section })"
              >
                Edit
              </front-outline-button-link>
              <button class="flex justify-end text-gray-500 hover:text-gray-600" @click="removeSection(index)">
                Remove
              </button>
            </div>
          </div>
        </div>
      </Draggable>
    </form>
    <div class="mt-8 flex max-w-2xl justify-end">
      <front-outline-button class="flex" @click="addSection">
        <icon class="fill-qualify-red mr-2 h-4 w-4 flex-shrink-0" name="plus" />
        Add Section
      </front-outline-button>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontDynamicAccessControl from '@/Shared/FrontDynamicAccessControl.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: {
    Draggable,
    Breadcrumb,
    FrontDynamicAccessControl,
    FrontLayout,
    FrontLoadingButton,
    FrontOutlineButton,
    FrontOutlineButtonLink,
    FrontTextInput,
    Icon,
  },
  props: {
    manual: Object,
    manual_sections: Array,
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        name: this.manual.name,
        sections: this.manual_sections,
        permissions: this.manual.permissions ?? {
          users: [],
          user_roles: [],
          locations: [],
          groups: [],
          everyone_access: false,
        },
      }),
    }
  },
  watch: {
    'form.permissions': {
      handler(newVal) {
        console.log('Permissions changed via watcher', newVal)
        this.form.isDirty = true
      },
      deep: true,
    },
  },
  methods: {
    addSection() {
      this.$inertia.post(
        this.route('front.admin.manual-sections.create', this.manual),
        {
          name: `New Section ${this.form.sections.length + 1}`,
        },
        {
          preserveState: false,
        }
      )
    },
    removeSection(index) {
      this.form.sections.splice(index, 1)
    },
    onPermissionsChange() {
      console.log('Permissions changed via event')
      this.form.isDirty = true
    },
    onPermissionsDirectChange() {
      console.log('Permissions changed directly')
      this.form.isDirty = true
    },
    updatePermissions(value) {
      console.log('Permissions updated with new value:', value)
      // Ensure we're working with a deep copy to avoid reference issues
      this.form.permissions = JSON.parse(JSON.stringify(value))
      console.log('Form permissions after update:', this.form.permissions)
      this.form.isDirty = true
    },
    submit() {
      // Log what we're about to submit
      console.log('Current permissions state:', this.form.permissions)

      // Store the original objects with names for later use
      const originalUsers = [...this.form.permissions.users]
      const originalUserRoles = [...this.form.permissions.user_roles]
      const originalLocations = [...this.form.permissions.locations]
      const originalGroups = [...this.form.permissions.groups]

      // Ensure permissions are properly formatted before submission
      const formattedPermissions = {
        everyone_access: this.form.permissions.everyone_access === true,
        users: Array.isArray(this.form.permissions.users)
          ? this.form.permissions.users.map((u) => (typeof u === 'object' ? u.id : u))
          : [],
        user_roles: Array.isArray(this.form.permissions.user_roles)
          ? this.form.permissions.user_roles.map((r) => (typeof r === 'object' ? r.id : r))
          : [],
        locations: Array.isArray(this.form.permissions.locations)
          ? this.form.permissions.locations.map((l) => (typeof l === 'object' ? l.id : l))
          : [],
        groups: Array.isArray(this.form.permissions.groups)
          ? this.form.permissions.groups.map((g) => (typeof g === 'object' ? g.id : g))
          : [],
      }

      console.log('Formatted permissions for submission:', formattedPermissions)
      console.log('Everyone access value:', formattedPermissions.everyone_access)
      console.log('Users array:', formattedPermissions.users)
      console.log('User roles array:', formattedPermissions.user_roles)

      // Update the form permissions with the formatted data
      this.form.permissions = formattedPermissions

      // Process the data directly in the form
      this.form.put(this.route('front.admin.manuals.update', this.manual), {
        onSuccess: () => {
          console.log('Form submitted successfully')
          // Restore the original objects with names after successful submission
          if (!this.form.permissions.everyone_access) {
            // Only restore if not everyone access
            this.form.permissions.users = originalUsers
            this.form.permissions.user_roles = originalUserRoles
            this.form.permissions.locations = originalLocations
            this.form.permissions.groups = originalGroups
            console.log('Restored original permissions objects with names:', this.form.permissions)
          }
        },
        onError: (errors) => {
          console.error('Form submission errors:', errors)
        },
        preserveScroll: true,
      })
    },
  },
}
</script>
