<template>
  <div class="ml-2 mt-2 text-xs text-gray-600">
    <DeletedMessage v-if="model.deleted_at" />
    <ArchivedMessage v-if="model.archived_at" />
    <CancelledMessage v-if="model.cancelled_at" />
    <OldVersionMessage v-if="model.valid_to" />
  </div>
</template>

<script>
import ArchivedMessage from '../ArchivedMessage.vue'
import CancelledMessage from '../CancelledMessage.vue'
import DeletedMessage from '../DeletedMessage.vue'
import OldVersionMessage from './Messages/OldVersionMessage.vue'

export default {
  components: {
    DeletedMessage,
    ArchivedMessage,
    CancelledMessage,
    OldVersionMessage,
  },
  props: {
    model: Object,
  },
}
</script>
