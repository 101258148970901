<template>
  <div ref="container" class="flex items-center space-x-2">
    <input
      v-for="n in length"
      :key="n"
      v-model="otpArray[n - 1]"
      class="border rounded-md w-10 p-2 text-center uppercase"
      :class="error ? 'border-ts-front-border-error' : 'focus:border-ts-front-border-dark'"
      maxlength="1"
      type="text"
      @keyup="(e) => onKeyUp(n - 1, e)"
    />
  </div>
  <div v-if="error" class="form-error text-ts-front-label-error">{{ error }}</div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    length: {
      type: Number,
      default: 6,
    },
    error: String,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      otpArray: [],
    }
  },
  mounted() {
    for (let i = 0; i < this.length; i++) {
      this.otpArray[i] = null
    }
    this.$refs.container.children[0].focus()
  },
  watch: {
    otpArray: {
      handler: function (newVal) {
        let otpCode = newVal.join('')
        if (otpCode.length == this.length) {
          this.$emit('update:modelValue', otpCode)
        } else {
          this.$emit('update:modelValue', null)
        }
      },
      deep: true,
    },
  },
  methods: {
    onKeyUp(i, e) {
      const children = this.$refs.container.children
      const keyPressed = e.key

      if (i > 0 && (keyPressed === 'Backspace' || keyPressed === 'Delete')) {
        children[i - 1].focus()
        this.otpArray[i] = null
      } else {
        const matched = keyPressed.match(/^[a-zA-Z]$/)
        if (!matched) {
          this.otpArray[i] = null
          return
        } else if (i < this.length - 1) {
          children[i + 1].focus()
        }
      }
    },
  },
}
</script>
