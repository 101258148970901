<template>
  <div class="flex min-h-screen justify-center bg-white">
    <DynamicHead :title="$t('Reset Password | :app_name', { app_name: $page.props.app.name })" />
    <div class="px-4 pt-20 lg:w-1/2 lg:pl-20 lg:pr-8">
      <div class="ml-auto max-w-md">
        <worksite-dark class="mx-auto sm:mx-0" />
        <h2 class="mb-8 mt-20 text-2xl leading-9 text-gray-900">{{ $t('Sign In') }}</h2>
        <form @submit.prevent="submit">
          <div
            v-if="$page.props.errors['token'] && showFlash"
            class="mb-4 flex items-center justify-between rounded bg-red-400"
          >
            <div class="flex items-center">
              <icon class="ml-4 mr-2 h-4 w-4 flex-shrink-0 fill-white" name="close-outline" />
              <div class="py-4 text-sm font-medium text-white">
                <span>{{ $page.props.errors['token'] }}</span>
              </div>
            </div>
            <button class="group mr-2 p-2" type="button" @click="showFlash = false">
              <icon class="block h-3 w-3 fill-red-300 group-hover:fill-red-100" name="close" />
            </button>
          </div>
          <div class="-mx-3 flex max-w-xs flex-wrap">
            <div class="w-full p-3">
              <label class="mb-2 block text-lg text-darkGray-700" for="email">{{ $t('Email / Username') }}</label>
              <input
                id="email"
                v-model="form.email"
                autocapitalize="off"
                autocomplete="email"
                autocorrect="off"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                required
                type="text"
              />
              <div v-if="form.errors.email" class="form-error">{{ form.errors.email }}</div>
            </div>

            <div class="w-full p-3">
              <label class="mb-2 block text-lg text-darkGray-700" for="password">{{ $t('Password') }}</label>
              <input
                v-model="form.password"
                autocomplete="new-password"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                required
                type="password"
              />
              <div v-if="form.errors.password" class="form-error">{{ form.errors.password }}</div>
            </div>
            <div class="w-full p-3">
              <label class="mb-2 block text-lg text-darkGray-700" for="password">{{ $t('Confirm password') }}</label>
              <input
                v-model="form.password_confirmation"
                autocomplete="new-password"
                class="block w-full rounded-lg border border-gray-200 bg-gray-100 px-3 py-2 transition duration-150 ease-in-out"
                :error="form.errors.password_confirmation"
                :label="$t('Confirm password')"
                required
                type="password"
              />
              <div v-if="form.errors.password_confirmation" class="form-error">
                {{ form.errors.password_confirmation }}
              </div>
            </div>
          </div>
          <front-loading-button class="mt-6" :loading="form.processing" type="submit">
            {{ $t('Reset Password') }}
          </front-loading-button>
        </form>
        <div class="py-10">
          <span class="text-darkGray-700 opacity-65">{{ $t('Powered By') }}</span>
          <qualify-dark class="ml-2 inline opacity-25" />
        </div>
      </div>
    </div>
    <div
      class="hidden items-center justify-center bg-cover lg:flex lg:w-1/2"
      style="background-image: url('/images/Qualify_LOGIN_BG.svg')"
    >
      <img alt="$t('Login')" class="w-3/4" src="/images/login.svg" />
    </div>
  </div>
</template>

<script>
import DynamicHead from '@/Shared/DynamicHead.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import Icon from '@/Shared/Icon.vue'
import QualifyDark from '@/Shared/Logo/QualifyDark.vue'
import WorksiteDark from '@/Shared/Logo/WorksiteDark.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    DynamicHead,
    FrontLoadingButton,
    Icon,
    QualifyDark,
    WorksiteDark,
  },
  props: {
    token: String,
    email: String,
  },
  data() {
    return {
      form: useForm(
        {
          token: this.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        },
        'ResetPassword'
      ),
    }
  },
  methods: {
    submit() {
      this.form.post(this.route('password.update'))
    },
  },
}
</script>

<style scoped>
.hover-animate:hover > svg {
  --transform-translate-x: 0.5rem;
}
</style>
