<template>
  <div class="ml-2 mt-2 text-xs text-gray-600">
    <div class="space-y-1">
      <ModelTimestampRow name="Created on" :timestamp="model.created_at" />
      <ModelTimestampRow name="Update on" :timestamp="model.updated_at" />
      <ModelTimestampRow name="Deleted on" :timestamp="model.deleted_at" />
      <ModelTimestampRow name="Archived on" :timestamp="model.archived_at" />
      <ModelTimestampRow name="Reset on" :timestamp="model.reset_at" />
    </div>
    <div v-if="model.valid_from" class="mt-2">
      <div class="font-bold">Valid:</div>
      <div class="space-y-1">
        <ModelTimestampRow name="From" :timestamp="model.valid_from" />
        <ModelTimestampRow name="To" :timestamp="model.valid_to" />
      </div>
    </div>
  </div>
</template>

<script>
import ModelTimestampRow from './Model/ModelTimestampRow.vue'

export default {
  components: {
    ModelTimestampRow,
  },
  props: {
    model: Object,
  },
}
</script>
