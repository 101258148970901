<template>
  <front-layout :title="$t('Form Templates')">
    <div class="flex flex-col xl:flex-row xl:justify-between">
      <div class="sm:no-flex mb-8 flex w-full items-center justify-between">
        <breadcrumb :name="$t('Form Templates')" />
        <front-solid-button v-if="globalFormTemplatesCount > 0" class="sm:hidden" @click="showNewFormModal">
          <span>{{ $t('+ Add') }}</span>
        </front-solid-button>
        <front-solid-button v-else class="sm:hidden" @click="createBlank">
          <span>{{ $t('+ Add') }}</span>
        </front-solid-button>
      </div>
      <div class="mb-6 flex w-full items-center justify-end">
        <front-search-filter
          v-model="form.search"
          class="flex w-full flex-col sm:mr-4 sm:flex-row lg:max-w-sm"
          :filter-show="false"
          @reset="reset"
        />
        <front-solid-button v-if="globalFormTemplatesCount > 0" class="hidden sm:inline" @click="showNewFormModal">
          <span>{{ $t('+ Add') }}</span>
        </front-solid-button>
        <front-solid-button v-else class="hidden sm:inline" @click="createBlank">
          <span>{{ $t('+ Add') }}</span>
        </front-solid-button>
      </div>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow-lg">
      <table class="front-table">
        <thead class="bg-ts-front-table-header">
          <tr>
            <th>{{ $t('Template') }}</th>
            <th>{{ $t('Last Published') }}</th>
            <th />
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-if="!formTemplates.data.length">
            <td class="border-t px-6 py-4" colspan="6">{{ $t('No templates found.') }}</td>
          </tr>
          <tr
            v-for="template in formTemplates.data"
            :key="template.id"
            @click="$inertia.visit(route('front.admin.form-templates.edit', template.series_id))"
          >
            <td class="flex items-center font-semibold text-ts-gray-text-bold">
              {{ template.title }}
              <span
                v-if="template.archived_at"
                class="mx-2 rounded-md bg-[#467082]/10 px-2 py-1 text-center text-xs font-semibold uppercase text-[#467082]"
              >
                {{ $t('Archived') }}
              </span>
            </td>
            <td>
              <span class="block">{{ template.last_published_at_readable }}</span>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm font-medium leading-5 text-gray-900 hover:underline">
              <InertiaLink
                class="font-semibold hover:underline"
                :href="route('front.admin.form-templates.edit', template.series_id)"
              >
                {{ $t('Edit') }}
              </InertiaLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <front-pagination :links="formTemplates.links" />

    <!-- New Form Modal -->
    <NewFormModal
      :show="isShowNewFormModal"
      @close="hideNewFormModal"
      @open-template-library="showTemplateLibraryModal"
    />

    <!-- Template Library Modal -->
    <TemplateLibraryModal :show="isShowTemplateLibraryModal" @close="hideTemplateLibraryModal" />
  </front-layout>
</template>

<script>
import NewFormModal from './Modals/NewFormModal.vue'
import TemplateLibraryModal from './Modals/TemplateLibraryModal.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontPagination from '@/Shared/FrontPagination.vue'
import FrontSearchFilter from '@/Shared/FrontSearchFilter.vue'
import FrontSolidButton from '@/Shared/FrontSolidButton.vue'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    NewFormModal,
    TemplateLibraryModal,
    Breadcrumb,
    FrontLayout,
    FrontPagination,
    FrontSearchFilter,
    FrontSolidButton,
  },
  props: {
    filters: Object,
    formTemplates: Object,
    globalFormTemplatesCount: Number,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
      isShowNewFormModal: false,
      isShowTemplateLibraryModal: false,
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        let query = _pickBy(this.form)
        this.$inertia.get(
          this.route('front.admin.form-templates.index'),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    showNewFormModal() {
      this.isShowNewFormModal = true
    },
    hideNewFormModal() {
      this.isShowNewFormModal = false
    },
    showTemplateLibraryModal() {
      this.isShowTemplateLibraryModal = true
    },
    hideTemplateLibraryModal() {
      this.isShowTemplateLibraryModal = false
    },
    createBlank() {
      this.$inertia.post(this.route('front.admin.form-templates.store'))
    },
    reset() {
      this.form = _mapValues(this.form, () => null)
    },
  },
}
</script>
