<template>
  <div class="space-y-6">
    <!-- Everyone Access Toggle -->
    <div
      class="flex flex-col space-y-2 rounded-lg bg-gray-50 p-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
    >
      <label class="text-md font-medium text-gray-700">{{ $t('Access') }}</label>
      <div class="sm:ml-4 sm:w-1/2">
        <front-checkbox-input
          :label="$t('Allow everyone to access')"
          :model-value="permissions.everyone_access"
          @update:model-value="handleEveryoneAccess"
        />
      </div>
    </div>

    <!-- Granular Access Controls -->
    <template v-if="!permissions.everyone_access">
      <div class="space-y-4">
        <!-- Users -->
        <div class="flex flex-col space-y-2 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
          <label class="text-md font-medium text-gray-700">{{ $t('User(s)') }}</label>
          <div class="sm:ml-4 sm:w-1/2">
            <vue-multiselect
              v-model="permissions.users"
              class="w-full"
              :class-name="'qualify-red-theme'"
              :clear-on-select="false"
              :close-on-select="false"
              :internal-search="false"
              label="name"
              :loading="usersLoading"
              :multiple="true"
              :options="userOptions"
              placeholder="Search for users..."
              :preselect-first="false"
              :preserve-search="true"
              track-by="id"
              @search-change="searchUsers"
              @update:model-value="handleUsersUpdate"
            >
              <template #noResult>{{ $t('No user(s) found.') }}</template>
              <template #noOptions>{{ $t('Type to search for users...') }}</template>
            </vue-multiselect>
          </div>
        </div>

        <!-- User Roles -->
        <div class="flex flex-col space-y-2 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
          <label class="text-md font-medium text-gray-700">{{ $t('User Role(s)') }}</label>
          <div class="sm:ml-4 sm:w-1/2">
            <vue-multiselect
              v-model="permissions.user_roles"
              class="w-full"
              :class-name="'qualify-red-theme'"
              :clear-on-select="false"
              :close-on-select="false"
              label="name"
              :multiple="true"
              :options="roleOptions"
              placeholder="Select User Role(s)"
              :preselect-first="false"
              :preserve-search="true"
              track-by="id"
              @update:model-value="handleUserRolesUpdate"
            >
              <template #noResult>{{ $t('No user role(s) found.') }}</template>
              <template #noOptions>{{ $t('No user role(s) found.') }}</template>
            </vue-multiselect>
          </div>
        </div>

        <!-- Locations -->
        <div class="flex flex-col space-y-2 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
          <label class="text-md font-medium text-gray-700">{{ $t('Location(s)') }}</label>
          <div class="sm:ml-4 sm:w-1/2">
            <vue-multiselect
              v-model="permissions.locations"
              class="w-full"
              :class-name="'qualify-red-theme'"
              :clear-on-select="false"
              :close-on-select="false"
              :internal-search="false"
              label="name"
              :loading="locationsLoading"
              :multiple="true"
              :options="locationOptions"
              placeholder="Search for locations..."
              :preselect-first="false"
              :preserve-search="true"
              track-by="id"
              @search-change="searchLocations"
              @update:model-value="handleLocationsUpdate"
            >
              <template #noResult>{{ $t('No location(s) found.') }}</template>
              <template #noOptions>{{ $t('Type to search for locations...') }}</template>
            </vue-multiselect>
          </div>
        </div>

        <!-- Groups -->
        <div class="flex flex-col space-y-2 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
          <label class="text-md font-medium text-gray-700">{{ $t('Group(s)') }}</label>
          <div class="sm:ml-4 sm:w-1/2">
            <vue-multiselect
              v-model="permissions.groups"
              class="w-full"
              :class-name="'qualify-red-theme'"
              :clear-on-select="false"
              :close-on-select="false"
              :internal-search="false"
              label="name"
              :loading="groupsLoading"
              :multiple="true"
              :options="groupOptions"
              placeholder="Search for groups..."
              :preselect-first="false"
              :preserve-search="true"
              track-by="id"
              @search-change="searchGroups"
              @update:model-value="handleGroupsUpdate"
            >
              <template #noResult>{{ $t('No group(s) found.') }}</template>
              <template #noOptions>{{ $t('Type to search for groups...') }}</template>
            </vue-multiselect>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'
import axios from 'axios'
import { debounce } from 'lodash'
import VueMultiselect from 'vue-multiselect'

export default {
  components: {
    FrontCheckboxInput,
    VueMultiselect,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({
        users: [],
        user_roles: [],
        locations: [],
        groups: [],
        everyone_access: false,
      }),
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue', 'change', 'permissions-changed'],
  data() {
    return {
      permissions: {
        users: this.modelValue?.users ?? [],
        user_roles: this.modelValue?.user_roles ?? [],
        locations: this.modelValue?.locations ?? [],
        groups: this.modelValue?.groups ?? [],
        everyone_access: this.modelValue?.everyone_access ?? false,
      },
      userOptions: [],
      locationOptions: [],
      groupOptions: [],
      roleOptions: [],
      usersLoading: false,
      locationsLoading: false,
      groupsLoading: false,
      rolesLoading: false,
    }
  },
  watch: {
    modelValue: {
      handler(newVal) {
        // Update local permissions when parent modelValue changes
        this.permissions = {
          users: newVal?.users ?? [],
          user_roles: newVal?.user_roles ?? [],
          locations: newVal?.locations ?? [],
          groups: newVal?.groups ?? [],
          everyone_access: newVal?.everyone_access ?? false,
        }
      },
      deep: true,
    },
  },
  created() {
    // Initialize debounced search functions
    this.debouncedSearchUsers = debounce(this.performUserSearch, 300)
    this.debouncedSearchLocations = debounce(this.performLocationSearch, 300)
    this.debouncedSearchGroups = debounce(this.performGroupSearch, 300)

    // Load roles immediately since they're not dynamic
    this.loadRoles()

    // If we have existing selections, load them
    this.loadInitialSelections()
  },
  mounted() {
    // Force an initial update to ensure parent component has the latest data
    this.$nextTick(() => {
      this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.permissions)))
    })
  },
  methods: {
    async loadInitialSelections() {
      // If we have existing users, make sure they're in the options
      if (this.permissions.users && this.permissions.users.length > 0) {
        this.userOptions = [...this.permissions.users]
      }

      // If we have existing locations, make sure they're in the options
      if (this.permissions.locations && this.permissions.locations.length > 0) {
        this.locationOptions = [...this.permissions.locations]
      }

      // If we have existing groups, make sure they're in the options
      if (this.permissions.groups && this.permissions.groups.length > 0) {
        this.groupOptions = [...this.permissions.groups]
      }
    },

    async loadRoles() {
      this.rolesLoading = true
      try {
        const response = await axios.get(this.route('front.admin.manuals.search.roles'))
        this.roleOptions = response.data
      } catch (error) {
        console.error('Error loading roles:', error)
      } finally {
        this.rolesLoading = false
      }
    },

    searchUsers(query) {
      if (query.length > 0) {
        this.usersLoading = true
        this.debouncedSearchUsers(query)
      }
    },

    async performUserSearch(query) {
      try {
        const response = await axios.get(this.route('front.admin.manuals.search.users'), {
          params: { search: query },
        })

        // Merge existing selections with new options to prevent them from disappearing
        const existingSelections = this.permissions.users || []
        const existingIds = existingSelections.map((user) => user.id)

        // Filter out duplicates
        const newOptions = response.data.data.filter((user) => !existingIds.includes(user.id))

        this.userOptions = [...existingSelections, ...newOptions]
      } catch (error) {
        console.error('Error searching users:', error)
      } finally {
        this.usersLoading = false
      }
    },

    searchLocations(query) {
      if (query.length > 0) {
        this.locationsLoading = true
        this.debouncedSearchLocations(query)
      }
    },

    async performLocationSearch(query) {
      try {
        const response = await axios.get(this.route('front.admin.manuals.search.locations'), {
          params: { search: query },
        })

        // Merge existing selections with new options
        const existingSelections = this.permissions.locations || []
        const existingIds = existingSelections.map((location) => location.id)

        // Filter out duplicates
        const newOptions = response.data.data.filter((location) => !existingIds.includes(location.id))

        this.locationOptions = [...existingSelections, ...newOptions]
      } catch (error) {
        console.error('Error searching locations:', error)
      } finally {
        this.locationsLoading = false
      }
    },

    searchGroups(query) {
      if (query.length > 0) {
        this.groupsLoading = true
        this.debouncedSearchGroups(query)
      }
    },

    async performGroupSearch(query) {
      try {
        const response = await axios.get(this.route('front.admin.manuals.search.groups'), {
          params: { search: query },
        })

        // Merge existing selections with new options
        const existingSelections = this.permissions.groups || []
        const existingIds = existingSelections.map((group) => group.id)

        // Filter out duplicates
        const newOptions = response.data.data.filter((group) => !existingIds.includes(group.id))

        this.groupOptions = [...existingSelections, ...newOptions]
      } catch (error) {
        console.error('Error searching groups:', error)
      } finally {
        this.groupsLoading = false
      }
    },

    handleEveryoneAccess(value) {
      this.permissions.everyone_access = value === true
      if (value) {
        this.permissions.users = []
        this.permissions.user_roles = []
        this.permissions.locations = []
        this.permissions.groups = []
      }
      // Make a deep copy to ensure reactivity
      const permissionsCopy = JSON.parse(JSON.stringify(this.permissions))
      this.$emit('update:modelValue', permissionsCopy)
      this.$emit('change')
      this.$emit('permissions-changed')
    },

    handleUsersUpdate(value) {
      this.permissions.users = value || []
      // Make a deep copy to ensure reactivity
      const permissionsCopy = JSON.parse(JSON.stringify(this.permissions))
      this.$emit('update:modelValue', permissionsCopy)
      this.$emit('change')
      this.$emit('permissions-changed')
    },

    handleUserRolesUpdate(value) {
      this.permissions.user_roles = value || []
      // Make a deep copy to ensure reactivity
      const permissionsCopy = JSON.parse(JSON.stringify(this.permissions))
      this.$emit('update:modelValue', permissionsCopy)
      this.$emit('change')
      this.$emit('permissions-changed')
    },

    handleLocationsUpdate(value) {
      this.permissions.locations = value || []
      // Make a deep copy to ensure reactivity
      const permissionsCopy = JSON.parse(JSON.stringify(this.permissions))
      this.$emit('update:modelValue', permissionsCopy)
      this.$emit('change')
      this.$emit('permissions-changed')
    },

    handleGroupsUpdate(value) {
      this.permissions.groups = value || []
      // Make a deep copy to ensure reactivity
      const permissionsCopy = JSON.parse(JSON.stringify(this.permissions))
      this.$emit('update:modelValue', permissionsCopy)
      this.$emit('change')
      this.$emit('permissions-changed')
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.qualify-red-theme.multiselect .multiselect__tags {
  border-radius: 0.25rem;
  border-color: #d1d5db;
}

.qualify-red-theme.multiselect .multiselect__tag {
  background-color: #ef4444;
  color: white;
}

.qualify-red-theme.multiselect .multiselect__tag-icon:after {
  color: white;
}

.qualify-red-theme.multiselect .multiselect__tag-icon:hover {
  background-color: #dc2626;
}

.qualify-red-theme.multiselect .multiselect__option--highlight {
  background-color: #ef4444;
  color: white;
}

.qualify-red-theme.multiselect .multiselect__option--highlight:after {
  background-color: #ef4444;
  color: white;
}

.qualify-red-theme.multiselect .multiselect__option--selected.multiselect__option--highlight {
  background-color: #dc2626;
  color: white;
}

.qualify-red-theme.multiselect .multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #dc2626;
  color: white;
}
</style>
