<template>
  <layout :title="form.name">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('locations')">
        {{ $t('Locations') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      {{ form.name }}
    </h1>
    <deleted-message v-if="location.deleted_at" class="mb-6">
      {{ $t('This location has been deleted.') }}
    </deleted-message>
    <archived-message v-if="location.archived_at" class="mb-6">
      <div class="flex w-full justify-between">
        <div>{{ $t('This location has been archived.') }}</div>
        <button
          v-if="location.archived_at"
          class="ml-4 text-yellow-800 hover:underline"
          tabindex="-1"
          type="button"
          @click="unArchive"
        >
          {{ $t('Restore') }}
        </button>
      </div>
    </archived-message>
    <div class="flex max-w-3xl flex-wrap">
      <div class="w-full lg:w-3/5 xl:w-2/3">
        <div class="overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.capacity" :error="form.errors.capacity" :label="$t('Capacity')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <label class="form-label">{{ $t('Type:') }}</label>
                <select v-model="form.type" class="form-select mt-1 w-full">
                  <option v-for="(type, index) in types" :key="index" :value="index">
                    {{ type }}
                  </option>
                </select>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <label class="form-label">{{ $t('Enter a location:') }}</label>
                <google-maps-autocomplete v-model="fullAddress" :placeholder="$t('Look for an Address')" />
                <span v-if="form.type === 'webinar'" class="mt-2 block text-sm text-gray-600">
                  {{ $t('This will autocomplete the timezone field.') }}
                </span>
                <span v-if="form.type === 'class'" class="mt-2 block text-sm text-gray-600">
                  {{ $t('This will autocomplete most of the fields below.') }}
                </span>
              </div>
              <div v-if="form.type !== 'webinar'" class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.timezone" disabled :error="form.errors.timezone" :label="$t('Timezone')" />
              </div>
              <div v-else class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input v-model="form.timezone" :error="form.errors.timezone" :label="$t('Timezone')">
                  <option :value="null" />
                  <option v-for="(timezone, index) in timezones" :key="index" :value="timezone">
                    {{ timezone }}
                  </option>
                </select-input>
              </div>
              <div v-if="form.type === 'class'" class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
              </div>
              <div v-if="form.type === 'class'" class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
              </div>
              <div v-if="form.type === 'class'" class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.region" :error="form.errors.region" :label="$t('Province/State')" />
              </div>
              <div v-if="form.type === 'class'" class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
              </div>
              <div v-if="form.type === 'class'" class="w-full pb-8 pr-6 lg:w-1/2">
                <radio-input
                  v-model="clientSite"
                  :error="form.errors.client_site"
                  :label="$t('Client site')"
                  type="radio"
                />
              </div>
              <div v-if="clientSite" class="w-full pb-8 pr-6 lg:w-1/2">
                <dynamic-search-input
                  v-model="selectedOrganization"
                  :error="form.errors.organization_id"
                  :label="$t('Organization')"
                  :search-by="['name']"
                  track-by="id"
                  :url="route('organizations.search', { access_codes_enabled: true })"
                >
                  <div v-if="selectedOrganization" class="truncate">{{ selectedOrganization.name }}</div>
                  <template #option="{ option }">
                    {{ option.name }}
                  </template>
                </dynamic-search-input>
                <span class="mt-2 block text-sm text-gray-600">
                  {{ $t('Location availability can be limited to an organization.') }}
                </span>
              </div>
              <div v-if="form.type === 'class' && !clientSite" class="w-full pb-8 pr-6 lg:w-1/2">
                <radio-input
                  v-model="isOwned"
                  :error="form.errors.is_owned"
                  :label="$t('Property Owned/Leased (Not a hotel)')"
                  type="radio"
                />
              </div>
              <div v-if="form.type === 'class'" class="flex w-full items-end pb-8 pr-6 lg:w-1/2">
                <a
                  v-if="can.getLocationsFromMyBusiness && !myBusinessLocations.length"
                  class="text-gray-700 hover:underline"
                  :href="route('login.google')"
                >
                  {{ $t('Show My Business Locations') }}
                </a>
                <select-input
                  v-if="can.getLocationsFromMyBusiness && myBusinessLocations.length"
                  v-model="form.my_business_location_url"
                  :error="form.errors.my_business_location_url"
                  :label="$t('My Business Location')"
                >
                  <option :value="null" />
                  <option
                    v-for="(myBusinessLocation, key) in myBusinessLocations"
                    :key="key"
                    :value="myBusinessLocation.url"
                  >
                    {{ myBusinessLocation.address }} -
                    {{ myBusinessLocation.name }}
                  </option>
                </select-input>
              </div>
              <div v-if="form.type === 'class'" class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.parking_info"
                  :error="form.errors.parking_info"
                  :label="$t('Parking info')"
                />
              </div>
              <div class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.additional_directions"
                  :error="form.errors.additional_directions"
                  :help="$t('This field will be shown in confirmation emails sent to clients.')"
                  :label="$t('Additional Directions')"
                />
                <span class="mt-1 block text-sm text-gray-500">
                  <small>{{ $t('This information will be displayed in booking confirmation emails.') }}</small>
                </span>
              </div>
              <div class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.description"
                  :error="form.errors.description"
                  :label="$t('Description')"
                />
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <div>
                <button
                  v-if="location.deleted_at"
                  class="text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="restore"
                >
                  {{ $t('Restore Location') }}
                </button>
                <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                  {{ $t('Delete') }}
                </button>
                <button
                  v-if="location.archived_at"
                  class="ml-4 text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="unArchive"
                >
                  {{ $t('Restore Archived') }}
                </button>
                <button v-else class="ml-4 text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
                  {{ $t('Archive') }}
                </button>
              </div>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update Location') }}
              </loading-button>
            </div>
          </form>
        </div>
        <ModelTimestamps :model="location" />
      </div>
      <div class="flex w-full flex-col pt-12 lg:w-2/5 lg:pl-8 lg:pt-0 xl:w-1/3">
        <div class="mb-12 overflow-hidden rounded bg-white shadow lg:mb-8">
          <div class="flex items-center justify-between px-6 pb-3 pt-4">
            <div class="font-bold">{{ $t('Rooms') }}</div>
            <InertiaLink class="btn-blue py-2" :href="route('rooms.create', location.id)">
              {{ $t('Add') }}
            </InertiaLink>
          </div>
          <table class="w-full">
            <tbody>
              <tr
                v-for="room in rooms"
                :key="room.id"
                class="cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
                @click="$inertia.visit(route('rooms.edit', room.id))"
              >
                <td class="whitespace-nowrap border-t px-6 py-4">
                  {{ room.name }}
                  <span v-if="room.capacity">({{ room.capacity }})</span>
                </td>
                <td class="w-min border-t px-4 align-middle">
                  <icon class="block h-6 w-6 fill-gray-400" name="chevron-right" />
                </td>
              </tr>
              <tr v-if="location.rooms.length === 0">
                <td class="border-t px-6 py-4">{{ $t('No rooms found.') }}</td>
              </tr>
            </tbody>
          </table>

          <div v-if="showDeletedRoomsOption" class="border-t px-6 py-4">
            <label class="flex select-none items-center text-sm text-gray-600">
              <input v-model="showDeletedRooms" type="checkbox" />
              <span class="ml-1">{{ $t('Show deleted') }}</span>
            </label>
          </div>
        </div>
        <organization-card v-if="location.organization" class="mb-8" :organization="location.organization" />
        <calendar-card v-if="location.calendar_url" :calendar-url="location.calendar_url" />
      </div>
      <div class="mt-12 flex w-full flex-col">
        <div v-if="location.organization" class="mb-6 flex flex-col-reverse items-center justify-between lg:flex-row">
          <search-filter v-model="userForm.search" class="mr-4 w-full max-w-md" @reset="resetUserForm">
            <label class="block text-gray-800">{{ $t('Role:') }}</label>
            <select v-model="userForm.organizationRole" class="form-select mt-1 w-full">
              <option :value="null" />
              <option v-for="role in organizationRoles" :key="role.level" :value="role.level">{{ role.label }}</option>
            </select>
            <label class="mt-4 block text-gray-800">{{ $t('Archived:') }}</label>
            <select v-model="userForm.archived" class="form-select mt-1 w-full">
              <option :value="null" />
              <option value="with">{{ $t('With Archived') }}</option>
              <option value="only">{{ $t('Only Archived') }}</option>
            </select>
            <label class="mt-4 block text-gray-800">{{ $t('Deleted:') }}</label>
            <select v-model="userForm.deleted" class="form-select mt-1 w-full">
              <option :value="null" />
              <option value="with">{{ $t('With Deleted') }}</option>
              <option value="only">{{ $t('Only Deleted') }}</option>
            </select>
          </search-filter>
          <div class="w-full overflow-hidden lg:w-1/3">
            <form class="w-full" @submit.prevent="addUserToGroup">
              <div class="flex w-full flex-col items-start justify-end md:flex-row">
                <dynamic-search-input
                  v-model="addUser.user"
                  class="mb-2 w-full"
                  :error="addUser.form.errors.user_id"
                  :search-by="['name', 'email']"
                  track-by="id"
                  :url="route('users.search', { organization: location.organization_id, archived: 'with' })"
                >
                  <div v-if="addUser.user" class="flex items-center justify-between">
                    <div class="truncate">{{ addUser.user.name }}</div>
                    <div class="whitespace-nowrap text-xs text-gray-600">
                      {{ addUser.user.email }}
                    </div>
                  </div>
                  <template #option="{ option, selected }">
                    <div class="flex items-center justify-between">
                      <div>{{ option.name }}</div>
                      <div class="text-xs" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                        {{ option.email }}
                      </div>
                    </div>
                  </template>
                </dynamic-search-input>
                <loading-button class="btn-blue mb-2" :loading="addUser.form.processing" type="submit">
                  {{ $t('Add User') }}
                </loading-button>
              </div>
            </form>
          </div>
        </div>
        <div v-if="location.organization" class="rounded bg-white shadow">
          <table class="w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Name') }}</th>
                <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Email') }}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="location.organization.users.data.length === 0">
                <td class="border-t px-6 py-4" colspan="5">{{ $t('No members yet.') }}</td>
              </tr>
              <tr
                v-for="user in location.organization.users.data"
                :key="user.id"
                class="hover:bg-gray-100 focus:bg-gray-100"
              >
                <td class="border-t px-6 py-0">
                  <div class="flex items-center space-x-2">
                    <InertiaLink class="hover:underline focus:underline" :href="route('users.edit', user)">
                      <span class="whitespace-nowrap py-4">{{ user.name }}</span>
                    </InertiaLink>
                    <span v-if="user.organization_role !== 'basic'" class="rounded bg-gray-300 px-2 py-1 text-xs">
                      {{ user.organization_role_label }}
                    </span>
                    <archived-badge v-if="user.archived_at" />
                    <deleted-badge v-if="user.deleted_at" />
                  </div>
                </td>
                <td class="whitespace-nowrap border-t px-6 py-4">{{ user.email }}</td>
                <td class="w-min border-t px-4 align-middle text-red-500 hover:text-red-500 focus:text-red-500">
                  <button @click="removeUserFromGroup(user)">{{ $t('Delete') }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination
          v-if="location.organization && location.organization.users && location.organization.users.links"
          :links="location.organization.users.links"
        />
      </div>
    </div>
  </layout>
</template>

<script>
import ArchivedBadge from '@/Shared/ArchivedBadge.vue'
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import CalendarCard from '@/Shared/CalendarCard.vue'
import DeletedBadge from '@/Shared/DeletedBadge.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import DynamicSearchInput from '@/Shared/DynamicSearchInput.vue'
import GoogleMapsAutocomplete from '@/Shared/GoogleMapsAutocomplete.vue'
import Icon from '@/Shared/Icon.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import ModelTimestamps from '@/Shared/ModelTimestamps.vue'
import OrganizationCard from '@/Shared/OrganizationCard.vue'
import Pagination from '@/Shared/Pagination.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'
import _mapValues from 'lodash/mapValues'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    ModelTimestamps,
    ArchivedBadge,
    ArchivedMessage,
    CalendarCard,
    DeletedBadge,
    DeletedMessage,
    DynamicSearchInput,
    GoogleMapsAutocomplete,
    Icon,
    Layout,
    LoadingButton,
    OrganizationCard,
    Pagination,
    RadioInput,
    SearchFilter,
    SelectInput,
    TextInput,
    TextareaInput,
  },
  props: {
    location: Object,
    can: Object,
    timezones: Array,
    myBusinessLocations: Array,
    types: Object,
    organizationRoles: Array,
    userFilters: Object,
    users: Array,
  },
  data() {
    return {
      fullAddress: {},
      clientSite: this.location.client_site,
      isOwned: this.location.is_owned,
      selectedOrganization: this.location.organization,
      showDeletedRooms: false,
      form: useForm(
        {
          name: this.location.name,
          address: this.location.address,
          city: this.location.city,
          region: this.location.region,
          country: this.location.country,
          postal_code: this.location.postal_code,
          timezone: this.location.timezone,
          my_business_location_url: this.location.my_business_location_url,
          latitude: this.location.latitude,
          longitude: this.location.longitude,
          description: this.location.description,
          additional_directions: this.location.additional_directions,
          parking_info: this.location.parking_info,
          capacity: this.location.capacity,
          client_site: this.location.client_site,
          is_owned: this.location.is_owned,
          type: this.location.type,
          organization_id: this.location.organization?.id,
          is_archived: this.location.is_archived,
        },
        'LocationsEdit'
      ),
      userForm: {
        search: this.userFilters.search,
        organizationRole: this.userFilters.organizationRole,
        archived: this.userFilters.archived,
        deleted: this.userFilters.deleted,
      },
      addUser: {
        user: null,
        form: useForm({
          user_id: null,
        }),
      },
    }
  },
  computed: {
    showDeletedRoomsOption() {
      return this.location.rooms.filter((room) => room.deleted_at).length > 0
    },
    rooms() {
      return this.location.rooms.filter((room) => !room.deleted_at || this.showDeletedRooms)
    },
  },
  watch: {
    fullAddress: {
      handler: function (val) {
        this.form.address = val.street_number + ' ' + val.route
        this.form.city = val.locality
        this.form.region = val.administrative_area_level_1
        this.form.country = val.country
        this.form.postal_code = val.postal_code
        this.form.timezone = val.timeZone
        this.form.latitude = val.latitude
        this.form.longitude = val.longitude
      },
      deep: true,
    },
    isOwned: {
      handler: function (val) {
        this.form.is_owned = val
        if (val) this.clientSite = false
      },
    },
    clientSite: {
      handler: function (val) {
        this.form.client_site = val
        if (val) this.isOwned = false
      },
    },
    selectedOrganization(organization) {
      this.form.is_owned = !organization
      this.form.organization_id = organization ? organization.id : null
    },
    'addUser.user': function (newUser) {
      this.addUser.form.user_id = newUser?.id
    },
    userForm: {
      handler: _throttle(function () {
        let query = _pickBy(this.userForm)
        this.$inertia.get(
          this.route('locations.edit', this.location.id),
          Object.keys(query).length ? query : { remember: 'forget' },
          {
            replace: true,
            preserveState: true,
            preserveScroll: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    submit() {
      this.resetFields()
      this.form.put(this.route('locations.update', this.location.id))
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this location?'))) {
        this.$inertia.delete(this.route('locations.destroy', this.location.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this location?'))) {
        this.$inertia.put(this.route('locations.restore', this.location.id))
      }
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this location?'))) {
        this.$inertia.put(this.route('locations.archive', this.location.id))
      }
    },
    unArchive() {
      if (confirm(this.$t('Are you sure you want to restore this archived location?'))) {
        this.$inertia.put(this.route('locations.archive.restore', this.location.id))
      }
    },
    resetFields() {
      if (this.form.type !== 'class') {
        this.form.address = null
        this.form.city = null
        this.form.region = null
        this.form.country = null
        this.form.postal_code = null
        this.form.my_business_location_url = null
        this.form.latitude = null
        this.form.longitude = null
        this.form.parking_info = null
        this.form.client_site = false
      }
    },
    addUserToGroup() {
      if (this.addUser.form.user_id) {
        this.addUser.form.post(this.route('locations.members.store', [this.location.id, this.addUser.form.user_id]), {
          onSuccess: () => (this.addUser.user = null),
        })
      }
    },
    removeUserFromGroup(user) {
      this.$inertia.delete(this.route('locations.members.destroy', [this.location.id, user.id]))
    },
    resetUserForm() {
      this.userForm = _mapValues(this.userForm, () => null)
    },
  },
}
</script>
