<template>
  <div class="w-full font-heading font-semibold">
    <template v-if="$page.props.auth.user.is_instructor">
      <div v-if="showInstructorDashboard" class="mb-4 ml-12 text-xs uppercase text-gray-400">
        {{ $t('Instructor') }}
      </div>
      <main-menu-item :href="route('dashboard.instructors')" icon="dashboard" path="instructor">
        {{ $t('Dashboard') }}
      </main-menu-item>
      <main-menu-item :href="route('instructor.classes')" icon="apple" path="instructor/classes*">
        {{ $t('Classes') }}
      </main-menu-item>
      <main-menu-item :href="route('instructor.availability')" icon="calendar" path="instructor/availability*">
        {{ $t('Availability') }}
      </main-menu-item>
      <div v-if="showInstructorDashboard" class="mb-4 ml-12 mt-6 text-xs uppercase text-gray-400">
        {{ $t('Admin') }}
      </div>
    </template>

    <template
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
    >
      <main-menu-item :href="route('dashboard')" icon="dashboard" path="">
        {{ $t('Dashboard') }}
      </main-menu-item>
      <main-menu-item :href="route('organizations.index')" icon="office" path="organizations*">
        {{ $t('Organizations') }}
      </main-menu-item>
      <main-menu-item :href="route('users')" icon="users" path="users*">
        {{ $t('Users') }}
      </main-menu-item>
      <main-menu-item :href="route('enrolments')" icon="collection" path="enrolments*">
        {{ $t('Enrolments') }}
      </main-menu-item>
      <main-menu-item
        :href="route('courses')"
        icon="book"
        :path="[
          'courses*',
          'course-location',
          'course-categories*',
          'instructor-led-classrooms*',
          'checkin-forms*',
          'quizzes*',
          'elearnings*',
          'files*',
          'links*',
          'certificate-templates*',
        ]"
      >
        {{ $t('Courses') }}
        <template #submenu>
          <main-menu-item :href="route('courses')" :is-sub-menu="true" path="courses*">
            {{ $t('Courses') }}
          </main-menu-item>
          <main-menu-item :href="route('elearnings')" :is-sub-menu="true" path="elearnings*">
            {{ $t('eLearning') }}
          </main-menu-item>
          <main-menu-item
            :href="route('instructor-led-classrooms')"
            :is-sub-menu="true"
            path="instructor-led-classrooms*"
          >
            {{ $t('Classes') }}
          </main-menu-item>
          <main-menu-item :href="route('checkin-forms')" :is-sub-menu="true" path="checkin-forms*">
            {{ $t('Check-In Form') }}
          </main-menu-item>
          <main-menu-item :href="route('files')" :is-sub-menu="true" path="files*">
            {{ $t('Files') }}
          </main-menu-item>
          <main-menu-item :href="route('links')" :is-sub-menu="true" path="links*">
            {{ $t('Links') }}
          </main-menu-item>
          <main-menu-item :href="route('quizzes')" :is-sub-menu="true" path="quizzes*">
            {{ $t('Quizzes') }}
          </main-menu-item>
          <main-menu-item :href="route('certificate-templates')" :is-sub-menu="true" path="certificate-templates*">
            {{ $t('Certificates') }}
          </main-menu-item>
        </template>
      </main-menu-item>
      <main-menu-item :href="route('classes')" icon="calendar" path="classes*">
        {{ $t('Classes') }}
      </main-menu-item>
      <main-menu-item :href="route('locations')" icon="location" :path="['locations*', 'rooms']">
        {{ $t('Locations') }}
      </main-menu-item>
      <main-menu-item
        :href="route('transactions')"
        icon="document"
        :path="['transactions*', 'subscription-plans*', 'resellers*']"
      >
        {{ $t('eCommerce') }}
        <template #submenu>
          <main-menu-item :href="route('transactions')" :is-sub-menu="true" path="transactions*">
            {{ $t('Transactions') }}
          </main-menu-item>
          <main-menu-item :href="route('subscription-plans')" :is-sub-menu="true" path="subscription-plans*">
            {{ $t('Subscription Plans') }}
          </main-menu-item>
          <main-menu-item
            v-if="$page.props.auth.user.is_tenant_owner || $page.props.auth.user.is_super"
            :href="route('resellers')"
            :is-sub-menu="true"
            path="resellers*"
          >
            {{ $t('Resellers') }}
          </main-menu-item>
        </template>
      </main-menu-item>
      <main-menu-item :href="route('form-templates')" icon="clipboard" :path="['form-templates*', 'multiple-choices*']">
        {{ $t('EHS') }}
        <template #submenu>
          <main-menu-item :href="route('form-templates')" :is-sub-menu="true" path="form-templates*">
            {{ $t('Form Templates') }}
          </main-menu-item>
          <main-menu-item :href="route('multiple-choices.index')" :is-sub-menu="true" path="multiple-choices*">
            {{ $t('Multiple Choices') }}
          </main-menu-item>
        </template>
      </main-menu-item>
      <main-menu-item
        v-if="$page.props.auth.user.is_super"
        :href="route('triggers')"
        icon="clipboard"
        :path="['triggers*', 'subscription-banners*', 'marketing*']"
      >
        {{ $t('Marketing') }}
        <template #submenu>
          <main-menu-item :href="route('triggers')" :is-sub-menu="true" path="triggers*">
            {{ $t('Automation Triggers') }}
          </main-menu-item>
          <main-menu-item :href="route('subscription-banners')" :is-sub-menu="true" path="subscription-banners*">
            {{ $t('Subscription Banners') }}
          </main-menu-item>
          <main-menu-item :href="route('marketing.settings.edit')" :is-sub-menu="true" path="marketing/settings">
            {{ $t('Settings') }}
          </main-menu-item>
        </template>
      </main-menu-item>
      <main-menu-item :href="route('plastic-card-orders')" icon="clipboard" path="plastic-card-orders*">
        {{ $t('Plastic Cards') }}
      </main-menu-item>
      <main-menu-item :href="route('reports')" icon="printer" path="reports*">
        {{ $t('Reports') }}
      </main-menu-item>
    </template>
  </div>
</template>

<script>
import MainMenuItem from './MainMenuItem.vue'
import { isUrl } from '@/Utils/Helpers'

export default {
  components: { MainMenuItem },
  data() {
    return {
      showContents: false,
      showCourseDropdown: false,
    }
  },
  computed: {
    showInstructorDashboard() {
      return (
        this.$page.props.auth.user.is_instructor &&
        (this.$page.props.auth.user.is_admin ||
          this.$page.props.auth.user.is_support ||
          this.$page.props.auth.user.is_super)
      )
    },
    prefix() {
      return 'admin/'
    },
  },
  methods: {
    isUrl,
  },
}
</script>
