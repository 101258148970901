<template>
  <layout :title="user.display_name">
    <div class="mb-8 flex items-center justify-between">
      <h1 class="text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('worksite-users.index')">
          {{ $t('Back-Office Users') }}
        </InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ user.display_name }}
      </h1>
    </div>
    <deleted-message v-if="user.deleted_at" class="mb-6">{{ $t('This user has been deleted.') }}</deleted-message>
    <archived-message v-if="user.archived_at" class="mb-6">
      <div class="flex w-full justify-between">
        <div>{{ $t('This user has been archived.') }}</div>
        <button
          v-if="user.archived_at"
          class="ml-4 text-yellow-800 hover:underline"
          tabindex="-1"
          type="button"
          @click="unArchive"
        >
          {{ $t('Restore') }}
        </button>
      </div>
    </archived-message>
    <div class="flex flex-wrap">
      <div class="flex w-full flex-col gap-x-8 md:flex-row">
        <div class="w-full max-w-lg">
          <div class="overflow-hidden rounded bg-white shadow">
            <form autocomplete="off" @submit.prevent="submit">
              <div class="-mb-8 -mr-6 flex flex-wrap p-8">
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.first_name"
                    autocomplete="off"
                    :error="form.errors.first_name"
                    :label="$t('First Name')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.last_name"
                    autocomplete="off"
                    :error="form.errors.last_name"
                    :label="$t('Last Name')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <select-input v-model="form.timezone" :error="form.errors.timezone" :label="$t('Timezone')">
                    <option :value="null" />
                    <option v-for="(timezone, key) in timezones" :key="key" :value="timezone">
                      {{ timezone }}
                    </option>
                  </select-input>
                </div>
              </div>
              <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Contact Information') }}</div>
              <div class="-mb-8 -mr-6 flex flex-wrap p-8">
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input v-model="form.phone" autocomplete="off" :error="form.errors.phone" :label="$t('Phone')" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.address"
                    autocomplete="off"
                    :error="form.errors.address"
                    :label="$t('Address')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input v-model="form.city" autocomplete="off" :error="form.errors.city" :label="$t('City')" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <select-input
                    v-model="form.region"
                    :error="form.errors.region"
                    :label="`${$t('Province')}/${$t('State')}`"
                    :options="[null, ...regions]"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.postal_code"
                    autocomplete="off"
                    :error="form.errors.postal_code"
                    :label="$t('Postal Code')"
                  />
                </div>
              </div>
              <div class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">{{ $t('Login') }}</div>
              <div class="-mb-8 -mr-6 flex flex-wrap p-8">
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.email"
                    autocomplete="off"
                    :disabled="!can.updateLoginCredentials"
                    :error="form.errors.email"
                    :label="$t('Email')"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input v-model="form.username" :error="form.errors.username" :label="$t('Username')" />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <text-input
                    v-model="form.password"
                    autocomplete="new-password"
                    :disabled="!can.updateLoginCredentials"
                    :error="form.errors.password"
                    :label="$t('Password')"
                    type="password"
                  />
                </div>
              </div>
              <div v-if="can.selectBambooHrUser" class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">
                {{ $t('Human Resource') }}
              </div>
              <div v-if="can.selectBambooHrUser" class="-mb-8 -mr-6 flex flex-wrap p-8">
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <select-input
                    v-model="form.bamboo_hr_id"
                    :error="form.errors.bamboo_hr_id"
                    :label="$t('BambooHr User')"
                  >
                    <option :value="null" />
                    <template v-for="bambooHrUser in bambooHrUsers" :key="bambooHrUser.id">
                      <option :value="bambooHrUser.id">{{ `${bambooHrUser.name} (ID:${bambooHrUser.id})` }}</option>
                    </template>
                  </select-input>
                </div>
              </div>
              <div v-if="$page.props.auth.user.is_super" class="bg-gray-300 px-8 py-4 text-sm font-bold text-gray-700">
                {{ $t('Backoffice Access') }}
              </div>
              <div v-if="$page.props.auth.user.is_super" class="-mb-8 -mr-6 flex flex-wrap p-8">
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <select-input
                    v-if="can.makeAdmin || can.makeSupport || can.makeSuper"
                    v-model="form.access"
                    :error="form.errors.access"
                    :label="$t('Admin access')"
                  >
                    <option :value="null" />
                    <option v-if="can.makeAdmin" value="Admin">{{ $t('Admin') }}</option>
                    <option v-if="can.makeSupport" value="Support">{{ $t('Support') }}</option>
                    <option v-if="can.makeSuper" value="Super">{{ $t('Super') }}</option>
                  </select-input>
                  <div v-else>
                    <label class="form-label">{{ $t('Admin access:') }}</label>
                    <div class="form-input bg-gray-100 text-gray-600">{{ user.access }}</div>
                    <div v-if="form.errors.access" class="form-error">
                      {{ form.errors.access }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="can.makeTenantOwner && ['Super', 'Admin'].includes(form.access)"
                  class="w-full pb-8 pr-6 lg:w-1/2"
                >
                  <radio-input
                    v-model="form.is_tenant_owner"
                    :error="form.errors.is_tenant_owner"
                    :label="$t('Is an account owner')"
                    type="radio"
                  />
                </div>
                <div class="w-full pb-8 pr-6 lg:w-1/2">
                  <radio-input
                    v-model="form.is_instructor"
                    :error="form.errors.is_instructor"
                    :label="$t('Is an instructor')"
                    type="radio"
                  />
                </div>
              </div>
              <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
                <div>
                  <button
                    v-if="user.deleted_at"
                    class="text-red-500 hover:underline"
                    tabindex="-1"
                    type="button"
                    @click="restore"
                  >
                    {{ $t('Restore') }}
                  </button>
                  <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                    {{ $t('Delete') }}
                  </button>
                  <button
                    v-if="user.archived_at"
                    class="ml-4 text-red-500 hover:underline"
                    tabindex="-1"
                    type="button"
                    @click="unArchive"
                  >
                    {{ $t('Restore Archived') }}
                  </button>
                  <button v-else class="ml-4 text-red-500 hover:underline" tabindex="-1" type="button" @click="archive">
                    {{ $t('Archive') }}
                  </button>
                </div>
                <loading-button class="btn-blue" :loading="form.processing" type="submit">
                  {{ $t('Update') }}
                </loading-button>
              </div>
            </form>
          </div>
          <ModelTimestamps :model="user" />
        </div>
        <div v-if="!user.deleted_at && !user.archived_at" class="mt-8 w-full space-y-4 md:mt-0 md:max-w-sm">
          <calendar-card v-if="user.is_instructor" :calendar-url="user.calendar_url" class="mt-12 lg:mt-0" />
          <card name="User">
            <InertiaLink class="link" :href="route('users.edit', user)">
              <span>{{ $t('User Edit Page') }}</span>
            </InertiaLink>
          </card>
          <card v-if="user.is_instructor" name="Approved Classes">
            <Multiselect
              v-model="selectedInstructorLedClassrooms"
              deselect-label=""
              :hide-label="true"
              :hide-selected="true"
              :label="$t('name')"
              :loading="isInstructorLedClassroomSearching"
              :multiple="true"
              :name="$t('Approved Classes')"
              :options="instructorLedClassrooms"
              :placeholder="$t('Select classrooms')"
              select-label=""
              track-by="id"
              @remove="deleteInstructorLedClassroom"
              @search-change="searchInstructorLedClassrooms"
              @select="createInstructorLedClassroom"
            >
              <template #noResult>{{ $t('No classrooms found.') }}</template>
              <template #noOptions>{{ $t('No classrooms found.') }}</template>
            </Multiselect>
          </card>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import ArchivedMessage from '@/Shared/ArchivedMessage.vue'
import CalendarCard from '@/Shared/CalendarCard.vue'
import Card from '@/Shared/Card.vue'
import DeletedMessage from '@/Shared/DeletedMessage.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import ModelTimestamps from '@/Shared/ModelTimestamps.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
    ArchivedMessage,
    CalendarCard,
    Card,
    DeletedMessage,
    Layout,
    LoadingButton,
    ModelTimestamps,
    RadioInput,
    SelectInput,
    TextInput,
  },
  props: {
    can: Object,
    user: Object,
    bambooHrUsers: Array,
    regions: Array,
    timezones: Object,
    instructor_led_classrooms: Object,
  },
  data() {
    return {
      selectedInstructorLedClassrooms: this.user.instructor_led_classrooms,
      instructorLedClassrooms: this.instructor_led_classrooms || [],
      isInstructorLedClassroomSearching: false,
      form: useForm(
        {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          username: this.user.username,
          phone: this.user.phone,
          address: this.user.address,
          city: this.user.city,
          region: this.user.region,
          postal_code: this.user.postal_code,
          timezone: this.user.timezone,
          password: this.user.password,
          access: this.user.access,
          bamboo_hr_id: this.user.bamboo_hr_id,
          is_tenant_owner: this.user.is_tenant_owner,
          is_instructor: this.user.is_instructor,
        },
        'UserEdit'
      ),
      noteForm: useForm({
        content: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          username: data.username,
          phone: data.phone,
          bamboo_hr_id: data.bamboo_hr_id,
          address: data.address,
          city: data.city,
          region: data.region,
          postal_code: data.postal_code,
          timezone: data.timezone,
          password: data.password,
          access: data.access,
          is_tenant_owner: ['Super', 'Admin'].includes(data.access) ? data.is_tenant_owner : false,
          is_instructor: data.is_instructor,
        }))
        .put(this.route('worksite-users.update', this.user.id))
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this user?'))) {
        this.$inertia.put(this.route('worksite-users.archive', this.user.id))
      }
    },
    unArchive() {
      if (confirm(this.$t('Are you sure you want to restore this archived user?'))) {
        this.$inertia.put(this.route('worksite-users.archive.restore', this.user.id))
      }
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this user?'))) {
        this.$inertia.delete(this.route('worksite-users.destroy', this.user.id))
      }
    },
    restore() {
      if (confirm(this.$t('Are you sure you want to restore this user?'))) {
        this.$inertia.put(this.route('worksite-users.restore', this.user.id))
      }
    },
    truncate(text, length) {
      if (text) return null
      if (text?.length > length) {
        return text.substring(0, length) + '...'
      }
      return text
    },
    searchInstructorLedClassrooms(query) {
      this.isInstructorLedClassroomSearching = true
      Http.get(this.route('instructor-led-classrooms.search'), {
        params: { search: query },
      })
        .then((response) => {
          this.instructorLedClassrooms = response.data.data
        })
        .finally(() => {
          this.isInstructorLedClassroomSearching = false
        })
    },
    createInstructorLedClassroom(selectedOption) {
      this.$inertia.post(
        this.route('worksite-users.create.instructor-led-classroom', this.user.id),
        {
          optionId: selectedOption.id,
        },
        {
          onFinish: () => {
            this.selectedInstructorLedClassrooms = this.user.instructor_led_classrooms
          },
        }
      )
    },
    deleteInstructorLedClassroom(selectedOption) {
      this.$inertia.post(
        this.route('worksite-users.delete.instructor-led-classroom', this.user.id),
        {
          optionId: selectedOption.id,
        },
        {
          onFinish: () => {
            this.selectedInstructorLedClassrooms = this.user.instructor_led_classrooms
          },
        }
      )
    },
  },
}
</script>
