<template>
  <front-layout :title="$t('Check-In')">
    <div class="w-full md:w-1/2">
      <InertiaLink class="text-darkGray flex items-center px-3 hover:font-semibold" href="#">
        <icon class="block h-3 w-3 fill-current" name="arrow-left" />
        <span class="ml-1" @click="goBack()">{{ $t('Back') }}</span>
      </InertiaLink>
    </div>
    <div class="flex flex-col lg:flex-row">
      <div class="mt-6 max-w-xl overflow-hidden rounded-lg bg-white shadow-lg">
        <div v-if="user.checkedIn" class="px-40 pb-6 pt-6">
          <h1 class="text-center text-xl font-bold">
            {{ $t('Thank you for checking in, :first_name.', { first_name: user.first_name }) }}
          </h1>
          <div class="mt-4 text-center text-xl text-gray-600">{{ $t('You can now start your training!') }}</div>
        </div>
        <div v-else>
          <div class="px-10 pb-6 pt-12">
            <h1 class="text-center text-xl font-bold">
              {{ $t('Check-in for Upcoming :course_name Class', { course_name: user.course.name }) }}
            </h1>
            <div class="mt-4 text-center text-xl text-gray-600">{{ user.first_name }} {{ user.last_name }}</div>
          </div>
          <div
            v-if="user.course.ministry_of_labour_code"
            class="mx-12 mt-6 rounded bg-yellow-300 px-6 pb-5 pt-6 text-center leading-tight text-yellow-800"
          >
            {{ $t('The following information is required by the') }}
            <strong>{{ $t('Ontario Ministry of Labour') }}</strong>
            .
          </div>
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-12">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('First name')" :modelValue="user.first_name" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('Last name')" :modelValue="user.last_name" />
              </div>
              <div v-if="user.course.is_esc_course" class="w-full pb-8 pr-6 lg:w-1/4">
                <select-input v-model="form.birth_day" :error="form.errors.birth_day" label="Birth Day">
                  <option :value="null" />
                  <option v-for="day in 31" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select-input>
              </div>
              <div v-if="user.course.is_esc_course" class="w-full pb-8 pr-6 lg:w-1/4">
                <select-input v-model="form.birth_month" :error="form.errors.birth_month" label="Birth Month">
                  <option :value="null" />
                  <option v-for="month in 12" :key="month" :value="month">
                    {{ month }}
                  </option>
                </select-input>
              </div>
              <div
                v-if="user.course.ministry_of_labour_code || user.course.is_esc_course"
                class="w-full pb-8 pr-6 lg:w-1/4"
              >
                <text-input
                  v-model="form.birth_year"
                  autocomplete="off"
                  :error="form.errors.birth_year"
                  :label="$t('Birth year')"
                  min="1920"
                  type="number"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  v-model="form.username"
                  :error="isUsernameError || form.errors.username ? form.errors.username : ''"
                  :label="$t('Username')"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  v-model="form.email"
                  :error="isEmailError || form.errors.email ? form.errors.email : ''"
                  :label="$t('Email')"
                  type="email"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" type="tel" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.address" :error="form.errors.address" :label="$t('Address')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.region"
                  :error="form.errors.region"
                  :label="$t('Province/State')"
                  :options="[null, ...regions]"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
              </div>
            </div>
            <div class="flex justify-end border-t border-gray-100 bg-gray-100 px-12 py-6">
              <front-loading-button :loading="form.processing" type="submit">
                {{ $t('Complete check-in') }}
              </front-loading-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </front-layout>
</template>

<script>
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import Icon from '@/Shared/Icon.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import _debounce from 'lodash/debounce'

export default {
  components: {
    FrontLayout,
    FrontLoadingButton,
    Icon,
    SelectInput,
    TextInput,
  },
  props: {
    regions: Array,
    user: Object,
    enrolmentContent: Object,
  },
  data() {
    return {
      isStudentCheckInRequest: true,
      isEmailError: false,
      isUsernameError: false,
      form: useForm(
        {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          birth_day: this.user.birth_day,
          birth_month: this.user.birth_month,
          birth_year: this.user.birth_year,
          username: this.user.username,
          email: this.user.email,
          phone: this.user.phone,
          address: this.user.address,
          city: this.user.city,
          region: this.user.region,
          postal_code: this.user.postal_code,
        },
        'CheckInElearning'
      ),
    }
  },
  watch: {
    'form.email': _debounce(function () {
      if (this.form.email) {
        this.checkEmailAvailability()
      } else {
        this.form.errors.email = ''
      }
    }, 300),
    'form.username': _debounce(function () {
      if (this.form.username) {
        this.checkUsernameAvailability()
      } else {
        this.form.errors.username = ''
      }
    }, 300),
  },
  methods: {
    checkEmailAvailability() {
      let params = { email: this.form.email }
      if (this.user.user) {
        params.user = this.user.user.id
      }
      Http.get(this.route('front.email.availability'), { params })
        .then(() => {
          this.isEmailError = false
          this.form.errors.email = ''
        })
        .catch((error) => {
          this.isEmailError = true
          this.form.errors.email = error.response.data.errors.email
        })
    },
    checkUsernameAvailability() {
      let params = { username: this.form.username }
      if (this.user.user) {
        params.user = this.user.user.id
      }
      Http.get(this.route('front.username.availability'), { params })
        .then(() => {
          this.isUsernameError = false
          this.form.errors.username = ''
        })
        .catch((error) => {
          this.isUsernameError = true
          this.form.errors.username = error.response.data.errors.username[0]
          console.log(this.form.errors.username)
        })
    },
    submit() {
      this.form.post(this.route('front.elearnings.check-in.store', this.enrolmentContent.id))
    },
    goBack() {
      window.location = this.route('front.contents', [this.enrolmentContent.enrolment_id])
    },
  },
}
</script>
