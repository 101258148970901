<template>
  <tr>
    <td class="w-1/2 whitespace-nowrap py-4 font-semibold">
      {{ subscriptionItem.subscriptionPlan?.title }}
    </td>
    <td class="w-1/2 w-auto whitespace-nowrap py-4">
      <div class="flex items-center justify-end">
        <MinusCircleIcon
          v-if="subscriptionItem.subscriptionPlan.is_active"
          aria-hidden="true"
          class="mr-2 h-8 w-8 cursor-pointer text-gray-300 transition duration-200 ease-in hover:text-gray-600"
          @click="decreaseQuantity"
        />
        <input
          v-model="quantity"
          class="h-8 w-20 rounded-lg border border-2 bg-ts-front-field-light p-2 text-center text-ts-front-label focus:border-gray-400 focus:text-gray-600 focus:outline-none"
          disabled="true"
          type="text"
        />
        <PlusCircleIcon
          v-if="subscriptionItem.subscriptionPlan.is_active"
          aria-hidden="true"
          class="ml-2 h-8 w-8 cursor-pointer text-gray-300 transition duration-200 ease-in hover:text-gray-600"
          @click="increaseQuantity"
        />
      </div>
    </td>
  </tr>
</template>
<script>
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/vue/20/solid'

export default {
  components: { PlusCircleIcon, MinusCircleIcon },
  props: {
    subscriptionItem: Object,
  },
  data() {
    return {
      quantity: this.subscriptionItem.quantity,
    }
  },
  methods: {
    increaseQuantity() {
      this.quantity++
      this.updateQuantity()
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.quantity--
        this.updateQuantity()
      }
    },
    updateQuantity() {
      this.$emit('update-quantity', this.quantity)
    },
  },
}
</script>
