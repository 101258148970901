<template>
  <layout :title="form.name">
    <ModelStatusMessages class="my-4" :model="elearning" />
    <div class="mb-6 flex max-w-2xl items-center justify-between">
      <h1 class="text-xl font-bold">
        <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('elearnings')">
          {{ $t('e-Learning') }}
        </InertiaLink>
        <span class="font-medium text-blue-600">/</span>
        {{ form.name }}
      </h1>
      <InertiaLink v-if="related_contentable" class="btn-blue" :href="route('elearnings.edit', related_contentable.id)">
        {{ $t('Edit Related e-Learning (:lang)', { lang: related_contentable.lang }) }}
      </InertiaLink>
      <InertiaLink v-else class="btn-blue" :href="route('elearnings.create', { contentable: elearning.id })">
        {{ $t('Create Related e-Learning') }}
      </InertiaLink>
    </div>
    <div class="flex w-full flex-col gap-x-8 md:flex-row items-start">
      <div>
        <div class="w-full max-w-lg overflow-hidden rounded bg-white shadow">
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-8">
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.name" :error="form.errors.name" :label="$t('Name (Internal)')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <text-input v-model="form.title" :error="form.errors.title" :label="$t('Title')" />
              </div>
              <div class="w-full pb-8 pr-6">
                <textarea-input
                  v-model="form.description"
                  :autosize="true"
                  :error="form.errors.description"
                  :label="$t('Description')"
                  rows="2"
                />
              </div>
            </div>
            <div class="flex items-center justify-between border-t border-gray-100 bg-gray-100 px-8 py-4">
              <div class="space-x-2">
                <button class="text-red-500 hover:underline" tabindex="-1" type="button" @click="destroy">
                  {{ $t('Delete') }}
                </button>
                <button
                  v-if="!elearning.archived_at"
                  class="text-red-500 hover:underline"
                  tabindex="-1"
                  type="button"
                  @click="archive"
                >
                  {{ $t('Archive') }}
                </button>
                <button v-else class="text-red-500 hover:underline" tabindex="-1" type="button" @click="unarchive">
                  {{ $t('un-Archive') }}
                </button>
              </div>
              <loading-button class="btn-blue" :loading="form.processing" type="submit">
                {{ $t('Update e-Learning') }}
              </loading-button>
            </div>
          </form>
        </div>
        <div>
          <ModelTimestamps :model="elearning" />
        </div>
      </div>
      <div class="mt-8 w-full space-y-4 md:mt-0 md:max-w-sm">
        <card class="p-0" name="Courses">
          <div class="flex w-full flex-col">
            <div v-for="course in courses" :key="course.id" class="border-b last:border-b-0">
              <InertiaLink class="block w-full p-4" :href="route('courses.edit', course.id)">
                {{ course.name }}
              </InertiaLink>
            </div>
          </div>
        </card>
        <card class="p-0" name="SCORM File">
          <div class="flex w-full flex-col">
            <form @submit.prevent="scormSubmit">
              <file-input
                accept="zip,application/zip"
                :error="formScorm.errors.elearning"
                @input="formScorm.elearning = $event"
              />
              <button class="btn-blue w-full p-4">
                {{ $t('Submit') }}
              </button>
              <div class="border-t mt-4" />
              <div class="p-4">
                <p class="text-sm text-gray-500">
                  {{ $t('Upload SCORM file to replace the current one.') }}
                </p>
              </div>
            </form>
          </div>
        </card>
        <VersionHistoryCard route-name="elearnings.edit" :value="progressiveVersions" />
      </div>
    </div>
  </layout>
</template>

<script>
import Card from '@/Shared/Card.vue'
import FileInput from '@/Shared/FileInput.vue'
import Layout from '@/Shared/Layout.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import ModelStatusMessages from '@/Shared/Model/ModelStatusMessages.vue'
import ModelTimestamps from '@/Shared/ModelTimestamps.vue'
import VersionHistoryCard from '@/Shared/ProgressiveVersionable/VersionHistoryCard.vue'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import { useForm } from '@inertiajs/vue3'

export default {
  components: {
    Card,
    Layout,
    LoadingButton,
    FileInput,
    TextInput,
    TextareaInput,
    ModelTimestamps,
    ModelStatusMessages,
    VersionHistoryCard,
  },
  props: {
    elearning: Object,
    progressiveVersions: Object,
    courses: Array,
    related_contentable: Object,
  },
  data() {
    return {
      form: useForm({
        name: this.elearning.name,
        title: this.elearning.title,
        description: this.elearning.description,
      }),
      formScorm: useForm({
        elearning: null,
      }),
    }
  },
  methods: {
    submit() {
      this.form.put(this.route('elearnings.update', this.elearning.id))
    },
    scormSubmit() {
      this.formScorm.post(this.route('elearnings.scorm.update', this.elearning.id))
    },
    archive() {
      if (confirm(this.$t('Are you sure you want to archive this e-Learning?'))) {
        this.$inertia.post(this.route('elearnings.archive', this.elearning.id))
      }
    },
    unarchive() {
      if (confirm(this.$t('Are you sure you want to restore this e-Learning?'))) {
        this.$inertia.post(this.route('elearnings.unarchive', this.elearning.id))
      }
    },
    destroy() {
      if (confirm(this.$t('Are you sure you want to delete this e-Learing?'))) {
        this.$inertia.delete(this.route('elearnings.destroy', this.elearning.id))
      }
    },
  },
}
</script>
