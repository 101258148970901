<template>
  <div class="w-full">
    <label>
      <span v-if="label">{{ label }}</span>
      <select
        :id="id"
        ref="input"
        v-model="selected"
        class="form-select whitespace-nowrap rounded-full border-ts-red-600 bg-ts-red-600 font-semibold text-white"
      >
        <slot v-if="Array.isArray(options)">
          <option v-for="(value, key) in options" :key="key" :value="value">{{ value }}</option>
        </slot>
        <slot v-else>
          <option v-for="(name, value) in options" :key="value" :value="value">
            {{ name }}
          </option>
        </slot>
      </select>
    </label>
  </div>
</template>

<script>
import { loadLanguageAsync } from 'laravel-vue-i18n'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
    },
    label: String,
    options: [Array, Object],
  },
  data() {
    return {
      selected: this.$page.props.locale ?? 'en',
    }
  },
  watch: {
    value() {
      this.selected = this.value
    },
    selected(selected) {
      if (
        confirm(this.$t('Any unsaved changes on this page will be lost. Are you sure you want to change the language?'))
      ) {
        this.$inertia.post(
          this.route('set-locale', selected),
          {},
          {
            onSuccess: () => {
              loadLanguageAsync(selected)
              window.location.reload()
            },
          }
        )
      } else {
        this.selected = this.$page.props.locale ?? 'en'
      }
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
