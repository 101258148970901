<template>
  <form @submit.prevent="submit">
    <styled-modal max-width="sm" :show="show" @close="close">
      <template #title>{{ $t('Create Subscription') }}</template>

      <template #content>
        <div class="max-w-sm my-6">
          <div class="w-full">
            <text-input
              v-model="form.quantity"
              :error="form.errors.quantity"
              :label="$t('No. Of Users:')"
              type="number"
            />
          </div>
          <div class="w-full my-4">
            <radio-input
              v-model="form.add_trial_without_payment_method"
              :error="form.errors.add_trial_without_payment_method"
              :label="$t('Add Trial Without Payment Method')"
              type="radio"
            />
          </div>
          <div v-show="form.add_trial_without_payment_method" class="w-full my-4">
            <label>{{ $t('Add Free Trial Days:') }}</label>
            <div class="flex items-center justify-between mt-2">
              <div class="relative flex items-center justify-end mr-3">
                <input
                  ref="input"
                  v-model="form.free_trial_days"
                  class="form-input pr-8"
                  maxlength="3"
                  type="text"
                  @change="onInputChange"
                  @keyup="onInputChange"
                />
                <div ref="icon" class="absolute cursor-pointer">
                  <icon class="h-5 w-5 fill-gray-500 mr-2" name="calendar" />
                </div>
              </div>
              <div>
                <input
                  ref="picker"
                  class="bg-transparent select-none"
                  disabled
                  type="text"
                  :value="getPickerValue"
                  @change="onPickerChange"
                />
              </div>
            </div>
          </div>
          <div v-if="form.errors.free_trial_days" class="form-error">{{ form.errors.free_trial_days }}</div>
        </div>
      </template>

      <template #footer>
        <div class="flex flex-wrap justify-between space-y-2 sm:space-x-4 sm:space-y-0">
          <button class="w-full px-6 py-2 text-gray-500 sm:w-auto" @click.prevent="close">{{ $t('Cancel') }}</button>
          <loading-button class="btn-cancel" :loading="form.processing" type="button" @click="submit">
            <span>{{ $t('Create Subscription') }}</span>
          </loading-button>
        </div>
      </template>
    </styled-modal>
  </form>
</template>
<script>
import Icon from '@/Shared/Icon.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import RadioInput from '@/Shared/RadioInput.vue'
import StyledModal from '@/Shared/StyledModal.vue'
import TextInput from '@/Shared/TextInput.vue'
import { useForm } from '@inertiajs/vue3'
import moment from 'moment'
import pikaday from 'pikaday'

export default {
  components: {
    LoadingButton,
    StyledModal,
    Icon,
    TextInput,
    RadioInput,
  },
  props: {
    show: Boolean,
    organization: {
      type: Object,
      required: true,
    },
    subscriptionPlan: Object,
  },
  data() {
    return {
      form: useForm({
        add_trial_without_payment_method: false,
        quantity: 1,
        free_trial_days: moment(moment(), 'YYYYMMDD').diff(moment(), 'days'),
      }),
      trial_end_date: moment(moment()).format('ll'),
      picker: null,
    }
  },
  mounted() {
    this.picker = new pikaday({
      format: 'll',
      toString(date) {
        let day = moment(date).format('ll')
        return day
      },
      reposition: true,
      position: 'bottom right',
      field: this.$refs.picker,
      trigger: this.$refs.icon,
      firstDay: 1,
      minDate: moment().toDate(),
      maxDate: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate() + 1), // Restricting free trial to 1 year
      defaultDate: new Date(),
      setDefaultDate: true,
      theme: 'date-input',
      keyboardInput: false,
      i18n: {
        previousMonth: 'Prev',
        nextMonth: 'Next',
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      },
    })
  },
  computed: {
    getPickerValue() {
      return this.$t('Ends :trial_end_date', { trial_end_date: this.trial_end_date })
    },
  },
  methods: {
    submit() {
      this.form.post(
        this.route('organizations.subscription-plans.store', [this.organization.id, this.subscriptionPlan.id]),
        {
          onSuccess: () => {
            this.close()
            // Setting the date picker to the new trial end date
            this.picker.setDate(this.trial_end_date)
            this.picker.hide()
          },
        }
      )
    },
    close() {
      this.form.errors.free_trial_days = null
      this.$emit('close')
    },
    onInputChange(e) {
      if (e.target.value === '' || e.target.value < 0) {
        e.target.value = 0
      }
      this.form.free_trial_days = e.target.value.replace(/^0+/, '')
      this.trial_end_date = moment().add(this.form.free_trial_days, 'days').format('ll')
    },
    onPickerChange(e) {
      this.trial_end_date = e.target.value
      this.form.free_trial_days = moment(this.trial_end_date).diff(moment().format('ll'), 'days')
    },
  },
}
</script>
<style scoped>
@import 'pikaday/css/pikaday.css';
</style>
