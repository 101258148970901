<template>
  <signature-type-layout :form-filled="formFilled" :is-last="isLast" :signature="signature">
    <div class="mb-4 flex w-full flex-col justify-between xl:flex-row xl:items-center">
      <div>
        <small class="block uppercase text-gray-500">
          {{
            $t(':signature_type Signature', {
              signature_type: signature.signature_type,
            })
          }}
        </small>
        <div v-if="signatureRequests.length" class="mb-6 mt-2">
          <div v-for="(signatureRequest, index) in signatureRequests" :key="index" class="my-2 flex items-center">
            <p class="mr-2 text-sm font-semibold text-gray-500">
              {{
                $t(':name (:email)', {
                  name: signatureRequest.receiver_name,
                  email: signatureRequest.receiver_email,
                })
              }}
            </p>
            <signature-status :signature-request="signatureRequest" />
            <button
              v-if="signatureRequest.expired_at || isSignatureRequestPending(signatureRequest)"
              class="ml-2 cursor-pointer text-xs uppercase text-sky-700 hover:underline"
              @click="resendRequest(signatureRequest)"
            >
              {{ $t('Resend Request') }}
            </button>
          </div>
        </div>
        <p class="text-sm font-semibold text-ts-gray-text-bold">
          {{ $t('Email will be sent to the selected manager(s) for review upon completion.') }}
        </p>
      </div>
    </div>

    <div class="flex w-full items-center justify-between">
      <div class="w-1/2">
        <Multiselect
          v-model="form.users"
          class="text-sm"
          :close-on-select="false"
          deselect-label=""
          :hide-label="true"
          :hide-selected="true"
          :label="$t('name')"
          :loading="isLoading"
          :multiple="true"
          :name="$t('Manager(s)')"
          open-direction="bottom"
          :options="findManagersData"
          :placeholder="$t('Search Manager(s)')"
          :searchable="true"
          select-label=""
          :show-pointer="false"
          track-by="id"
          @remove="optionRemoved($event)"
          @search-change="findManagers()"
          @select="optionSelected($event)"
        >
          <template #option="props">
            <div
              class="flex items-center justify-between"
              :class="props.option.hasEHSAccess ? 'text-black' : 'cursor-text text-black/40'"
            >
              <div>{{ props.option.name }}</div>
              <div>{{ props.option.email }}</div>
            </div>
          </template>
          <template #noResult>{{ $t('No manager(s) found.') }}</template>
          <template #noOptions>{{ $t('No manager(s) found.') }}</template>
        </Multiselect>
      </div>
    </div>
  </signature-type-layout>
</template>
<script>
import SignatureStatus from './SignatureStatus.vue'
import SignatureTypeLayout from './SignatureTypeLayout.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: { SignatureTypeLayout, SignatureStatus, Multiselect },
  props: {
    signature: Object,
    formFilled: Object,
    signatureRequests: Object,
    isLast: Boolean,
  },
  data() {
    return {
      resendRequestForm: useForm({
        requests: [
          {
            users: [],
            signatureType: this.signature.signature_type,
          },
        ],
      }),
      form: {
        users: [],
        signatureType: this.signature.signature_type,
      },
      isLoading: false,
      findManagersData: [],
    }
  },
  watch: {
    signatureRequests: {
      handler() {
        this.form.users = []
      },
      deep: true,
    },
  },
  methods: {
    isSignatureRequestPending(request) {
      return !request.accepted_at && !request.expired_at
    },
    resendRequest(signatureRequest) {
      this.resendRequestForm.requests[0].users.push({
        id: signatureRequest.receiver_id,
        name: signatureRequest.receiver_name,
        email: signatureRequest.receiver_email,
      })

      this.resendRequestForm.post(this.route('front.filledForms.signature-request.store', this.formFilled?.id), {
        preserveScroll: true,
        onSuccess: () => {
          this.resendRequestForm.reset()
        },
      })
    },
    findManagers(query) {
      this.isLoading = true
      Http.get(this.route('front.users.search', { onlyManagers: true }), {
        params: { search: query },
      }).then((response) => {
        this.findManagersData = response.data.data
        this.isLoading = false
      })
    },
    optionSelected(selectedOption) {
      if (!selectedOption.hasEHSAccess) {
        this.form.users = this.form.users.filter((option) => option !== selectedOption)
      } else {
        this.$emit('update-data', { users: this.form.users, signatureType: this.form.signatureType })
      }
    },
    optionRemoved(removedOption) {
      console.log(removedOption)
      this.$emit('update-data', { users: this.form.users, signatureType: this.form.signatureType })
    },
  },
}
</script>
