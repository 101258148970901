<template>
  <front-layout :title="$t('Check-In')">
    <div class="w-full md:w-1/2">
      <InertiaLink class="text-darkGray flex items-center px-3 hover:font-semibold" :href="route('front.courses')">
        <icon class="block h-3 w-3 fill-current" name="arrow-left" />
        <span class="ml-1">{{ $t('Courses') }}</span>
      </InertiaLink>
    </div>
    <div class="flex flex-col lg:flex-row">
      <div class="mt-6 max-w-xl overflow-hidden rounded-lg bg-white shadow-lg">
        <div v-if="student.checkedIn" class="px-40 pb-6 pt-6">
          <h1 class="text-center text-xl font-bold">
            {{ $t('Thank you for checking in, :first_name.', { first_name: student.first_name }) }}
          </h1>
          <div class="mt-4 text-center text-xl text-gray-600">{{ $t("We'll see you soon!") }}</div>
        </div>
        <div v-else>
          <div class="px-10 pb-6 pt-12">
            <h1 class="text-center text-xl font-bold">
              {{ $t('Check-in for Upcoming :course_name Class', { course_name: student.course.name }) }}
            </h1>
            <div class="mt-4 text-center text-xl text-gray-600">{{ student.first_name }} {{ student.last_name }}</div>
          </div>
          <div
            v-if="student.course.ministry_of_labour_code"
            class="mx-12 mt-6 rounded bg-yellow-300 px-6 pb-5 pt-6 text-center leading-tight text-yellow-800"
          >
            {{ $t('The following information is required by the') }}
            <strong>{{ $t('Ontario Ministry of Labour') }}</strong>
            .
          </div>
          <form @submit.prevent="submit">
            <div class="-mb-8 -mr-6 flex flex-wrap p-12">
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input disabled :label="$t('First name')" :modelValue="student.first_name" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  disabled
                  :error="form.errors.last_name"
                  :label="$t('Last name')"
                  :value="student.last_name"
                />
              </div>
              <div v-if="student.course.is_esc_course" class="w-full pb-8 pr-6 lg:w-1/4">
                <select-input v-model="form.birth_day" :error="form.errors.birth_day" label="Birth Day">
                  <option :value="null" />
                  <option v-for="day in 31" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select-input>
              </div>
              <div v-if="student.course.is_esc_course" class="w-full pb-8 pr-6 lg:w-1/4">
                <select-input v-model="form.birth_month" :error="form.errors.birth_month" label="Birth Month">
                  <option :value="null" />
                  <option v-for="month in 12" :key="month" :value="month">
                    {{ month }}
                  </option>
                </select-input>
              </div>
              <div
                v-if="student.course.ministry_of_labour_code || student.course.is_esc_course"
                class="w-full pb-8 pr-6 lg:w-1/4"
              >
                <text-input
                  v-model="form.birth_year"
                  autocomplete="off"
                  :error="form.errors.birth_year"
                  :label="$t('Birth year')"
                  min="1920"
                  type="number"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  v-model="form.username"
                  :error="isUsernameError || form.errors.username ? form.errors.username : ''"
                  :label="$t('Username')"
                />
              </div>
              <div v-if="course.collects_learner_status" class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.mol_learner_status"
                  :error="form.errors.mol_learner_status"
                  :label="$t('Learner Type')"
                >
                  <option :value="null" />
                  <option v-for="(status, key) in mol_learner_statuses" :key="key" :value="key">
                    {{ status }}
                  </option>
                </select-input>
              </div>
              <div v-if="course.collects_learner_status" class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.mol_sector"
                  :disabled="!form.mol_learner_status || form.mol_learner_status === 'learner'"
                  :error="form.errors.mol_sector"
                  :label="$t('Sector')"
                >
                  <option :value="null" />
                  <option v-for="(sector, key) in mol_sectors" :key="key" :value="key">
                    {{ sector }}
                  </option>
                </select-input>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input
                  v-model="form.email"
                  :error="isEmailError || form.errors.email ? form.errors.email : ''"
                  :label="$t('Email')"
                  type="email"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.phone" :error="form.errors.phone" :label="$t('Phone')" type="tel" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <canada-post-address-complete-input
                  v-if="$page.props.app.tenant.has_address_complete_api_enabled && canFindAddress"
                  ref="address_complete"
                  v-model="address_complete"
                  class="w-full"
                  :label="$t('Address')"
                  label-class=""
                  required
                  :search-by="['Text', 'Description']"
                  search-input-class=""
                  track-by="Id"
                  @update:enable-a-p-i="canFindAddress = false"
                >
                  <div v-if="form.address" class="flex items-center justify-between">
                    <div class="truncate">{{ form.address }}</div>
                  </div>
                  <template #option="{ option, selected }">
                    <div class="flex items-center justify-between">
                      <div class="text-md" :class="{ 'text-gray-600': !selected, 'text-white': selected }">
                        {{ option.Text.concat(' ', option.Description) }}
                      </div>
                      <icon
                        v-if="option.Next == 'Find'"
                        class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-400"
                        name="chevron-right"
                      />
                    </div>
                  </template>
                </canada-post-address-complete-input>
                <template v-else>
                  <label class="mb-2 block" for="address">{{ $t('Address') }}</label>
                  <input
                    id="address"
                    v-model="form.address"
                    class="block w-full rounded border bg-white p-2 focus:outline-none"
                    :placeholder="$t('Type your address...')"
                    required
                    type="text"
                  />
                </template>
                <div v-if="form.errors.address" class="form-error">{{ form.errors.address }}</div>
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.city" :error="form.errors.city" :label="$t('City')" />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <select-input
                  v-model="form.region"
                  :error="form.errors.region"
                  :label="$t('Province/State')"
                  :options="[null, ...regions]"
                />
              </div>
              <div class="w-full pb-8 pr-6 lg:w-1/2">
                <text-input v-model="form.postal_code" :error="form.errors.postal_code" :label="$t('Postal code')" />
              </div>
            </div>
            <div class="flex justify-end border-t border-gray-100 bg-gray-100 px-12 py-6">
              <front-loading-button :loading="form.processing" type="submit">
                {{ $t('Complete check-in') }}
              </front-loading-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </front-layout>
</template>

<script>
import CanadaPostAddressCompleteInput from '@/Shared/CanadaPostAddressCompleteInput.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import Icon from '@/Shared/Icon.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import TextInput from '@/Shared/TextInput.vue'
import Http from '@/Utils/Http'
import { useForm } from '@inertiajs/vue3'
import _debounce from 'lodash/debounce'

export default {
  components: {
    CanadaPostAddressCompleteInput,
    FrontLayout,
    FrontLoadingButton,
    Icon,
    SelectInput,
    TextInput,
  },
  props: {
    regions: Array,
    student: Object,
    course: Object,
    mol_learner_statuses: Object,
    mol_sectors: Object,
  },
  data() {
    return {
      isStudentCheckInRequest: true,
      isEmailError: false,
      isUsernameError: false,
      form: useForm(
        {
          first_name: this.student.first_name,
          last_name: this.student.last_name,
          birth_day: this.student.birth_day,
          birth_month: this.student.birth_month,
          birth_year: this.student.birth_year,
          username: this.student.username,
          email: this.student.email,
          phone: this.student.phone,
          address: this.student.address,
          city: this.student.city,
          region: this.student.region,
          postal_code: this.student.postal_code,
          mol_learner_status: this.student.mol_learner_status,
          mol_sector: this.student.mol_sector,
        },
        'CheckIn'
      ),
      address_complete: null,
      canFindAddress: true,
    }
  },
  watch: {
    'form.email': _debounce(function () {
      if (this.form.email) {
        this.checkEmailAvailability()
      } else {
        this.form.errors.email = ''
      }
    }, 300),
    'form.username': _debounce(function () {
      if (this.form.username) {
        this.checkUsernameAvailability()
      } else {
        this.form.errors.username = ''
      }
    }, 300),
    'form.mol_learner_status'($value) {
      if (!$value || $value === 'learner') {
        this.form.mol_sector = null
      }
    },
    address_complete: function (newVal, oldVal) {
      this.form.address = newVal?.Line1
      this.form.city = newVal?.City
      this.form.postal_code = newVal?.PostalCode
      this.form.region = newVal?.ProvinceName
      this.form.country = newVal ? newVal.CountryIso2 : oldVal.CountryIso2
    },
  },
  methods: {
    checkEmailAvailability() {
      let params = { email: this.form.email }
      if (this.student.user) {
        params.user = this.student.user.id
      }
      Http.get(this.route('front.email.availability'), { params })
        .then(() => {
          this.isEmailError = false
          this.form.errors.email = ''
        })
        .catch((error) => {
          this.isEmailError = true
          this.form.errors.email = error.response.data.errors.email
        })
    },
    checkUsernameAvailability() {
      let params = { username: this.form.username }
      if (this.student.user) {
        params.user = this.student.user.id
      }
      Http.get(this.route('front.username.availability'), { params })
        .then(() => {
          this.isUsernameError = false
          this.form.errors.username = ''
        })
        .catch((error) => {
          this.isUsernameError = true
          this.form.errors.username = error.response.data.errors.username[0]
          console.log(this.form.errors.username)
        })
    },
    submit() {
      this.form.post(this.route('front.students.check-in.store', this.student.id))
    },
  },
}
</script>
