<template>
  <layout :title="$t('Move Student')">
    <h1 class="mb-8 text-xl font-bold">
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('classes')">
        {{ $t('Classes') }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      <InertiaLink class="text-blue-600 hover:text-blue-700" :href="route('classes.show', student.class.id)">
        {{ student.class.course }}
      </InertiaLink>
      <span class="font-medium text-blue-600">/</span>
      <span class="whitespace-nowrap">{{ $t('Move student') }}</span>
    </h1>
    <div class="-mb-8 -mr-8 flex max-w-xl flex-wrap">
      <div class="w-full pb-8 pr-8 md:w-1/2">
        <div class="w-full rounded bg-white px-8 py-6 leading-normal shadow">
          <div v-if="student.customer" class="my-2">
            <strong>{{ $t('Customer:') }}</strong>
            {{ student.customer }}
          </div>
          <div v-if="student.name" class="my-2">
            <strong>{{ $t('Student:') }}</strong>
            {{ student.name }}
          </div>
          <div v-if="student.payment_status" class="my-2">
            <strong>{{ $t('Payment status:') }}</strong>
            {{ student.payment_status }}
          </div>
          <div v-if="student.payment_method" class="my-2">
            <strong>{{ $t('Payment method:') }}</strong>
            {{ student.payment_method }}
          </div>
          <div v-if="student.present" class="my-2">
            <strong>{{ $t('Present:') }}</strong>
            {{ student.present }}
          </div>
          <div v-if="student.score" class="my-2">
            <strong>{{ $t('Score:') }}</strong>
            {{ student.score }}
          </div>
          <div v-if="student.certificate_number" class="my-2">
            <strong>{{ $t('Certificate number:') }}</strong>
            {{ student.certificate_number }}
          </div>
        </div>
      </div>
      <div class="w-full pb-8 pr-8 md:w-1/2">
        <div class="mr-8 w-full rounded bg-white px-8 py-6 leading-normal shadow">
          <div class="my-2">
            <strong>{{ $t('Dates:') }}</strong>
            {{ student.class.dates }}
          </div>
          <div class="my-2">
            <strong>{{ $t('Location:') }}</strong>
            {{ student.class.location }}
          </div>
          <div v-if="student.class.room" class="my-2">
            <strong>{{ $t('Room:') }}</strong>
            {{ student.class.room }}
          </div>
          <div v-if="student.class.instructors" class="my-2">
            <strong>{{ $t('Instructors:') }}</strong>
            {{ student.class.instructors }}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 w-full rounded bg-white shadow">
      <div class="flex flex-wrap border-b px-6 pb-4 pt-2">
        <div>
          <label class="ml-1 mt-4 block text-gray-800">{{ $t('Between:') }}</label>
          <div class="mr-2 flex flex-wrap">
            <date-input v-model="form.local_start_date" class="ml-1 mt-1 w-40" />
            <date-input v-model="form.local_end_date" class="ml-1 mt-1 w-40" />
          </div>
        </div>
        <div class="mr-2">
          <label class="mt-4 block text-gray-800">{{ $t('Location:') }}</label>
          <select v-model="form.location" class="form-select mt-1 w-full">
            <option :value="null" />
            <option v-for="location in locations" :key="location.id" :value="location.id">{{ location.name }}</option>
          </select>
        </div>
        <div>
          <label class="mt-4 block text-gray-800">{{ $t('Access:') }}</label>
          <select v-model="form.access" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="public">{{ $t('Public') }}</option>
            <option value="private">{{ $t('Private') }}</option>
          </select>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Sessions') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Location') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Access') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold">{{ $t('Available (seats)') }}</th>
              <th class="whitespace-nowrap px-6 pb-4 pt-6 text-left font-bold" colspan="2">{{ $t('Instructors') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="class_ in classes.data" :key="class_.id">
              <td class="whitespace-nowrap border-t px-6 py-4">
                <div v-if="class_.sessions_count" class="space-x-1">
                  <span class="rounded bg-gray-400 px-2 py-1 text-sm text-white">{{ class_.sessions_count }}</span>
                  <span>{{ class_.dates }}</span>
                  <span class="rounded-full border bg-gray-100 px-2 py-1 text-sm text-gray-600">{{ class_.time }}</span>
                </div>
                <div v-else class="text-red-500">{{ $t('None set') }}</div>
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                {{ class_.location }}
                <span v-if="class_.room" class="text-sm">({{ class_.room }})</span>
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">{{ class_.private ? 'Private' : 'Public' }}</td>
              <td class="whitespace-nowrap border-t px-6 py-4">
                {{ class_.available_seats }}
              </td>
              <td class="whitespace-nowrap border-t px-6 py-4">{{ class_.instructors }}</td>
              <td class="w-min border-t px-4 align-middle">
                <button
                  class="btn-blue py-2 text-xs"
                  :class="{
                    'bg-gray-400 hover:cursor-default hover:bg-gray-400': class_.available_seats <= 0,
                  }"
                  :disabled="class_.available_seats <= 0"
                  type="button"
                  @click="move(class_)"
                >
                  Move
                </button>
              </td>
            </tr>
            <tr v-if="classes.data.length === 0">
              <td class="border-t px-6 py-4" colspan="6">{{ $t('No classes found.') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination :links="classes.links" />
  </layout>
</template>

<script>
import DateInput from '@/Shared/DateInput.vue'
import Layout from '@/Shared/Layout.vue'
import Pagination from '@/Shared/Pagination.vue'
import _pickBy from 'lodash/pickBy'
import _throttle from 'lodash/throttle'

export default {
  components: {
    DateInput,
    Layout,
    Pagination,
  },
  props: {
    student: Object,
    filters: Object,
    locations: Array,
    classes: Object,
  },
  data() {
    return {
      form: {
        local_start_date: this.filters.local_start_date,
        local_end_date: this.filters.local_end_date,
        location: this.filters.location,
        access: this.filters.access,
      },
    }
  },
  watch: {
    form: {
      handler: _throttle(function () {
        this.$inertia.get(
          this.route('students.move', this.student.id),
          {
            ..._pickBy(this.form),
          },
          {
            preserveState: true,
            replace: true,
          }
        )
      }, 300),
      deep: true,
    },
  },
  methods: {
    move(class_) {
      if (
        confirm(
          this.$t('Are you sure you want to move this student to the :dates class?', { dates: `${class_.dates}` })
        )
      ) {
        this.$inertia.put(this.route('students.update', this.student.id), {
          class_id: class_.id,
        })
      }
    },
  },
}
</script>
