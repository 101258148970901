<template>
  <front-layout :title="$t('Form Templates')">
    <div class="mb-8">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <breadcrumb
            class="font-heading text-2xl font-semibold"
            :name="$t('Edit')"
            :previous-name="$t('Form Templates')"
            :previous-url="route('front.admin.form-templates.index')"
          />
        </div>
        <div class="mt-4 inline-flex items-center gap-x-5 md:ml-4 md:mt-0">
          <div
            class="items-baseline rounded-full bg-green-100 px-3 py-1.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0"
          >
            <span>{{ $t('Saved unpublished changes') }}</span>
          </div>
          <p v-if="formTemplate.last_published_at" class="text-sm font-semibold text-gray-600">
            {{ $t('Last published:') }}
            <front-date class="inline" format="MMMM Do YYYY, h:mm:ss a" :timestamp="formTemplate.last_published_at" />
          </p>
          <button v-if="!formTemplate.archived_at" class="btn-red-outline" @click="archiveFormTemplate()">
            <span>{{ $t('Archive') }}</span>
            <span class="sr-only">{{ $t('Template') }}</span>
          </button>
          <button v-else class="btn-red-outline" @click="unarchiveFormTemplate()">
            <span>{{ $t('Restore Archived') }}</span>
            <span class="sr-only">{{ $t('Template') }}</span>
          </button>
          <front-loading-button
            :class="{ disabled: !formTemplate.can.publish }"
            :disabled="!formTemplate.can.publish"
            :loading="form.processing"
            type="button"
            @click="publish"
          >
            {{ $t('Publish') }}
          </front-loading-button>
        </div>
      </div>
    </div>

    <div class="flex w-full flex-col-reverse md:flex-row">
      <div class="mb-4 mr-0 w-full md:w-4/5">
        <div class="overflow-hidden">
          <form @change="formChange" @submit.prevent="submit">
            <div class="flex flex-col items-center xl:flex-row">
              <div class="flex w-full flex-wrap">
                <div class="mb-8 w-full">
                  <div class="mb-2">
                    <front-text-input
                      v-model="form.title"
                      class="bg-white px-6 text-2xl"
                      :error="form.errors.title"
                      :placeholder="$t('Form Title')"
                    />
                  </div>
                  <div>
                    <front-textarea-input
                      v-model="form.description"
                      :autosize="true"
                      class="w-full bg-white px-6 text-xl"
                      :error="form.errors.description"
                      :placeholder="$t('Form Description')"
                      :rows="5"
                    />
                  </div>
                </div>

                <div class="mb-8 w-full rounded-lg border border-ts-front-border-light bg-white p-6">
                  <div class="mb-8 w-full">
                    <h2 class="mb-2 text-2xl">{{ $t('Access') }}</h2>
                    <p class="text-md text-gray-600">
                      {{ $t('Select who in the organization is able to access this form.') }}
                    </p>
                  </div>
                  <div class="mb-4 flex items-center justify-between">
                    <label class="form-label text-md">{{ $t('User(s)') }}</label>
                    <Multiselect
                      v-model="form.permissions.users"
                      class="w-1/2"
                      deselect-label=""
                      :hide-label="true"
                      :hide-selected="true"
                      :label="$t('name')"
                      :loading="false"
                      :multiple="true"
                      :name="$t('User(s)')"
                      :options="organization.users"
                      :placeholder="$t('Select User(s)')"
                      select-label=""
                      track-by="id"
                    >
                      <template #noResult>{{ $t('No user(s) found.') }}</template>
                      <template #noOptions>{{ $t('No user(s) found.') }}</template>
                    </Multiselect>
                  </div>
                  <div class="mb-4 flex items-center justify-between">
                    <label class="form-label text-md">{{ $t('User Role(s)') }}</label>
                    <Multiselect
                      v-model="form.permissions.user_roles"
                      class="w-1/2"
                      deselect-label=""
                      :hide-label="true"
                      :hide-selected="true"
                      :label="$t('name')"
                      :loading="false"
                      :multiple="true"
                      :name="$t('User Role(s)')"
                      :options="organization.user_roles"
                      :placeholder="$t('Select User Role(s)')"
                      select-label=""
                      track-by="id"
                    >
                      <template #noResult>{{ $t('No user role(s) found.') }}</template>
                      <template #noOptions>{{ $t('No user role(s) found.') }}</template>
                    </Multiselect>
                  </div>
                  <div v-if="organization.locations.length > 0" class="mb-4 flex items-center justify-between">
                    <label class="form-label text-md">{{ $t('Location(s)') }}</label>
                    <Multiselect
                      v-model="form.permissions.locations"
                      class="w-1/2"
                      deselect-label=""
                      :hide-label="true"
                      :hide-selected="true"
                      :label="$t('name')"
                      :loading="false"
                      :multiple="true"
                      :name="$t('Location(s)')"
                      :options="organization.locations"
                      :placeholder="$t('Select locations')"
                      select-label=""
                      track-by="id"
                    >
                      <template #noResult>{{ $t('No location(s) found.') }}</template>
                      <template #noOptions>{{ $t('No location(s) found.') }}</template>
                    </Multiselect>
                  </div>
                  <div v-if="organization.groups.length > 0" class="mb-4 flex items-center justify-between">
                    <label class="form-label text-md">{{ $t('Group(s)') }}</label>
                    <Multiselect
                      v-model="form.permissions.groups"
                      class="w-1/2"
                      deselect-label=""
                      :hide-label="true"
                      :hide-selected="true"
                      :label="$t('name')"
                      :loading="false"
                      :multiple="true"
                      :name="$t('Group(s)')"
                      :options="organization.groups"
                      :placeholder="$t('Select Group(s)')"
                      select-label=""
                      track-by="id"
                    >
                      <template #noResult>{{ $t('No group(s) found.') }}</template>
                      <template #noOptions>{{ $t('No group(s) found.') }}</template>
                    </Multiselect>
                  </div>
                  <div v-if="organization.job_profiles.length > 0" class="flex items-center justify-between">
                    <label class="form-label text-md">{{ $t('Job Profile(s)') }}</label>
                    <Multiselect
                      v-model="form.permissions.job_profiles"
                      class="w-1/2"
                      deselect-label=""
                      :hide-label="true"
                      :hide-selected="true"
                      :label="$t('name')"
                      :loading="false"
                      :multiple="true"
                      :name="$t('Job Profile(s)')"
                      :options="organization.job_profiles"
                      :placeholder="$t('Select Job Profile(s)')"
                      select-label=""
                      track-by="id"
                    >
                      <template #noResult>{{ $t('No job profile(s) found.') }}</template>
                      <template #noOptions>{{ $t('No job profile(s) found.') }}</template>
                    </Multiselect>
                  </div>
                </div>

                <div class="mb-12 w-full rounded-lg border border-ts-front-border-light bg-white p-6 px-6">
                  <div class="mb-4 w-full">
                    <h2 class="mb-2 text-2xl">{{ $t('Form Builder') }}</h2>
                    <p class="text-md text-gray-600">{{ $t('Create effective form within minutes.') }}</p>
                  </div>
                  <div class="w-11/12">
                    <FormPages :common="common" :form-template="formTemplate" :list="formTemplateContents" />
                    <front-form-page-move-modal
                      :pages="formTemplateContents"
                      :show="isMovePageModal"
                      @close="hideMovePageModal"
                    />
                    <div
                      v-if="form.processing"
                      class="fixed bottom-4 right-4 flex space-x-4 rounded-full bg-green-600 px-4 py-4 text-white"
                    >
                      <div class="spinner"></div>
                      <span>{{ $t('Saving') }}</span>
                    </div>
                  </div>
                </div>

                <div class="mb-8 w-full rounded-lg border border-ts-front-border-light bg-white p-6">
                  <div class="mb-8 w-full">
                    <h2 class="mb-2 text-2xl">{{ $t('Signatures') }}</h2>
                    <p class="text-md text-gray-600">
                      {{
                        $t(
                          'Select who in the organization should be asked to review and sign this form when it is completed.'
                        )
                      }}
                    </p>
                  </div>
                  <div
                    v-for="(signatureType, index) in form_template_signature_types"
                    :key="index"
                    class="mb-4 flex items-center justify-between"
                  >
                    <label class="flex items-center p-3">
                      <input
                        :id="index"
                        :checked="isSignatureTypeChecked(signatureType)"
                        class="form-checkbox mr-3 h-5 w-5 rounded-xl accent-ts-red-500 outline-none"
                        type="checkbox"
                        @change="onSignatureTypeSelect(signatureType)"
                      />
                      {{ signatureType.label }}
                    </label>
                    <Multiselect
                      v-if="signatureType.value === 'user'"
                      v-model="form.signatures.users"
                      class="w-1/2 text-sm"
                      :close-on-select="false"
                      deselect-label=""
                      :disabled="!isSignatureTypeChecked(signatureType)"
                      :hide-label="true"
                      :hide-selected="true"
                      :label="$t('name')"
                      :loading="isLoading"
                      :multiple="true"
                      :name="$t('User(s)')"
                      open-direction="bottom"
                      :options="findUsersData"
                      :placeholder="$t('Search User(s)')"
                      :searchable="true"
                      select-label=""
                      :show-pointer="false"
                      track-by="id"
                      @search-change="findUsers()"
                      @select="optionSelected($event)"
                    >
                      <template #option="props">
                        <div
                          class="flex items-center justify-between"
                          :class="props.option.hasEHSAccess ? 'text-black' : 'cursor-text text-black/40'"
                        >
                          <div>{{ props.option.name }}</div>
                          <div>{{ props.option.email }}</div>
                        </div>
                      </template>
                      <template #noResult>{{ $t('No user(s) found.') }}</template>
                      <template #noOptions>{{ $t('No user(s) found.') }}</template>
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="md:top-50 mb-4 w-full md:fixed md:right-0 md:mb-0 md:w-1/4">
        <div class="text-right">
          <button
            class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            type="button"
            @click="showMobilePreview = !showMobilePreview"
          >
            <DevicePhoneMobileIcon aria-hidden="true" class="mr-2 h-4 w-4 text-qualify-red-500" />
            {{ showMobilePreview ? $t('Hide mobile preview') : $t('Show mobile preview') }}
          </button>
        </div>
        <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-100 translate-x-5"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="transition ease-out duration-200"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="opacity-100 translate-x-5"
        >
          <div
            v-if="showMobilePreview"
            class="mt-2 h-[36rem] overflow-hidden rounded-lg border border-ts-gray-500 bg-white p-4 shadow"
          >
            <header class="relative w-full border bg-gray-50">
              <nav aria-label="Global" class="max-w-7xl mx-auto flex items-center justify-between p-4">
                <div>
                  <Popover class="mb-3">
                    <PopoverButton class="inline-flex items-center text-sm text-gray-600">
                      <p class="text-sm">Page {{ templatePageIndex }} of {{ formTemplateContents.length }}</p>
                      <ChevronDownIcon
                        aria-hidden="true"
                        :class="!open ? 'ml-1 h-4 w-4 transition' : 'ml-1 h-4 w-4 rotate-180 transition'"
                      />
                    </PopoverButton>

                    <transition
                      enter-active-class="transition ease-out duration-200"
                      enter-from-class="opacity-0 translate-y-1"
                      enter-to-class="opacity-100 translate-y-0"
                      leave-active-class="transition ease-in duration-150"
                      leave-from-class="opacity-100 translate-y-0"
                      leave-to-class="opacity-0 translate-y-1"
                    >
                      <PopoverPanel class="absolute top-20 z-10 -ml-4 w-full bg-white shadow ring-1 ring-gray-900/5">
                        <div class="rounded bg-white text-sm">
                          <div class="p-2">
                            <div
                              v-for="(page, index) in formTemplateContents"
                              :key="page.id"
                              class="shrink rounded bg-white text-sm text-gray-600"
                            >
                              <a class="block cursor-pointer p-2 hover:bg-gray-100" @click="handleTemplatePage(index)">
                                {{ page.title ?? $t('Untitled page') }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </PopoverPanel>
                    </transition>
                  </Popover>
                  <p class="text-m font-semibold text-gray-900">{{ formTemplate?.title }}</p>
                </div>
                <div class="text-right lg:gap-x-12">
                  <p class="mb-3 text-sm text-gray-600">{{ $t('Score') }}</p>
                  <p class="text-m font-semibold text-gray-900">{{ $t('0 / 1 (0%)') }}</p>
                </div>
              </nav>
            </header>
            <div class="h-[28.75rem] overflow-hidden overflow-y-scroll bg-gray-100 p-4">
              <front-form-item-mobile-view
                v-for="item in formTemplateContents[templatePageIndex - 1].children"
                :key="item.uuid"
                :item="item"
              />
              <div
                :class="[
                  'mb-16 mt-20 inline-flex w-full items-center',
                  templatePageIndex === 1
                    ? 'justify-end'
                    : templatePageIndex < formTemplateContents.length
                      ? 'justify-between'
                      : 'justify-start',
                ]"
              >
                <button
                  v-if="templatePageIndex > 1"
                  class="inline-flex items-center rounded-md bg-qualify-red-500 p-3 text-sm font-semibold text-white shadow-sm focus:outline-none"
                  type="button"
                  @click="gotoPreviousPage(templatePageIndex)"
                >
                  <ChevronLeftIcon aria-hidden="true" class="h-5 w-5" />
                  {{ $t('Previous Page') }}
                </button>
                <button
                  v-if="templatePageIndex < formTemplateContents.length"
                  class="inline-flex items-center rounded-md bg-qualify-red-500 p-3 text-sm font-semibold text-white shadow-sm focus:outline-none"
                  type="button"
                  @click="gotoNextPage(templatePageIndex)"
                >
                  {{ $t('Next Page') }}
                  <ChevronRightIcon aria-hidden="true" class="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <front-confirm-modal
      v-if="showOutOfSyncError"
      :show="showOutOfSyncError"
      @cancel="showOutOfSyncError = false"
      @confirm="reload"
    >
      <template #title>{{ $t('Unable to save') }}</template>
      <template #content>
        <div class="flex flex-col space-y-4">
          <div>{{ $t('Page is out of sync. Need reload the page for editing') }}</div>
          <div>{{ $t('Are you sure you want to reload the page?') }}</div>
        </div>
      </template>
    </front-confirm-modal>
  </front-layout>
</template>

<script>
import FormPages from './Components/FormPages.vue'
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontConfirmModal from '@/Shared/FrontConfirmModal.vue'
import FrontDate from '@/Shared/FrontDate.vue'
import FrontFormItemMobileView from '@/Shared/FrontFormItemMobileView.vue'
import FrontFormPageMoveModal from '@/Shared/FrontFormPageMoveModal.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import FrontTextareaInput from '@/Shared/FrontTextareaInput.vue'
import { isTenantWorksite } from '@/Utils/Helpers'
import Http from '@/Utils/Http'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { ChevronLeftIcon, ChevronRightIcon, DevicePhoneMobileIcon } from '@heroicons/vue/24/outline'
import { useForm } from '@inertiajs/vue3'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    FrontDate,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    FormPages,
    DevicePhoneMobileIcon,
    Multiselect,
    Breadcrumb,
    FrontConfirmModal,
    FrontFormItemMobileView,
    FrontFormPageMoveModal,
    FrontLayout,
    FrontLoadingButton,
    FrontTextInput,
    FrontTextareaInput,
  },
  provide() {
    return {
      saveChanges: this.saveChanges,
      showMovePageModal: this.showMovePageModal,
    }
  },
  props: {
    common: Object,
    organization: Object,
    formTemplate: Object,
    form_template_signature_types: Array,
  },
  data() {
    return {
      isMovePageModal: false,
      enabled: true,
      dragging: false,
      imagePath: this.organization.logo,
      imageChanged: false,
      templatePageIndex: 1,
      previous: {
        url: this.route('front.admin.form-templates.index'),
        name: this.$t('Form Templates'),
      },
      form: useForm({
        series_id: this.formTemplate.series_id,
        title: this.formTemplate.title,
        description: this.formTemplate.description,
        formContents: this.listToTree(this.formTemplate?.contents),
        permissions: this.formTemplate.permissions ?? {
          users: [],
          user_roles: [],
          locations: [],
          groups: [],
          job_profiles: [],
        },
        signatures: {
          types: this.formTemplate.signatures.types ?? [],
          users: this.formTemplate.signatures.users ?? [],
        },
      }),
      showMobilePreview: false,
      showOutOfSyncError: false,
      isLoading: false,
      findUsersData: [],
    }
  },
  computed: {
    isTenantWorksite,
    formTemplateContents() {
      return this.listToTree(this.formTemplate?.contents)
    },
    isSignatureTypeUserSelected() {
      return this.form.signatures.types.includes('user')
    },
  },
  watch: {
    'form.permissions': {
      handler: function () {
        this.formChange()
      },
      deep: true,
    },
    'form.signatures': {
      handler: function (option) {
        console.log(option)
        this.formChange()
      },
      deep: true,
    },
  },
  methods: {
    formChange() {
      this.saveChanges()
    },
    listToTree(listItems) {
      let pageList = []
      const parentMap = []

      listItems.forEach((formContent) => {
        formContent.children = []

        if (formContent.type === 'form-page') {
          parentMap[formContent.uuid] = formContent
          return pageList.push(formContent)
        }

        if (formContent.parent_id in parentMap) {
          parentMap[formContent.uuid] = formContent
          parentMap[formContent.parent_id].children.push(formContent)
        }
      })

      return pageList
    },
    treeToList(tree) {
      const treeList = []

      function transformToList(list, parentId = null) {
        list.forEach(({ children, ...node }) => {
          treeList.push({ ...node, parent_id: parentId })
          if (children) transformToList(children, node.uuid)
        })
      }

      transformToList(tree)

      return treeList
    },
    saveChanges() {
      this.form
        .transform((data) => {
          return {
            ...data,
            signatures: {
              types: this.form.signatures.types,
              users: this.isSignatureTypeUserSelected ? this.form.signatures.users.map((user) => user.id) : [],
            },
            updated_at: this.formTemplate.updated_at,
            formContents: this.treeToList(this.formTemplateContents),
          }
        })
        .put(this.route('front.admin.form-templates.update', this.formTemplate.series_id), {
          preserveScroll: true,
          onError: () => {
            this.showOutOfSyncError = true
          },
        })
    },
    publish() {
      this.$inertia.post(this.route('front.admin.form-templates.publish', this.formTemplate.series_id), {
        updated_at: this.formTemplate.updated_at,
      })
    },
    hideMovePageModal() {
      this.isMovePageModal = false
    },
    showMovePageModal() {
      this.isMovePageModal = true
    },
    handleTemplatePage(index) {
      this.templatePageIndex = ++index
    },
    gotoPreviousPage() {
      this.templatePageIndex--
    },
    gotoNextPage() {
      this.templatePageIndex++
    },
    reload() {
      this.showOutOfSyncError = false
      window.location.reload()
    },
    onSignatureTypeSelect(signatureType) {
      if (this.form.signatures.types.includes(signatureType.value)) {
        this.form.signatures.types = this.form.signatures.types.filter((type) => type !== signatureType.value)
        if (signatureType.value === 'user') this.form.signatures.users = []
      } else {
        this.form.signatures.types.push(signatureType.value)
      }
    },
    isSignatureTypeChecked(signatureType) {
      return this.form.signatures.types.includes(signatureType.value)
    },
    customLabel({ name, email }) {
      return `${name} - ${email}`
    },
    findUsers(query) {
      this.isLoading = true
      Http.get(this.route('front.users.search'), { params: { search: query } }).then((response) => {
        this.findUsersData = response.data.data
        this.isLoading = false
      })
    },
    optionSelected(selectedOption) {
      if (!selectedOption.hasEHSAccess)
        this.form.signatures.users = this.form.signatures.users.filter((option) => option !== selectedOption)
    },
    archiveFormTemplate() {
      if (confirm(this.$t('Are you sure you want to archive this form template?'))) {
        this.$inertia.put(this.route('front.admin.form-templates.archive', this.formTemplate.series_id))
      }
    },
    unarchiveFormTemplate() {
      if (confirm(this.$t('Are you sure you want to unarchive this form template?'))) {
        this.$inertia.put(this.route('front.admin.form-templates.archive.restore', this.formTemplate.series_id))
      }
    },
  },
}
</script>
<style scoped>
.ghost {
  opacity: 0.5;
}

.not-draggable {
  cursor: no-drop;
}
</style>
